.cookie {
	position: fixed;
	bottom: -6.25rem;
	background: rgba(38, 39, 42, 0.7);
	padding: 0 1.25rem;
	width: 96%;
	max-width: 1440px;
	left: 50%;
	-webkit-transform: translate(-50%, 0);
	transform: translate(-50%, 0);
	z-index: 200;
	color: #fff;
	border-radius: 4px;
	visibility: hidden;
	opacity: 0;
	font-size: 1rem;
	text-align: center;
	transition: bottom .4s, visibility .3s, opacity .3s; }
.cookie a {
	color: #fff; }
.cookie a:hover {
	text-decoration: none; }
.cookie--visible {
	visibility: visible;
	opacity: 1;
	bottom: 1.25rem;}

.cookie-icon {
	display: inline-block;
	vertical-align: middle;
	width: 2.375rem;
	height: 2.375rem;
	position: relative;
	-webkit-transform: rotateY(-180deg);
	transform: rotateY(-180deg);
	margin: 0 0.9375rem 0 0; }
.cookie-icon svg {
	fill: #ec1c23;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	margin: auto;
	max-width: 100%;
	max-height: 100%; }