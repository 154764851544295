@charset "UTF-8";
@media only screen and (min-width: 320px) {
  /*! $mqpacker hack */
}

@media only screen and (min-width: 375px) {
  /*! $mqpacker hack */
}

@media only screen and (min-width: 412px) {
  /*! $mqpacker hack */
}

@media only screen and (min-width: 480px) {
  /*! $mqpacker hack */
}

@media only screen and (min-width: 640px) {
  /*! $mqpacker hack */
}

@media only screen and (min-width: 768px) {
  /*! $mqpacker hack */
}

@media only screen and (min-width: 1024px) {
  /*! $mqpacker hack */
}

@media only screen and (min-width: 1280px) {
  /*! $mqpacker hack */
}

@media only screen and (min-width: 1400px) {
  /*! $mqpacker hack */
}

@media only screen and (min-width: 1600px) {
  /*! $mqpacker hack */
}

@media only screen and (max-width: 1599px) {
  /*! $mqpacker hack */
}

@media only screen and (max-width: 1399px) {
  /*! $mqpacker hack */
}

@media only screen and (max-width: 1279px) {
  /*! $mqpacker hack */
}

@media only screen and (max-width: 1023px) {
  /*! $mqpacker hack */
}

@media only screen and (max-width: 767px) {
  /*! $mqpacker hack */
}

@media only screen and (max-width: 639px) {
  /*! $mqpacker hack */
}

@media only screen and (max-width: 479px) {
  /*! $mqpacker hack */
}

@media only screen and (max-width: 411px) {
  /*! $mqpacker hack */
}

@media only screen and (max-width: 374px) {
  /*! $mqpacker hack */
}

@media only screen and (max-width: 319px) {
  /*! $mqpacker hack */
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  font-size: 100%;
}

body {
  margin: 0;
}

article, aside, footer, header, nav, section {
  display: block;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

figcaption,
figure {
  display: block;
}

figure {
  margin: 1em 0;
}

main {
  display: block;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace;
  font-size: 1em;
}

a {
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: inherit;
}

b, strong {
  font-weight: bold;
}

code, kbd, samp {
  font-family: Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace;
  font-size: 1em;
}

dfn {
  font-style: italic;
}

mark {
  background-color: #ff0;
  color: #000;
}

small {
  font-size: 80%;
}

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

audio, video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

img {
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

button, input, optgroup, select, textarea {
  font-family: sans-serif;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

progress {
  display: inline-block;
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details,
menu {
  display: block;
}

summary {
  display: list-item;
}

canvas {
  display: inline-block;
}

template {
  display: none;
}

[hidden] {
  display: none;
}

a:active, a:hover {
  outline-width: 0;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

abbr[title], dfn[title] {
  cursor: help;
}

button {
  cursor: pointer;
}

button:disabled {
  cursor: not-allowed;
}

input::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

input::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

input[readonly] {
  cursor: default;
}

input:disabled {
  cursor: not-allowed;
}

html {
  position: relative;
  height: 100%;
  font-family: Arial, Helvetica, sans-serif;
  color: #000;
  line-height: 1.6;
  font-size: 12px;
}

@media only screen and (min-width: 480px) {
  html {
    font-size: 13px;
  }
}

@media only screen and (min-width: 768px) {
  html {
    font-size: 14px;
  }
}

@media only screen and (min-width: 1024px) {
  html {
    font-size: 15px;
  }
}

@media only screen and (min-width: 1280px) {
  html {
    font-size: 16px;
  }
}

*,
::after,
::before {
  box-sizing: inherit;
}

body {
  position: relative;
  height: 100%;
  box-sizing: border-box;
  min-width: 320px;
  background-color: #fff;
  overflow-x: hidden;
}

.touchevents body {
  cursor: pointer;
}

pre {
  padding: 1.2em;
  tab-size: 4;
  border-width: 0;
  white-space: pre-wrap;
  word-wrap: break-word;
  font-family: Consolas, 'Liberation Mono', Menlo, Courier, monospace;
}

img {
  max-width: 100%;
}

img:not(.lozad) {
  height: auto;
}

small {
  display: inline-block;
  vertical-align: text-bottom;
}

button svg,
a svg {
  pointer-events: none;
}

audio[controls] {
  display: block;
  width: 100%;
}

video,
iframe {
  max-width: 100%;
}

hr {
  border-width: 0;
  border-top: 1px solid;
  opacity: .5;
}

::selection {
  text-shadow: none;
  color: #fff;
  background: #2597ff;
}

input,
button,
select,
textarea {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
  -webkit-appearance: none;
  border-radius: 0;
}

[type="reset"],
[type="submit"],
button,
html [type="button"] {
  -webkit-appearance: none;
}

.roistat-promo-wrap {
  display: none !important;
}

.case-fix.is-hidden {
  transform: translateY(100%);
}

.has-anim .lt-anim {
  opacity: 0;
}

.view-wrapper {
  position: relative;
  min-height: 100%;
  overflow: hidden;
}

.page-size {
  position: relative;
  max-width: 1440px;
  padding: 0 1.5rem;
  margin: 0 auto;
}

.page-wrapper {
  overflow: hidden;
}

.page-section {
  position: relative;
  background: #fff;
  width: 94%;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (min-width: 1024px) {
  .page-section {
    width: 100%;
  }
}

.page-header {
  position: fixed;
  z-index: 505;
  width: 100%;
  top: 0;
  background: #fff;
  box-shadow: 0 0 2px #999;
  padding: 2px 0;
}

@media only screen and (min-width: 640px) {
  .page-header {
    padding: 0;
  }
}

.page-header__size {
  max-width: 1680px;
  margin: 0 auto;
  padding: 0 0.9375rem;
}

.wezom-logo {
  display: flex;
  align-items: center;
  text-decoration: none;
  user-select: none;
}

.wezom-logo__cube {
  display: block;
  position: relative;
  width: 2.625rem;
  height: 2.625rem;
  margin: 0 0.625rem 0 0.3125rem;
  transform-style: preserve-3d;
  transition: transform .3s ease-out;
}

.no-ie .wezom-logo:hover .wezom-logo__cube {
  transform: rotateX(90deg);
}

.no-touchevents .wezom-logo:hover .wezom-logo__cube {
  -webkit-transform: rotateX(90deg);
  transform: rotateX(90deg);
}

@media only screen and (min-width: 640px) {
  .wezom-logo__cube {
    width: 4.875rem;
    height: 4.875rem;
  }
}

.wezom-logo__icon {
  display: block;
  position: absolute;
  width: 2.625rem;
  height: 2.625rem;
}

@media only screen and (min-width: 640px) {
  .wezom-logo__icon {
    width: 4.875rem;
    height: 4.875rem;
  }
}

.wezom-logo__icon svg {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  max-width: 70%;
  max-height: 70%;
}

.wezom-logo__icon--front {
  transform: translateZ(2.4375rem);
  background: #ec1c23;
}

.wezom-logo__icon--front svg {
  fill: #fff;
}

.wezom-logo__icon--back {
  transform: rotateX(-90deg) translateY(2.4375rem);
  transform-origin: bottom center;
  background: #fff;
  box-shadow: inset 0 0 0 2px #ec1c23;
}

.wezom-logo__icon--back svg {
  fill: #ec1c23;
}

.wezom-logo__title {
  display: flex;
  position: relative;
  height: 2.625rem;
  width: 3rem;
  justify-content: center;
  align-items: center;
}

.wezom-logo__title svg {
  max-height: 30%;
  fill: #1d1d1b;
  transition: fill .25s;
}

@media only screen and (min-width: 640px) {
  .wezom-logo__title {
    height: 4.875rem;
    width: 7.5rem;
  }
}

.wezom-logo__caption {
  display: none;
  flex-direction: column;
  line-height: 1.4;
  color: #1d1d1b;
  transition: color .25s;
  font-size: 0.8rem;
  font-family: 'ProximaNova', Arial, sans-serif;
  font-weight: 700;
}

@media only screen and (min-width: 768px) {
  .wezom-logo__caption {
    display: flex;
  }
}

.header-menu {
  display: flex;
}

.header-menu__link {
  display: block;
  position: relative;
  margin: 0 0.9375rem;
  text-transform: uppercase;
  text-decoration: none;
  color: #1d1d1b;
  font-weight: 700;
  font-size: 1rem;
  padding: 0.625rem 0;
  transition: color 0.3s;
}

.header-menu__link::before {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  left: auto;
  width: 0;
  height: 3px;
  background: #ec1c23;
  transition: width 0.3s;
}

.header-menu__link:hover {
  color: #ec1c23;
}

.header-menu__link:hover::before {
  left: 0;
  right: auto;
  width: 100%;
}

.header-menu__link--active {
  color: #ec1c23;
}

.header-menu__link--active::before {
  left: 0;
  right: auto;
  width: 100%;
}

.hamburger {
  padding: 2px 0;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
}

@media only screen and (min-width: 640px) {
  .hamburger {
    padding: 15px 15px;
  }
}

.hamburger:hover .hamburger-inner::after {
  width: 100%;
  left: 0;
  right: auto;
}

.hamburger:hover .hamburger-inner__top::after,
.hamburger:hover .hamburger-inner__bottom::after {
  width: 100%;
  left: 0;
  right: auto;
}

.hamburger-box {
  width: 40px;
  height: 24px;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
}

.hamburger-inner::after {
  left: auto;
  right: 0;
}

.hamburger-inner,
.hamburger-inner__top,
.hamburger-inner__bottom {
  width: 40px;
  height: 4px;
  border-radius: 2px;
  background-color: #000;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}

.hamburger-inner::after,
.hamburger-inner__top::after,
.hamburger-inner__bottom::after {
  content: '';
  display: block;
  position: absolute;
  height: 100%;
  width: 0;
  background: #ec1c23;
  border-radius: 2px;
}

.hamburger-inner__top::after {
  transition: width .35s ease-out;
}

.hamburger-inner::after {
  transition: width .35s ease-out .06s;
}

.hamburger-inner__bottom::after {
  transition: width .35s ease-out 0.12s;
}

.hamburger-inner__top,
.hamburger-inner__bottom {
  display: block;
}

.hamburger-inner__top::after,
.hamburger-inner__bottom::after {
  left: auto;
  right: 0;
}

.hamburger-inner__top {
  top: -10px;
}

.hamburger-inner__bottom {
  bottom: -10px;
}

.hamburger--spin .hamburger-inner {
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spin .hamburger-inner__top {
  transition: top 0.1s 0.34s ease-in, opacity 0.1s ease-in;
}

.hamburger--spin .hamburger-inner__bottom {
  transition: bottom 0.1s 0.34s ease-in, transform 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spin.is-active .hamburger-inner {
  transform: rotate(225deg);
  transition-delay: 0.14s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--spin.is-active .hamburger-inner__top {
  top: 0;
  opacity: 0;
  transition: top 0.1s ease-out, opacity 0.1s 0.14s ease-out;
}

.hamburger--spin.is-active .hamburger-inner__bottom {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.1s ease-out, transform 0.3s 0.14s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.d-roistat-promo {
  position: absolute;
  left: 0;
  bottom: -13px;
  font-family: Arial,sans-serif;
  color: #000;
  font-size: 14px;
  white-space: nowrap;
  background-color: #fff;
  padding: 3px 15px 3px 3px;
  line-height: 1;
  width: 100%;
  text-align: right;
  z-index: -1;
}

@media only screen and (min-width: 361px) {
  .d-roistat-promo {
    bottom: 0;
    padding-right: 19px;
  }
}

@media only screen and (min-width: 769px) {
  .d-roistat-promo {
    bottom: 2px;
    background-color: transparent;
    right: 113px;
    left: auto;
    width: auto;
    padding: 0;
  }
}

@media only screen and (min-width: 1280px) {
  .d-roistat-promo {
    right: 125px !important;
    bottom: 3px !important;
  }
}

.page-footer {
  position: relative;
  background: #26272a;
}

.page-footer__above {
  display: none;
  border-bottom: 1px solid #2f3033;
  padding: 2.5rem 0;
}

@media only screen and (min-width: 1024px) {
  .page-footer__above {
    display: block;
  }
}

.page-footer__below {
  padding: 2.5rem 0;
}

.footer-column {
  position: relative;
  padding-left: 2.8125rem;
  overflow: hidden;
}

.footer-column__icon {
  position: absolute;
  left: 0;
  top: 0;
  width: 1.875rem;
  height: 1.875rem;
}

.footer-column__icon svg {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  fill: #ec1c23;
  max-width: 100%;
  max-height: 100%;
}

.footer-column__address {
  color: #ffffff;
  font-size: 0.875rem;
  margin-bottom: 0.625rem;
  line-height: 1.4;
}

.footer-column__address span {
  display: block;
}

.footer-column__index {
  color: #787a7d;
  font-size: 0.875rem;
  line-height: 1.4;
}

.footer-column__title {
  color: #787a7d;
  font-size: 0.875rem;
  padding: 0.625rem 0;
  margin: 0 0 0.625rem;
  font-weight: bold;
}

.footer-column__phone, .footer-column__email {
  position: relative;
  display: inline-block;
  font-size: 1.8rem;
  color: #fff;
  text-decoration: none;
  font-weight: 300;
}

.footer-column__phone:after, .footer-column__email:after {
  content: '';
  display: block;
  position: absolute;
  height: 1px;
  bottom: 0;
  width: 100%;
  background: #fff;
  opacity: 0;
  transition: opacity .4s;
}

.footer-column__phone:not(:last-child), .footer-column__email:not(:last-child) {
  margin-bottom: 0.3125rem;
}

.footer-column__phone:hover:after, .footer-column__email:hover:after {
  opacity: 1;
}

.footer-social {
  margin-bottom: 1.5625rem;
  text-align: center;
}

.footer-social__title {
  color: #fff;
  font-size: 1.4rem;
  margin: 0.9375rem 0;
}

.footer-social__button {
  position: relative;
  display: inline-block;
  width: 4rem;
  height: 4rem;
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  margin: 0.3125rem 0.625rem;
  transition: background .25s, border .25s;
}

.footer-social__button svg {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  max-width: 50%;
  max-height: 50%;
  fill: rgba(255, 255, 255, 0.5);
  transition: fill .25s;
}

.footer-social__button--facebook:hover {
  border: 1px solid #3b5998;
  background: #3b5998;
}

.footer-social__button--facebook:hover svg {
  fill: #fff;
}

.footer-social__button--youtube:hover {
  border: 1px solid #cd201f;
  background: #cd201f;
}

.footer-social__button--youtube:hover svg {
  fill: #fff;
}

.footer-social__button--twitter:hover {
  border: 1px solid #64a8f2;
  background: #64a8f2;
}

.footer-social__button--twitter:hover svg {
  fill: #fff;
}

.footer-social__button--google:hover {
  border: 1px solid #dd5044;
  background: #dd5044;
}

.footer-social__button--google:hover svg {
  fill: #fff;
}

.footer-social__button--linkedin:hover {
  border: 1px solid #007ab5;
  background: #007ab5;
}

.footer-social__button--linkedin:hover svg {
  fill: #fff;
}

.footer-social__button--pinterest:hover {
  border: 1px solid #cb2027;
  background: #cb2027;
}

.footer-social__button--pinterest:hover svg {
  fill: #fff;
}

.footer-social__button--instagram:hover {
  border: 1px solid #325c86;
  background: #325c86;
}

.footer-social__button--instagram:hover svg {
  fill: #fff;
}

.copyright {
  color: #6f6f71;
  font-size: 0.8rem;
  text-align: center;
}

.map-wrapper {
  position: relative;
  height: 350px;
  background: #252525;
  margin-bottom: 3rem;
}

.map-wrapper__button {
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 0);
  white-space: nowrap;
}

.map-wrapper__button:active {
  transform: translate(-50%, 2px) !important;
}

.main-slider {
  margin: 0 0 3.125rem;
  position: relative;
}

.main-slider .swiper-slide {
  background-position: 50%;
  background-size: cover;
}

.main-slider__slide {
  position: relative;
  width: 100%;
  height: auto;
  padding: 150% 0 0;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0);
}

@media only screen and (min-width: 480px) {
  .main-slider__slide {
    padding: 50% 0 0;
    min-height: 340px;
  }
}

@media only screen and (min-width: 1400px) {
  .main-slider__slide {
    background-color: transparent;
  }
}

.main-slider__content {
  position: absolute;
  z-index: 2;
  left: 50%;
  top: 50%;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 4.875rem 8rem 0;
  box-sizing: border-box;
  text-align: center;
  transform: translate(-50%, -50%);
}

@media only screen and (min-width: 1024px) {
  .main-slider__content {
    text-align: left;
  }
}

.main-slider__title {
  font-size: 1.875rem;
  color: #fff;
  font-weight: 700;
  margin: 0 0 1.25rem;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  text-transform: uppercase;
  line-height: 1.0;
}

@media only screen and (min-width: 480px) {
  .main-slider__title {
    font-size: 2.5rem;
  }
}

@media only screen and (min-width: 768px) {
  .main-slider__title {
    font-size: 3.125rem;
  }
}

@media only screen and (min-width: 1280px) {
  .main-slider__title {
    font-size: 3.6rem;
  }
}

.main-slider__caption {
  font-size: 1.25rem;
  color: #fff;
  font-weight: 300;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

@media only screen and (min-width: 480px) {
  .main-slider__caption {
    font-size: 1.5625rem;
  }
}

@media only screen and (min-width: 768px) {
  .main-slider__caption {
    font-size: 1.8rem;
  }
}

.main-slider__prev, .main-slider__next {
  position: absolute;
  cursor: pointer;
  z-index: 100;
  top: 50%;
  width: 4.375rem;
  height: 8.75rem;
  transform: translate(0, -50%);
  margin-top: 2.4375rem;
  opacity: .5;
  display: none;
  transition: left .2s, right .2s, opacity .2s;
}

@media only screen and (min-width: 480px) {
  .main-slider__prev, .main-slider__next {
    display: block;
  }
}

@media only screen and (min-width: 1024px) {
  .main-slider__prev, .main-slider__next {
    width: 8.75rem;
    height: 17.5rem;
  }
}

.main-slider__prev svg, .main-slider__next svg {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  max-width: 100%;
  max-height: 100%;
  fill: none;
  stroke: #fff;
  stroke-width: 1px;
  transition: stroke .2s;
}

.main-slider__prev:hover, .main-slider__next:hover {
  opacity: 1;
}

.main-slider__prev {
  left: 0;
}

.main-slider__prev:active {
  left: -0.625rem;
}

.main-slider__next {
  right: 0;
}

.main-slider__next:active {
  right: -0.625rem;
}

.button-down {
  display: none;
  position: absolute;
  width: 30px;
  height: 45px;
  box-sizing: border-box;
  border: 2px solid #fff;
  z-index: 50;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -75%);
  border-radius: 15px;
  cursor: pointer;
}

@media only screen and (min-width: 1024px) {
  .button-down {
    display: block;
  }
}

.button-down__arrow {
  position: absolute;
  width: 16px;
  height: 4px;
  opacity: 0;
  left: 50%;
  margin: 0 0 0 -8px;
  transform: scale3d(0.5, 0.5, 0.5);
  animation: move 3s ease-out infinite;
}

.button-down__arrow:first-child {
  animation: move 3s ease-out 1s infinite;
}

.button-down__arrow:nth-child(2) {
  animation: move 3s ease-out 2s infinite;
}

.button-down__arrow:before, .button-down__arrow:after {
  content: ' ';
  position: absolute;
  top: 0;
  height: 100%;
  width: 51%;
  background: #fff;
}

.button-down__arrow:before {
  left: 0;
  transform: skew(0deg, 30deg);
}

.button-down__arrow:after {
  right: 0;
  width: 50%;
  transform: skew(0deg, -30deg);
}

@keyframes move {
  25% {
    opacity: 1;
  }
  33% {
    opacity: 1;
    transform: translateY(10px);
  }
  67% {
    opacity: 1;
    transform: translateY(25px);
  }
  100% {
    opacity: 0;
    transform: translateY(40px) scale3d(0.5, 0.5, 0.5);
  }
}

@keyframes pulse {
  to {
    opacity: 1;
  }
}

.zoom-in {
  /* animate in */
  /* animate out */
  /* Dark overlay, start state */
  /* animate in */
  /* animate out */
}

.zoom-in .magnific-popup {
  opacity: 0;
  transition: all 0.3s ease-in-out;
  transform: scale(0.8);
}

.zoom-in.mfp-ready .magnific-popup {
  opacity: 1;
  transform: scale(1);
}

.zoom-in.mfp-removing .magnific-popup {
  transform: scale(0.8);
  opacity: 0;
}

.zoom-in.mfp-bg {
  opacity: 0;
  transition: opacity 0.3s ease-out;
}

.zoom-in.mfp-ready.mfp-bg {
  opacity: 0.8;
}

.zoom-in.mfp-removing.mfp-bg {
  opacity: 0;
}

.hidden-wrapper {
  position: relative;
  padding: 2rem;
  margin: 1.2rem auto;
  background-color: #fff;
  width: 96%;
  max-width: 1024px;
}

.hidden-wrapper--lg {
  max-width: 1280px;
}

.hidden-wrapper--md {
  max-width: 768px;
}

.hidden-wrapper--sm {
  max-width: 480px;
}

.hidden-wrapper--strip {
  background: none;
  padding: 0;
}

.hidden-wrapper--no-gap {
  padding: 0;
}

.hidden-wrapper ._show-in--hidden-wrapper {
  display: block;
}

.wysiwyg-old {
  position: relative;
  font-size: 1rem;
  line-height: 1.5;
  color: #333333;
  font-family: Arial,sans-serif;
  z-index: 1;
}

.wysiwyg-old.seoText {
  font-size: 0.8rem;
}

.wysiwyg-old blockquote > :first-child, .wysiwyg-old > :first-child {
  margin-top: 0;
}

.wysiwyg-old blockquote > :last-child, .wysiwyg-old > :last-child {
  margin-bottom: 0;
}

.wysiwyg-old--lg {
  font-size: 1.25rem;
}

@media only screen and (min-width: 1024px) {
  .wysiwyg-old--lg {
    font-size: 1.4rem;
  }
}

.wysiwyg-old--center {
  text-align: center;
}

.wysiwyg-old h1, .wysiwyg-old h2, .wysiwyg-old h3, .wysiwyg-old h4, .wysiwyg-old h5, .wysiwyg-old h6 {
  clear: both;
  line-height: 1.25;
  font-weight: normal;
  margin: 1.2em 0 .8rem;
  color: #333333;
}

.wysiwyg-old h1 {
  font-weight: bold;
  font-size: 2rem;
}

@media only screen and (min-width: 480px) {
  .wysiwyg-old h1 {
    font-size: 2.25rem;
  }
}

@media only screen and (min-width: 768px) {
  .wysiwyg-old h1 {
    font-size: 2.625rem;
  }
}

.wysiwyg-old h2 {
  font-size: 2rem;
}

.wysiwyg-old h3 {
  font-size: 1.4rem;
}

.wysiwyg-old h4 {
  font-size: 1.375rem;
}

.wysiwyg-old h5 {
  font-size: 1.25rem;
}

.wysiwyg-old h6 {
  font-size: 1rem;
}

.wysiwyg-old + .wysiwyg {
  position: relative;
  padding: 1.875rem 0 0 0;
}

.wysiwyg-old p, .wysiwyg-old hr, .wysiwyg-old dl, .wysiwyg-old pre, .wysiwyg-old address, .wysiwyg-old table,
.wysiwyg-old .table-wrapper, .wysiwyg-old .media-wrapper {
  margin: 0.8em 0;
}

.wysiwyg-old blockquote {
  position: relative;
  font-size: 1.5rem;
  color: #333333;
  font-weight: 300;
  margin: 2.5rem 0;
  font-style: italic;
  padding: 0 0 0 5.625rem;
  text-align: center;
}

.wysiwyg-old blockquote:before {
  content: '“';
  font-family: Arial, sans-serif;
  font-weight: 700;
  color: #ec1c23;
  font-size: 7.5rem;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(0, -30%);
  line-height: 1.0;
}

.wysiwyg-old hr {
  clear: both;
  border-width: 0;
  border-top-width: 1px;
}

.wysiwyg-old ul, .wysiwyg-old ol {
  font-size: 1rem;
  padding: 0;
  margin: 1.6em 0;
}

.wysiwyg-old ol > li {
  list-style-type: none;
  padding: 0.3125rem 0 0 3.125rem;
  margin-bottom: 1.25rem;
  position: relative;
  counter-increment: step-counter;
}

.wysiwyg-old ol > li:before {
  content: counter(step-counter);
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 2.3333333em;
  height: 2.3333333em;
  background: #ec1c23;
  text-align: center;
  line-height: 2.3333333em;
  color: #fff;
  border-radius: 50%;
  font-size: 1rem;
  font-weight: 700;
  user-select: none;
}

.wysiwyg-old ul > li {
  position: relative;
  list-style: none;
  padding: 0.3125rem 0 0.9375rem 4.375rem;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTExIDc5LjE1ODMyNSwgMjAxNS8wOS8xMC0wMToxMDoyMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTUgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOkU5RDU5QzY0MEUzNDExRTdBNTQwQ0ZDQTg5OTA1ODA3IiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOkU5RDU5QzY1MEUzNDExRTdBNTQwQ0ZDQTg5OTA1ODA3Ij4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6RTlENTlDNjIwRTM0MTFFN0E1NDBDRkNBODk5MDU4MDciIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6RTlENTlDNjMwRTM0MTFFN0E1NDBDRkNBODk5MDU4MDciLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz4GE8HZAAABvUlEQVR42ryXv0sDMRTHc/VcLFioCIK6OTiJo4sggjooDnXyv+jYP0AR/AucBREHbbeqKPgfONRRQWhtxR8oopPg+U15gRCSa+4uucCHNLnjPjT3eO8di6KIcf4YC8A2uAbfIHLELmDCIwgZ3wyCEqYTsMbcjwXdZghpoEjb4Bj0HEh/QUN7hY5XHMsZGFGPhV5FJtTn8c0rkrZNUpdi/J4DE0wKpH2T1JUYc41c3QL2inTqXeZxIJZqmPZo+VFg+QxZ+tAPZCmwqp6OuiY57sGUCC6fYq3Ut9go9SmOlcaKsV4FPXAAhrJITQnEJN6Rrh1ZyrXSpOIx0EogN0qVzDULRmPfMfbGLeWxUilzbdA9rYHBZSEfKJXEVXGvVVTHyK2kqcUG+a2tVCdOkqtfwTK4o/U8zY9gCXSSJO+kRUKVP4GVpNJ+65Oi0nD5ItgEl+A5TbkKU5a5T3CYpU7mVY/FGBatHhf/0GI6B/EMzS9ys8cLQtFXzwXK4J1cDbW9bfI86kHMpTeSZ51/tvCGvik19G/gNG20asYkqIAyretBFFXEvymBc4ffSybqondnOXy0fYELsMUdwvcvwAAXWhx4ZFUNLwAAAABJRU5ErkJggg==) 0 0 no-repeat;
  margin-bottom: 0.9375rem;
}

.wysiwyg-old ul > li:after {
  content: '';
  display: block;
  position: absolute;
  width: 5rem;
  border-bottom: 1px solid #aeaeae;
  bottom: 0;
  left: 4.375rem;
}

.wysiwyg-old.seoText ul > li {
  list-style: disc;
  color: red;
  background: none;
  color: #333333;
  padding: 0 0 0 0px;
  list-style-position: inside;
  font-size: 0.8rem;
  padding-left: 25px;
}

.wysiwyg-old.seoText ul > li:after {
  display: none;
}

.wysiwyg-old.seoText h2 {
  font-size: 1.8rem;
}

.wysiwyg-old dd {
  margin-left: 1.5em;
}

.wysiwyg-old dt {
  font-weight: bold;
}

.wysiwyg-old dd + dt {
  margin: 1em 0 0;
}

.wysiwyg-old pre, .wysiwyg-old code, .wysiwyg-old kbd, .wysiwyg-old samp {
  font-style: normal;
  font-weight: normal;
  font-family: Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace;
}

.wysiwyg-old pre {
  tab-size: 4;
  padding: 1em 1.5em;
  background-color: #f5f5f5;
}

.wysiwyg-old code, .wysiwyg-old kbd, .wysiwyg-old samp {
  padding: 0 .4em;
}

.wysiwyg-old code {
  background-color: #f5f5f5;
}

.wysiwyg-old kbd {
  background-color: #e1f0fa;
}

.wysiwyg-old samp {
  background-color: #f7f8e2;
}

.wysiwyg-old iframe {
  border-width: 0;
}

.wysiwyg-old audio {
  width: 100%;
}

.wysiwyg-old audio[controls] {
  display: block;
}

.wysiwyg-old .media-wrapper__holder {
  background-color: #f5f5f5;
  position: relative;
}

.wysiwyg-old .media-wrapper__holder iframe, .wysiwyg-old .media-wrapper__holder video {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.wysiwyg-old table {
  width: 100%;
  background-color: transparent;
  border-collapse: collapse;
  border-spacing: 0;
  border: 1px solid #ddd;
}

.wysiwyg-old caption {
  margin-bottom: .5em;
  caption-side: top;
}

.wysiwyg-old tbody, .wysiwyg-old tfoot, .wysiwyg-old thead, .wysiwyg-old tr, .wysiwyg-old th, .wysiwyg-old td {
  border: inherit;
}

.wysiwyg-old th, .wysiwyg-old td {
  padding: .5em;
  text-align: left;
  vertical-align: top;
}

.wysiwyg-old th {
  font-weight: bold;
  text-align: center;
  background-color: #f5f5f5;
}

.wysiwyg-old .table-null, .wysiwyg-old .table-null th, .wysiwyg-old .table-null td {
  border-color: transparent;
}

.wysiwyg-old .table-null th {
  background: transparent;
}

.wysiwyg-old .table-zebra, .wysiwyg-old .table-zebra th, .wysiwyg-old .table-zebra td {
  border-color: transparent;
}

.wysiwyg-old .table-zebra th {
  background: transparent;
}

.wysiwyg-old .table-zebra tr:nth-child(odd) {
  background-color: #f5f5f5;
}

.wysiwyg-old a {
  display: inline-block;
  position: relative;
  color: #ec1c23;
  text-decoration: none;
  padding: 0 0.3125rem;
  transition: color .2s;
}

.wysiwyg-old a:before {
  content: '';
  display: block;
  position: absolute;
  bottom: 0;
  left: 5px;
  right: 5px;
  height: 1px;
  background: #ec1c23;
  transition: height .2s, left .2s .2s, right .2s .2s;
  z-index: -1;
}

.wysiwyg-old a:visited {
  color: #f04b51;
}

.wysiwyg-old a:hover {
  color: #fff;
  -webkit-transition: all .2s .2s;
  transition: all .2s .2s;
  background: #ec1c23;
  text-decoration: none;
}

.wysiwyg-old a:hover:before {
  height: 100%;
  left: 0;
  right: 0;
  transition: height .2s .2s, left .2s, right .2s;
}

.wysiwyg-old a:active {
  color: #000;
}

.wysiwyg-old b, .wysiwyg-old strong {
  font-weight: bold;
}

.wysiwyg-old i, .wysiwyg-old cite, .wysiwyg-old em, .wysiwyg-old var, .wysiwyg-old address, .wysiwyg-old dfn, .wysiwyg-old caption {
  font-style: italic;
}

.wysiwyg-old abbr[title], .wysiwyg-old dfn[title] {
  text-decoration: none;
  border-bottom: 1px dotted;
  cursor: help;
}

@media only screen and (max-width: 1023px) {
  .wysiwyg-old > pre {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

._clear:after {
  content: '';
  display: table;
  clear: both;
}

.table-wrapper {
  clear: both;
  display: block;
  position: relative;
  padding: 0;
  margin: 0;
}

.table-wrapper__holder {
  max-width: 100%;
  overflow-x: auto;
  padding: 0;
  margin: 0;
}

.table-wrapper__table {
  width: 100%;
  border-collapse: collapse;
}

.table-wrapper:before, .table-wrapper:after {
  content: '';
  width: 8px;
  position: absolute;
  top: 0;
  bottom: 0;
  pointer-events: none;
  opacity: 0;
  transition: opacity .25s linear;
}

.table-wrapper:before {
  left: 0;
  background: -moz-linear-gradient(left, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%);
  background: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%);
  background: linear-gradient(to right, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%);
}

.table-wrapper:after {
  right: 0;
  background: -moz-linear-gradient(left, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
  background: -webkit-linear-gradient(left, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
  background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
}

.table-wrapper--outside-left:before {
  opacity: .3;
}

.table-wrapper--outside-right:after {
  opacity: .3;
}

.content-image {
  position: relative;
}

.content-image--width-1200-and-more {
  display: block;
  float: none !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.view-text--article .content-image--width-600-and-more {
  display: block;
  float: none !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

@media only screen and (max-width: 1599px) {
  .content-image--width-1000-and-more {
    display: block;
    float: none !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

@media only screen and (max-width: 1399px) {
  .content-image--width-900-and-more {
    display: block;
    float: none !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

@media only screen and (max-width: 1279px) {
  .content-image--width-800-and-more {
    display: block;
    float: none !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

@media only screen and (max-width: 767px) {
  .content-image--width-500-and-more {
    display: block;
    float: none !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

@media only screen and (max-width: 639px) {
  .content-image--width-300-and-more {
    display: block;
    float: none !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

@media only screen and (max-width: 479px) {
  .content-image--width-200-and-more {
    display: block;
    float: none !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

@media only screen and (max-width: 374px) {
  .content-image--width-100-and-more {
    display: block;
    float: none !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

.title-old {
  line-height: 1.2;
  font-weight: bold;
  color: #333333;
  font-size: 2.625rem;
  margin: 1.2em 0 .8em;
  font-family: Coco Gothic, Arial;
}

.title-old:first-child {
  margin-top: 0;
}

.title-old--center {
  text-align: center;
}

.title-old--caps {
  text-transform: uppercase;
}

.title-old--decor {
  position: relative;
  padding: 0 0 0.875rem;
}

.title-old--decor:after {
  content: '';
  display: block;
  position: absolute;
  width: 3.75rem;
  height: 2px;
  background: #000;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
}

.title-old--white {
  color: #fff;
}

.title-old--lg {
  font-size: 1.75rem;
}

@media only screen and (min-width: 768px) {
  .title-old--lg {
    font-size: 2.125rem;
  }
}

@media only screen and (min-width: 768px) {
  .title-old--lg {
    font-size: 2.625rem;
  }
}

.title-old--md {
  font-size: 1.375rem;
}

@media only screen and (min-width: 768px) {
  .title-old--md {
    font-size: 1.625rem;
  }
}

.title-old--sm {
  font-size: 1.25rem;
}

.title-old--sm.title-old--decor:after {
  background: #000;
  left: 0;
  transform: translate(0, 0);
}

.breadcrumbs {
  width: 100%;
  display: block;
  position: relative;
  line-height: 0;
  margin: 0 0 2.8125rem;
  font-size: 14px;
  text-align: center;
}

.breadcrumbs:after {
  content: '';
  display: block;
  position: absolute;
  width: 3.75rem;
  height: 3px;
  background: #f00;
  left: 50%;
  margin-left: -1.875rem;
  bottom: -0.9375rem;
}

.breadcrumbs a {
  color: #f00;
  text-decoration: none;
}

.breadcrumbs a:hover {
  text-decoration: underline;
}

.breadcrumbs span {
  display: inline-block;
  position: relative;
  vertical-align: top;
  font-size: 1em;
  line-height: 1.4;
  margin: 0 .8em;
}

.breadcrumbs span:before, .breadcrumbs span:after {
  content: '';
  display: block;
  position: absolute;
  width: 5px;
  height: 2px;
  background: #f00;
  top: calc(50% - 1px);
  left: -1.1em;
}

.breadcrumbs span:before {
  transform-origin: 100% 0;
  transform: rotate(-45deg);
}

.breadcrumbs span:after {
  transform-origin: 100% 100%;
  transform: rotate(45deg);
}

.breadcrumbs span:first-child:before, .breadcrumbs span:first-child:after {
  display: none;
}

@media only screen and (max-width: 767px) {
  .breadcrumbs {
    display: block;
  }
  .breadcrumbs span, .breadcrumbs span:before {
    display: none;
  }
  .breadcrumbs span:nth-last-child(2) {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
    background: none;
    margin: 0;
  }
  .breadcrumbs span:nth-last-child(2):before {
    content: '<';
    margin: 0 5px 0 3px;
    display: none;
  }
}

.sitemap {
  max-width: 100%;
  position: relative;
  font-size: 1rem;
  margin-bottom: 2.5rem;
}

@media only screen and (min-width: 768px) {
  .sitemap {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.sitemap ul {
  list-style: none;
  padding: 0 0 0 2em;
  margin: 0;
}

.sitemap li {
  list-style: none;
  line-height: 1.4em;
}

.sitemap a {
  padding: .2em 1em .2em .5em;
  display: block;
  color: #333;
  text-decoration: none;
}

.sitemap a:hover {
  color: #ec1c23;
  background-color: rgba(0, 0, 0, 0.035);
}

.sitemap a:active {
  background-color: rgba(0, 0, 0, 0.035);
}

.sitemap > ul {
  padding: 0;
  margin: 1em 0;
  position: relative;
  overflow: hidden;
}

.sitemap > ul > li > ul {
  padding-left: 0;
}

.sitemap > ul ul {
  list-style: none;
  padding: 0 0 0 2em;
  margin: 0;
}

.sitemap > ul ul li {
  position: relative;
  margin: .1em 0;
}

.sitemap > ul ul li:hover {
  background-color: rgba(0, 0, 0, 0.035);
}

.sitemap > ul ul li a {
  position: relative;
  padding-left: 2.5em;
}

.sitemap > ul ul li a:before, .sitemap > ul ul li a:after {
  content: '';
  position: absolute;
  top: .2em;
  width: 1600px;
  height: 1.4em;
  text-align: right;
  opacity: .3;
  background: transparent repeat-x center right;
}

.sitemap > ul ul li a:before {
  left: 0;
  width: 2em;
  background-image: url(pic/sitemap-start.svg);
}

.sitemap > ul ul li a:after {
  right: 100%;
  background-image: url(pic/sitemap-middle.svg);
}

.sitemap > ul ul li:last-child > a:only-child:before {
  background-image: url(pic/sitemap-end.svg);
}

.button-old {
  display: inline-flex;
  flex-wrap: nowrap;
  align-items: center;
  height: 2.625rem;
  background: #ffdb4b;
  box-sizing: border-box;
  color: #000;
  justify-content: center;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 1rem;
  padding: 0 1.5625rem;
  user-select: none;
  border: none;
  outline: none !important;
  cursor: pointer;
  transition: transform .2s, border .2s, background .2s, color .2s, box-shadow .2s;
}

.button-old:hover, .button-old:focus {
  background: #ffce4b;
}

.button-old:active {
  background: #ffbd4b;
}

.button-old i {
  display: block;
  position: relative;
  width: 1.25rem;
  height: 1.25rem;
  animation: load .5s infinite;
  animation-play-state: paused;
}

.button-old i svg {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  max-width: 80%;
  max-height: 80%;
  fill: #000;
  transition: fill .2s;
}

.button-old span ~ i {
  margin-left: 0.625rem;
}

.button-old--lg {
  height: 3.375rem;
}

.button-old--lg i {
  width: 1.5625rem;
  height: 1.5625rem;
}

.button-old--full {
  width: 100%;
}

.button-old--hollow {
  background: transparent;
  color: #fff;
  border: 2px solid #fff;
}

.button-old--hollow:hover {
  border: 2px solid #ec1c23;
  background: #ec1c23;
  color: #fff;
}

.button-old--progress i {
  animation-play-state: running;
}

@keyframes load {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.pagination {
  text-align: center;
  margin: 3.75rem 0;
}

.pagination__link {
  display: inline-block;
  vertical-align: middle;
  color: #787a7d;
  font-size: 1.25rem;
  width: 2.5rem;
  height: 2.5rem;
  line-height: 2.5rem;
  text-align: center;
  text-decoration: none;
  border-radius: 0.25rem;
  margin: 0 2px;
  transition: background .2s, color .2s;
}

.pagination__link--active {
  background: #ffce4b;
  color: #000;
}

.pagination__link:hover {
  background: #ffce4b;
  color: #000;
}

.pagination__prev, .pagination__next {
  display: inline-block;
  position: relative;
  vertical-align: middle;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 0.25rem;
  transition: background .2s;
}

.pagination__prev svg, .pagination__next svg {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  fill: #ffce4b;
  max-width: 50%;
  max-height: 50%;
  transition: fill .2s;
}

.pagination__prev:hover, .pagination__next:hover {
  background: #ffce4b;
}

.pagination__prev:hover svg, .pagination__next:hover svg {
  fill: #000;
}

.pagination__prev {
  margin-right: 0.625rem;
}

.pagination__next {
  margin-left: 0.625rem;
}

/*.form {
	position: relative;

	&__sending {
	  position: absolute;
	  top: 0;
	  left: 0;
	  width: 100%;
	  height: 100%;
	  background: transparent url('pic/ajax-sending.svg') no-repeat 50% 50%;
	  background-size: 50% 50%;
	}
}
.form-element {
	position: relative;
	font-size: rem(16);
	margin: 0 0 rem(20);

	&__info {
		position: absolute;
		right: 0;
		top: 0;
		visibility: hidden;
		opacity: 0;
		font-size: 12px;
		color: #fff;
		padding: rem(5) rem(10);
		background: darken(#aaaaaa, 15%);
		border-radius: 0 4px 0 4px;
		transition: visibility .2s, opacity .2s, transform .2s;

		input:focus ~ &,
		textarea:focus ~ & {
			visibility: visible;
			opacity: 1;
		}
	}

	&__input {
		width: 100%;
		height: rem(56);
		padding: 0 rem(20);
		border: 1px solid  #aaaaaa;
		border-radius: 4px;
		outline: 0;
		background: #fff;
		transition: border .2s;
		@include placeholder(#a5a4a4);

		&:focus,
		&:hover {
			border: 1px solid darken(#aaaaaa, 15%);
		}

		&.has-error {
			//border-color: $color-crimson;
		}
	}

	&__textarea {
		width: 100%;
		height: rem(200);
		max-height: rem(200);
		padding: rem(20) rem(20);
		border: 1px solid  #aaaaaa;
		border-radius: 4px;
		outline: 0;
		background: #fff;
		box-sizing: border-box;
		resize: vertical;

		&:focus,
		&:hover {
			//border-color: $color-bermuda-gray;
		}

		&.has-error {
			//border-color: $color-crimson;
		}
	}

	&__select {
		height: rem(56);
		width: 100%;
		padding: 0 rem(20);
		border: 1px solid  #aaaaaa;
		border-radius: 4px;
		appearance: none;
		color: #333333;
		outline: none;
		background: #fff url("pic/down.svg") calc(100% - 15px) 50% no-repeat;
		background-size: rem(25);

		&::-ms-value {
			color: #333333;
		}
	}

	&__file-holder {
		position: relative;
		display: flex !important;
		justify-content: space-between;
		flex-wrap: nowrap;
		align-items: center;
		margin: rem(10) 0;

		& > label {
			position: relative;
			display: flex !important;
			justify-content: space-between;
			flex-wrap: nowrap;
			align-items: center;
			cursor: pointer;
			max-width: calc(100% - 3.3333333rem);
			min-width: calc(100% - 3.3333333rem);
		}
	}

	&__file-input {
		visibility: hidden;
		opacity: 0;
		position: absolute;
		width: 1px;
		height: 1px;
		overflow: hidden;
	}

	&__file-result {
		display: block;
		flex-grow: 1;
		font-size: rem(20);
		color: $color-primary;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		font-weight: 300;

		&:hover {
			text-decoration: none;
		}
	}

	&__file-icon {
		flex-shrink: 0;
		position: relative;
		display: block;
		height: rem(40);
		width: rem(40);
		background-color: #fff;
		margin: 0 rem(10) 0 0;
		//box-shadow: 0 1px 2px $color-bombay;
		border-radius: rem(4);

		svg {
			position: absolute;
			max-width: 60%;
			max-height: 60%;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			margin: auto;
			//fill: $color-blue;
		}
	}

	&__file-remove {
		flex-shrink: 0;
		position: relative;
		display: block;
		height: rem(40);
		width: rem(40);
		margin: 0 0 0 rem(10);
		border-radius: rem(4);
		cursor: pointer;
		transition: background .25s;

		&:before,
		&:after {
			content: '';
			display: block;
			position: absolute;
			width: 80%;
			height: 3px;
			background: $color-primary;
			top: calc(50% - 1px);
			left: 10%;
			transform-origin: 50%;
			transition: background .25s;
		}

		&:before {
			transform: rotate(-45deg);
		}

		&:after {
			transform: rotate(45deg);
		}

		&:hover {
			background: $color-primary;
			&:before,
			&:after {
				background: #fff;
			}
		}
	}

	&--check,
	&--radio {
		position: relative;
		display: flex;
		line-height: 1.4;
		font-size: rem(13);
		//fill: $color-blue;
		//color: $color-bermuda-gray;
		input {
			z-index: -1;
			position: absolute;
			top: 0;
			left: 0;
			height: 1px;
			width: 1px;
		}
		i, span {
			vertical-align: middle;
			display: inline-block; // IE 10
		}
		i {
			position: relative;
			height: rem(16);
			width: rem(16);
			flex-shrink: 0;
			//border: 1px solid $color-bombay;
			cursor: pointer;
			transition: border .2s, box-shadow .2s;
			&:hover {
				//border-color: $color-bermuda-gray;
			}
		}
		svg {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			max-width: 70%;
			max-height: 70%;
			margin: auto;
			fill: #fff;
			transform: scale(0);
			transform-origin: bottom center;
			transition: transform .2s;
		}

		span {
			padding-left: rem(5);
			flex-grow: 1;
			flex-shrink: 1;
		}

		a {
			color: inherit;
			&:hover {
				text-decoration: none;
			}
		}
	}

	&--radio {
		input {
			&:checked ~ i {
				//border-color: $color-blue;
				//box-shadow: inset 0 0 0 rem(4) $color-blue;
			}
			&.has-error ~ i {
				//border-color: $color-crimson;
			}
		}
		i {
			border-radius: 50%;
		}
	}

	&--check {
		input {
			&:checked ~ i {
				//background: $color-blue;
				//border-color: $color-blue;
			}
			&:checked ~ i svg {
				transform: scale(1);
			}
			&.has-error ~ i {
				//border-color: $color-crimson;
			}
		}
		i {
			border-radius: 4px;
		}
	}

	&__checkbox {
		opacity: 0;
		width: 1px;
		height: 1px;
		position: absolute;

		& ~ i {
			display: block;
			width: rem(20);
			height: rem(20);
			box-sizing: border-box;
			border: 1px solid #c8c8c8;
			background: #fff;
			border-radius: 2px;
			cursor: pointer;
			position: relative;
			margin: 0 rem(20) 0 0;
			transition: background .2s;

			svg {
				position: absolute;
				max-width: 80%;
				max-height: 80%;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);
				visibility: hidden;
				opacity: 0;
				fill: #fff;
				transition: opacity .2s, visibility .2s;
			}
		}

		&:checked ~ i {
			//background: $color-blue;
			//border: 1px solid $color-blue;

			svg {
				visibility: visible;
				opacity: 1;
			}
		}
	}
}
select::-ms-expand {
	display: none;
}
.form-tips {
	font-size: 12px;
	color: #aaaaaa;
}
.form-label {
	font-size: rem(14);
	user-select: none;
	cursor: default;
	margin-bottom: rem(10);
	font-weight: 300;

	label & {
		cursor: inherit;
	}
}*/
.form,
.form-group,
.form-controller {
  position: relative;
}

.form-control {
  border: 1px solid #ccc;
  padding: 0.8rem 1rem;
  width: 100%;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1rem;
  line-height: 1.4em;
  transition: border-color 0.2s ease-in-out;
}

.form-control:hover {
  border-color: #8c8c8c;
}

.form-control.has-error {
  border-color: #f00;
}

input[type="file"].form-control.has-error ~ label.has-error {
  visibility: visible;
  position: relative;
}

textarea.form-control {
  max-width: 100%;
  min-width: 100%;
}

[type="checkbox"].form-control {
  width: 20px;
  height: 20px;
  padding: 0;
}

[type="checkbox"].form-control:checked ~ span {
  opacity: 1;
}

.form-control--transparent {
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: #fff;
}

.form-control--arrow {
  background: white url(pic/down.svg) calc(100% - 15px) 50% no-repeat;
  background-size: 1.5625rem;
}

.form-flag__input.has-error ~ .form-flag__value {
  color: #f00;
}

.form-label {
  display: inline-block;
  vertical-align: top;
  font: 700 1rem/1.4em Arial, Helvetica, sans-serif;
}

label.has-error {
  visibility: hidden;
  position: absolute;
  left: 0;
  padding: 0.3rem 0.5rem;
  font: 11px/1.3em Arial, Helvetica, sans-serif;
  color: #fff;
  background: #ff0505;
  letter-spacing: 0.5px;
  width: 100%;
  margin-top: 3px;
  display: inline-block;
  vertical-align: top;
  z-index: 1;
}

label.has-error::after {
  bottom: 100%;
  left: 10%;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border: 4px solid rgba(255, 51, 51, 0);
  border-bottom-color: #f33;
  margin-left: -4px;
}

.form-control:focus ~ label.has-error, label.has-error--visible {
  visibility: visible;
  position: relative;
}

.form-error {
  font-size: 12px;
  color: #f00;
  margin-bottom: 1.5625rem;
}

.custom-file__input {
  overflow: hidden;
  width: 1px;
  height: 1px;
  visibility: hidden;
  position: absolute;
  left: 0;
}

.custom-file__result {
  display: block;
  cursor: pointer;
}

.custom-file__clear {
  display: none;
  position: absolute;
  width: 2rem;
  height: 2rem;
  background: #fff;
  top: 0.5rem;
  right: 1px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  fill: #f00;
}

.has-file .custom-file__clear {
  display: flex;
}

.custom-checkbox {
  position: relative;
}

.custom-checkbox__input {
  position: relative;
  display: block;
  border: 1px solid #ccc;
  width: 20px;
  height: 20px;
  cursor: pointer;
  outline: none;
  z-index: 1;
}

.custom-checkbox__input:checked ~ .custom-checkbox__checked {
  opacity: 1;
}

.custom-checkbox__checked {
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  font-size: 14px;
  text-align: center;
  z-index: 0;
  transition: 0.15s ease-out;
}

#fPopUp {
  max-width: 80% !important;
  min-width: 280px !important;
}

@media only screen and (min-width: 768px) {
  #fPopUp {
    max-width: 50% !important;
    min-width: 400px !important;
  }
}

.bg-section {
  padding: 33% 0 0;
  background-size: cover;
}

.project-card {
  display: block;
  position: relative;
  background: #ccc 50% 50%;
  padding: 75% 0 0;
  box-sizing: border-box;
  background-size: cover;
  text-decoration: none;
}

.project-card__content {
  position: absolute;
  display: flex;
  justify-content: center;
  background: rgba(236, 28, 35, 0.8);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  visibility: hidden;
  opacity: 0;
  transition: visibility .25s ease-in .2s, opacity .25s ease-in .2s;
}

.project-card:hover .project-card__content {
  transition: visibility .25s ease-in, opacity .25s ease-in;
  visibility: visible;
  opacity: 1;
}

.touchevents .project-card__content {
  visibility: visible;
  opacity: 1;
  top: auto;
  padding: 1.875rem 6.25rem 1.875rem 0;
  background-color: rgba(0, 0, 0, 0.5);
  background-image: url(pic/right-arrow.svg);
  background-position: calc(100% - 18px) 50%;
  background-repeat: no-repeat;
  background-size: 3.75rem;
}

@media only screen and (min-width: 480px) {
  .touchevents .project-card__content {
    padding: 1.875rem 0;
    background-image: none;
  }
}

.project-card__body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.project-card__title {
  font-size: 1.625rem;
  color: #fff;
  font-weight: bold;
  margin-bottom: 0;
  max-width: 75%;
  text-align: center;
  visibility: hidden;
  opacity: 0;
  transform: translate(0, -3.125rem);
  transition: transform .25s ease, visibility .25s ease, opacity .25s ease;
}

.project-card:hover .project-card__title {
  visibility: visible;
  opacity: 1;
  transform: translate(0, 0);
  transition: transform .25s ease .2s, visibility .25s ease .2s, opacity .25s ease .2s;
}

@media only screen and (min-width: 768px) {
  .project-card__title {
    font-size: 1.125rem;
  }
}

@media only screen and (min-width: 1024px) {
  .project-card__title {
    font-size: 1.25rem;
    margin-bottom: 1.25rem;
    max-width: 60%;
  }
}

@media only screen and (min-width: 1280px) {
  .project-card__title {
    font-size: 1.625rem;
  }
}

.touchevents .project-card__title {
  visibility: visible;
  opacity: 1;
  transform: translate(0, 0);
  margin-bottom: 0;
}

.project-card__meta {
  display: none;
  max-width: 80%;
  text-align: center;
  visibility: hidden;
  opacity: 0;
  transform: translate(0, 3.125rem);
  transition: transform .25s ease, visibility .25s ease, opacity .25s ease;
}

.project-card:hover .project-card__meta {
  visibility: visible;
  opacity: 1;
  transform: translate(0, 0);
  transition: transform .25s ease .2s, visibility .25s ease .2s, opacity .25s ease .2s;
}

@media only screen and (min-width: 1024px) {
  .project-card__meta {
    display: block;
  }
}

.touchevents .project-card__meta {
  display: none !important;
}

.project-card__service {
  display: inline-block;
  font-size: 12px;
  color: #fff;
  text-transform: uppercase;
  margin: 0 0.625rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.project-card__service span {
  display: inline-block;
  vertical-align: middle;
}

.project-card__service i {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 20px;
}

.project-card__service i svg {
  fill: #fff;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  max-width: 80%;
  max-height: 80%;
}

.blog-card {
  position: relative;
  display: flex;
  color: #333333;
  padding: 0.9375rem;
  text-decoration: none;
  height: 100%;
}

.blog-card:before {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity .25s, width .25s, height .25s;
}

@media only screen and (min-width: 1024px) {
  .blog-card {
    padding: 1.5625rem;
  }
}

@media only screen and (min-width: 768px) {
  .blog-card {
    display: block;
  }
}

.blog-card:hover:before {
  opacity: 1;
}

.blog-card__image {
  position: relative;
  z-index: 2;
  display: block;
  margin: 0 1.25rem 0 0;
  width: 50%;
  max-width: 50%;
  min-width: 40%;
}

.blog-card__image img {
  display: block;
  width: 100%;
  transition: filter .25s;
}

@media only screen and (min-width: 768px) {
  .blog-card__image img {
    position: absolute;
    min-width: 100%;
    min-height: 100%;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: auto;
  }
}

@media only screen and (min-width: 768px) {
  .blog-card__image {
    margin: 0 0 1.25rem;
    width: 100%;
    padding-top: 50%;
    position: relative;
    height: 0;
    min-width: 0;
    max-width: 100%;
    overflow: hidden;
  }
}

.blog-card__body {
  position: relative;
  z-index: 2;
}

.blog-card__title {
  display: block;
  margin: 0 0 1.25rem;
  font-size: 1.25rem;
  font-weight: bold;
  transition: color .25s;
}

.blog-card:hover .blog-card__title {
  color: #ec1c23;
}

@media only screen and (min-width: 1024px) {
  .blog-card__title {
    font-size: 1.4rem;
  }
}

.blog-card__meta {
  font-size: 0.875rem;
}

.service-block {
  position: relative;
  z-index: 1;
  padding: 3.75rem 0;
  background: 50% no-repeat;
  display: flex;
  height: 100%;
  background-size: cover;
}

.service-block:after {
  content: '';
  display: block;
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.35);
  visibility: hidden;
  opacity: 0;
  transition: visibility .2s ease-in, opacity .2s ease-in;
}

.service-block:hover:after {
  visibility: visible;
  opacity: 1;
}

.service-block__content {
  z-index: 2;
  position: relative;
  width: 100%;
  max-width: 840px;
  box-sizing: border-box;
  padding: 0 1.25rem;
}

@media only screen and (min-width: 768px) {
  .service-block__content {
    padding: 0 2.5rem;
  }
}

.service-block__body {
  position: relative;
  color: #fff;
}

@media only screen and (min-width: 768px) {
  .service-block__body {
    padding-left: 6.25rem;
  }
}

.service-block__icon {
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  width: 4.375rem;
  height: 4.375rem;
}

@media only screen and (min-width: 768px) {
  .service-block__icon {
    display: block;
  }
}

.service-block__icon svg {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  max-width: 100%;
  max-height: 100%;
  fill: #fff;
}

.service-block__title {
  font-size: 1.875rem;
  font-weight: 700;
  margin-bottom: 0.3125rem;
  color: #fff;
  display: inline-block;
  text-decoration: none;
}

.service-block__title:hover {
  text-decoration: underline;
}

@media only screen and (min-width: 1024px) {
  .service-block__title {
    font-size: 2.4rem;
  }
}

.service-block__caption {
  font-size: 1.25rem;
  font-weight: 300;
}

@media only screen and (min-width: 1024px) {
  .service-block__caption {
    font-size: 1.4rem;
  }
}

.service-block__image {
  margin: 0.9375rem 0 0.625rem;
}

@media only screen and (min-width: 1280px) {
  .service-block--odd {
    justify-content: flex-end;
  }
}

.service-block--even {
  justify-content: flex-start;
}

@media only screen and (min-width: 1280px) {
  .service-block--even {
    transform: translate(0, -3.125rem);
  }
}

.service-block--lg {
  justify-content: center;
}

.service-block--lg .service-block__content {
  max-width: 1680px;
}

.service-menu {
  position: relative;
  column-count: 1;
  column-gap: 1.25rem;
  display: inline-block;
  margin: 1.25rem 0 0;
  transition: visibility .35s, opacity .35s, left .35s ease-out;
}

html.touchevents .service-menu {
  visibility: visible !important;
  opacity: 1 !important;
}

@media only screen and (min-width: 480px) {
  .service-menu {
    column-count: 2;
  }
}

@media only screen and (min-width: 1280px) {
  .service-menu {
    visibility: hidden;
    left: -2.5rem;
    opacity: 0;
  }
}

.service-menu__item {
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.service-menu__item a {
  display: inline-block;
  position: relative;
  padding: 0.3125rem 0.625rem;
  color: #fff;
  text-decoration: none;
  font-size: 12px;
  text-transform: uppercase;
  transition: color .2s, background;
}

.service-menu__item a:before {
  content: '';
  display: block;
  position: absolute;
  bottom: 0;
  left: 5px;
  right: 5px;
  height: 100%;
  z-index: -1;
}

.service-menu__item a:hover {
  color: #000;
  transition: color .2s;
}

.service-menu__item a:hover:before {
  height: 100%;
  left: 0;
  right: 0;
  background: #fff;
  transition: height .2s, background .2s;
}

@media only screen and (min-width: 1024px) {
  .service-menu__item a {
    font-size: 14px;
  }
}

.service-block:hover .service-menu {
  visibility: visible;
  opacity: 1;
  left: 0;
}

.hidden-menu {
  display: block;
  position: fixed;
  height: 100%;
  z-index: 500;
  top: 0;
  box-sizing: content-box;
  transition: width 0.25s ease-in;
  width: 0;
  left: 0;
  right: auto;
  overflow: hidden;
}

.hidden-menu.is-active {
  width: 100%;
}

.hidden-menu__body {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background: #26272a;
  transition: transform .2s ease;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  top: 4.875rem;
  width: 100vw;
}

.hidden-menu__content {
  position: relative;
  flex-grow: 1;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  box-sizing: border-box;
  flex-shrink: 1;
}

.hidden-menu__wrapper {
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: auto;
  padding: 0 1.5625rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.hidden-menu__footer {
  position: relative;
  z-index: 2;
  overflow: hidden;
  padding-top: 1.5625rem;
  flex-shrink: 0;
  box-shadow: 0 -15px 25px 0 #26272a;
}

.mobile-menu {
  display: inline-block;
  vertical-align: top;
}

.mobile-menu__title {
  display: block;
  margin: 1.5625rem 0;
  text-align: center;
}

@media only screen and (min-width: 1024px) {
  .mobile-menu__title {
    text-align: left;
  }
}

.mobile-menu__title a {
  font-size: 2rem;
  color: #fff;
  text-decoration: none;
  font-weight: 700;
  transition: color .2s;
  user-select: none;
}

.mobile-menu__title a:hover {
  color: #ec1c23;
}

.mobile-menu__item {
  display: block;
  margin-bottom: 0.625rem;
  text-align: left;
}

@media only screen and (min-width: 1024px) {
  .mobile-menu__item {
    text-align: left;
  }
}

.mobile-menu__link {
  color: #fff;
  font-size: 1.1rem;
  text-decoration: none;
  transition: color .2s;
  user-select: none;
  vertical-align: middle;
  margin-right: 10px;
}

@media only screen and (min-width: 1024px) {
  .mobile-menu__link {
    font-size: 16px;
  }
}

.mobile-menu__link:hover {
  color: #ec1c23;
}

.mobile-menu__button {
  display: inline-block;
  vertical-align: middle;
  width: 2rem;
  height: 2rem;
  background: #ec1c23;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  transform: rotateX(180deg);
  transition: all 0.3s linear 0.2s;
}

@media only screen and (min-width: 1024px) {
  .mobile-menu__button {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.mobile-menu__button svg {
  position: absolute;
  max-width: 70%;
  max-height: 70%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  fill: #fff;
  transform: rotateX(0deg);
}

.mobile-menu__button--active {
  transform: rotateX(0deg);
}

.mobile-menu__contact {
  font-size: 1rem;
  color: #787a7d;
  margin: 0 0 0.9375rem;
  line-height: 1.4;
  position: relative;
  text-align: center;
}

@media only screen and (min-width: 1024px) {
  .mobile-menu__contact {
    text-align: left;
    padding-left: 2.1875rem;
  }
}

.mobile-menu__icon {
  width: 1.5625rem;
  height: 1.5625rem;
  position: absolute;
  left: 0;
  top: 0;
  display: none;
}

@media only screen and (min-width: 1024px) {
  .mobile-menu__icon {
    display: block;
  }
}

.mobile-menu__icon svg {
  max-width: 100%;
  max-height: 100%;
  fill: #787a7d;
}

.mobile-menu__phone, .mobile-menu__mail {
  color: #787a7d;
  text-decoration: none;
  transition: color .25s;
}

.mobile-menu__phone:hover, .mobile-menu__mail:hover {
  color: #ec1c23;
}

.mobile-submenu {
  padding: 0.625rem 0 1.25rem 0;
  margin: 0;
  display: none;
}

.mobile-submenu > li {
  list-style: none;
  padding: 0;
  margin: 0;
}

.mobile-submenu__link {
  display: inline-block;
  line-height: 1.4;
  font-size: 1.125rem;
  transition: color .2s;
  user-select: none;
  color: rgba(255, 255, 255, 0.8);
  text-decoration: none;
  padding: 0.3125rem 0;
}

@media only screen and (min-width: 1024px) {
  .mobile-submenu__link {
    font-size: 14px;
    padding: 0;
  }
}

.mobile-submenu__link:hover {
  color: #ec1c23;
}

.page-head {
  position: relative;
  height: 31.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-size: cover;
  background-position: 50%;
  color: #fff;
  line-height: 1.2;
}

.page-head + .page-section {
  margin-top: 2.5rem;
}

.page-head--simple {
  color: #333333;
  height: auto;
  padding: 2rem 0;
  border-bottom: 1px solid #ddd;
}

.page-head__title {
  font-size: 1.875rem;
  font-family: Coco Gothic, Arial;
  font-weight: 700;
  max-width: 940px;
  text-align: center;
  margin: 0 0 0.2em;
  text-transform: uppercase;
  padding: 0 0.9375rem;
}

@media only screen and (min-width: 480px) {
  .page-head__title {
    font-size: 2.1875rem;
  }
}

@media only screen and (min-width: 768px) {
  .page-head__title {
    font-size: 2.1875rem;
  }
}

@media only screen and (min-width: 1280px) {
  .page-head__title {
    font-size: 3.4875rem;
  }
}

.page-head__title--blog {
  font-size: 1.875rem;
  text-transform: none;
  max-width: 1400px;
}

@media only screen and (min-width: 480px) {
  .page-head__title--blog {
    font-size: 2.1875rem;
  }
}

@media only screen and (min-width: 768px) {
  .page-head__title--blog {
    font-size: 2.5rem;
  }
}

@media only screen and (min-width: 1280px) {
  .page-head__title--blog {
    font-size: 3.5rem;
  }
}

.page-head__caption {
  font-size: 1.25rem;
  max-width: 940px;
  text-align: center;
  padding: 0 0.9375rem;
  font-family: 'ProximaNova', sans-serif;
  font-weight: 300;
}

@media only screen and (min-width: 480px) {
  .page-head__caption {
    font-size: 1.5rem;
  }
}

@media only screen and (min-width: 768px) {
  .page-head__caption {
    font-size: 1.625rem;
  }
}

.page-head__prev, .page-head__next {
  display: flex;
  text-decoration: none;
  color: rgba(51, 51, 51, 0.25);
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  align-items: center;
  flex-basis: 10rem;
  max-width: 10rem;
  font-size: 1.5rem;
  font-family: 'ProximaNova', sans-serif;
  font-weight: 300;
  transition: color .25s;
}

@media only screen and (min-width: 1024px) {
  .page-head__prev, .page-head__next {
    display: flex;
  }
}

.page-head__prev:hover, .page-head__next:hover {
  color: rgba(51, 51, 51, 0.6);
}

.page-head__prev:hover i svg, .page-head__next:hover i svg {
  fill: rgba(51, 51, 51, 0.6);
}

.page-head__prev i, .page-head__next i {
  display: block;
  width: 2.5rem;
  height: 3.75rem;
  min-width: 2.5rem;
}

.page-head__prev i svg, .page-head__next i svg {
  max-width: 100%;
  max-height: 100%;
  fill: rgba(51, 51, 51, 0.25);
  transition: fill .25s;
}

.page-head__prev span, .page-head__next span {
  display: block;
  text-align: center;
}

.page-head__prev {
  left: 1.875rem;
  flex-direction: row;
}

.page-head__prev i {
  margin: 0 0.9375rem 0 0;
}

.page-head__next {
  right: 1.875rem;
  flex-direction: row-reverse;
}

.page-head__next i {
  margin: 0 0 0 0.9375rem;
}

.below-head-image {
  margin: -2.5rem 0 2.5rem 0;
  user-select: none;
  display: none;
}

@media only screen and (min-width: 768px) {
  .below-head-image {
    display: block;
  }
}

.below-head-image img {
  display: block;
  margin: 0 auto;
}

.service-preview {
  margin-bottom: 3.125rem;
}

.service-preview__icon {
  position: relative;
  width: 7.5rem;
  height: 7.5rem;
  border-radius: 3.75rem;
  background: #fff2f2;
  margin-bottom: 1.25rem;
  transition: background .3s;
}

.service-preview__icon svg {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  max-width: 60%;
  max-height: 60%;
  fill: #ec1c23;
  transition: fill .3s;
}

.service-preview:hover .service-preview__icon svg {
  fill: #fff;
}

.service-preview:hover .service-preview__icon {
  background: #ec1c23;
}

.service-preview__body {
  padding-left: 2.1875rem;
  line-height: 1.5;
}

.service-preview__title {
  font-size: 1.5rem;
  color: #333333;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 1rem;
  transition: color .3s;
  text-decoration: none;
}

.service-preview__title:hover {
  color: #ec1c23;
}

.service-preview__descr {
  font-size: 1rem;
  font-family: 'ProximaNova', sans-serif;
}

.service-preview__descr a {
  color: #787a7d;
  text-decoration: none;
}

.service-preview__descr a:hover {
  color: #ec1c23;
  text-decoration: underline;
}

.process-slider {
  position: relative;
  max-width: 1680px;
  width: 100%;
  margin: 3.125rem auto 2.5rem;
}

.process-slider__prev, .process-slider__next {
  position: absolute;
  cursor: pointer;
  z-index: 100;
  top: 50%;
  width: 2.1875rem;
  height: 3.75rem;
  transform: translate(0, -50%);
  opacity: .5;
  transition: left .2s, right .2s, opacity .2s;
}

@media only screen and (min-width: 1024px) {
  .process-slider__prev, .process-slider__next {
    width: 4.375rem;
    height: 7.5rem;
  }
}

.process-slider__prev svg, .process-slider__next svg {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  max-width: 100%;
  max-height: 100%;
  fill: none;
  stroke: #333333;
  stroke-width: 1px;
  transition: stroke .2s;
}

.process-slider__prev:hover, .process-slider__next:hover {
  opacity: 1;
}

.process-slider__prev {
  left: 0.625rem;
}

.process-slider__prev:active {
  left: 0;
}

.process-slider__next {
  right: 0.625rem;
}

.process-slider__next:active {
  right: 0;
}

.process-slider__pagination {
  text-align: center;
  margin-top: 2.1875rem;
}

.process-slider__pagination .swiper-pagination-bullet {
  display: inline-block;
  width: 14px;
  height: 14px;
  box-sizing: border-box;
  border-radius: 7px;
  background: transparent;
  border: 2px solid #b0b0b0;
  opacity: 1;
  margin: 0 4px;
  transition: background .25s, border-color .25s;
}

.process-slider__pagination .swiper-pagination-bullet:hover {
  border-color: #ec1c23;
}

.process-slider__pagination .swiper-pagination-bullet-active {
  background: #ec1c23;
  border-color: #ec1c23;
}

.process-slide {
  background: #f2f2f5;
}

.process-slide__image img {
  display: block;
}

.process-slide__caption {
  text-align: center;
  margin: 1.5625rem 0 0;
  color: #333333;
  font-size: 1.5rem;
  font-weight: 700;
}

.service-subnav {
  background: #f2f2f2;
  border-bottom: 1px solid #c8c8c8;
  text-align: center;
  padding: 1rem;
  margin: 0 0 2.5rem 0;
  font-size: 0;
  display: none;
}

.service-subnav + .page-section {
  margin-top: 2.5rem;
}

.service-subnav.is-stuck {
  margin: 0;
  z-index: 200;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 0 1rem;
  max-width: 100%;
  overflow: auto;
}

@media only screen and (min-width: 1024px) {
  .service-subnav {
    display: block;
  }
}

.service-subnav__link {
  display: inline-block;
  padding: 0.75rem 0.9375rem;
  margin: 0.25rem 0;
  color: #787a7d;
  font-size: 12px;
  text-transform: uppercase;
  text-decoration: none;
  transition: background .25s;
  user-select: none;
}

@media only screen and (min-width: 768px) {
  .service-subnav__link {
    font-size: 14px;
  }
}

.service-subnav__link:hover {
  background: #fff;
}

.service-subnav__link--active {
  background: #ec1c23;
  color: #fff;
}

.service-subnav__link--active:hover {
  background: #ec1c23;
}

.work-section {
  background: #26272a url("pic/work-bg.jpg") 50% 0 no-repeat;
  padding: 5rem 0 0 0;
}

.work-card {
  margin: 0 0 5rem;
}

.work-card__icon {
  display: block;
  width: 7.5rem;
  height: 7.5rem;
  position: relative;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 3.75rem;
  margin: 0 0 1.25rem;
  transition: background .3s;
}

.work-card__icon svg {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  max-width: 60%;
  max-height: 60%;
  fill: #ec1c23;
  transition: fill .3s;
}

.work-card:hover .work-card__icon {
  background: #ec1c23;
}

.work-card:hover .work-card__icon svg {
  fill: #fff;
}

.work-card__body {
  margin-left: 2.5rem;
}

.work-card__title {
  display: inline-block;
  text-decoration: none;
  font-size: 1.5rem;
  color: #fff;
  text-transform: uppercase;
  line-height: 1.2;
  margin: 0 0 1.25rem;
  font-weight: 700;
  transition: color .3s;
}

.work-card__title:hover {
  color: #ec1c23;
}

.work-card__descr {
  font-size: 1rem;
  color: #a0a2a5;
  margin: 0 0 1.25rem;
}

.advantage {
  position: relative;
}

.advantage:after {
  content: '';
  display: block;
  position: absolute;
  width: 80%;
  height: 10px;
  left: 10%;
  bottom: -1.875rem;
  background: linear-gradient(right, rgba(201, 201, 201, 0.3), #c9c9c9, rgba(201, 201, 201, 0.3));
  filter: blur(5px);
  border-radius: 50%;
}

.advantage--1 .advantage__circle {
  transform: rotate(135deg);
}

.advantage--1 .advantage__value {
  transform: rotate(-135deg);
}

.advantage--1:hover .advantage__circle {
  transform: rotate(315deg);
}

.advantage--1:hover .advantage__value {
  transform: rotate(-315deg);
}

.advantage--2 .advantage__circle {
  transform: rotate(-135deg);
}

.advantage--2 .advantage__value {
  transform: rotate(135deg);
}

.advantage--2:hover .advantage__circle {
  transform: rotate(45deg);
}

.advantage--2:hover .advantage__value {
  transform: rotate(-45deg);
}

.advantage--3 .advantage__circle {
  transform: rotate(-45deg);
}

.advantage--3 .advantage__value {
  transform: rotate(45deg);
}

.advantage--3:hover .advantage__circle {
  transform: rotate(135deg);
}

.advantage--3:hover .advantage__value {
  transform: rotate(-135deg);
}

.advantage--4 .advantage__circle {
  transform: rotate(45deg);
}

.advantage--4 .advantage__value {
  transform: rotate(-45deg);
}

.advantage--4:hover .advantage__circle {
  transform: rotate(225deg);
}

.advantage--4:hover .advantage__value {
  transform: rotate(-225deg);
}

.advantage__circle {
  width: 15.625rem;
  height: 15.625rem;
  margin: 0 auto 4.375rem;
  position: relative;
  cursor: default;
  transition: transform .35s;
  will-change: transform;
}

.advantage__value {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  user-select: none;
  transition: transform .35s;
  will-change: transform;
}

.advantage__count {
  font-size: 3.125rem;
  color: #ec1c23;
  line-height: 1.2;
  font-weight: 300;
}

.advantage__caption {
  font-size: 1.125rem;
  color: #9a9c9a;
  max-width: 70%;
  line-height: 1.2;
  font-weight: 300;
}

.service-tech {
  text-align: center;
  margin: 3.75rem 0 0;
}

.service-tech__title {
  font-size: 2rem;
  color: #333333;
  font-weight: 300;
}

.service-tech__image {
  margin: 1.875rem 0 0;
}

.valuation-section {
  background: url("pic/valuation.jpg") 50% no-repeat;
  background-size: cover;
  padding: 6.875rem 0;
}

.popup-wrapper {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
}

.popup-wrapper--vacancy {
  max-width: 1400px;
}

.vacancy-popup {
  padding: 3.125rem 2.5rem;
  background: #f2f2f2;
  color: #787a7d;
  font-size: 1.125rem;
  font-weight: 300;
}

@media only screen and (min-width: 768px) {
  .vacancy-popup {
    padding: 5rem 6.25rem;
  }
}

.vacancy-popup__title {
  color: #333333;
  font-size: 2.5rem;
  font-weight: 300;
  margin: 0 0 1.875rem;
}

.vacancy-popup__caption {
  font-size: 1.625rem;
  font-weight: 300;
  margin: 0 0 1.875rem;
}

.vacancy-popup ul {
  padding: 0 0 0 1.25rem;
  margin: 0;
}

.vacancy-popup ul:not(:last-child) {
  margin: 0 0 3.125rem;
}

.vacancy-popup ul li {
  margin: 0 0 0.9375rem;
}

.cookie {
  position: fixed;
  bottom: -6.25rem;
  background: rgba(38, 39, 42, 0.7);
  padding: 0 1.25rem;
  width: 96%;
  max-width: 1440px;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  z-index: 200;
  color: #fff;
  border-radius: 4px;
  visibility: hidden;
  opacity: 0;
  font-size: 1rem;
  text-align: center;
  transition: bottom .4s, visibility .3s, opacity .3s;
}

.cookie a {
  color: #fff;
}

.cookie a:hover {
  text-decoration: none;
}

.cookie--visible {
  visibility: visible;
  opacity: 1;
  bottom: 1.25rem;
}

.cookie-icon {
  display: inline-block;
  vertical-align: middle;
  width: 2.375rem;
  height: 2.375rem;
  position: relative;
  -webkit-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
  margin: 0 0.9375rem 0 0;
}

.cookie-icon svg {
  fill: #ec1c23;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  max-width: 100%;
  max-height: 100%;
}

.partner {
  display: flex;
  align-items: center;
  justify-content: center;
}

.partner__title {
  color: #333333;
  font-size: 1.5rem;
  text-transform: uppercase;
  font-weight: bold;
  margin: 0 1.25rem 0 0;
}

.partner__image {
  height: 4.375rem;
  margin: 0 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.city {
  display: flex;
  flex-wrap: nowrap;
  color: #333333;
}

.city__image {
  flex-shrink: 0;
  margin: 0 2.1875rem 0 0;
  max-width: 30%;
}

.city__image img {
  display: block;
  border-radius: 50%;
}

.city__title {
  font-size: 1.5rem;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0 0 1.5625rem;
}

.city__contact {
  margin-bottom: 0.3125rem;
  font-size: 1rem;
}

.city__meta {
  font-size: 12px;
}

.city__link {
  display: inline-block;
  margin-top: 1.25rem;
  font-size: 0.875rem;
  text-transform: uppercase;
  font-weight: 700;
  color: #ec1c23;
  cursor: pointer;
}

.city__link i, .city__link span {
  display: inline-block;
}

.city__link i {
  width: 1.375rem;
  height: 1.375rem;
  margin: 0 0.625rem 0 0;
  vertical-align: bottom;
}

.city__link i svg {
  max-width: 100%;
  max-height: 100%;
  fill: #000;
}

.city__link:hover span {
  text-decoration: underline;
}

.contact-title {
  padding-left: 2.8125rem;
  margin-bottom: 0.75rem;
  font-size: 14px;
  font-weight: 700;
  color: #333333;
  position: relative;
}

.contact-title i {
  position: absolute;
  left: 0;
  top: -0.3125rem;
  width: 1.5rem;
  height: 1.5rem;
}

.contact-title i svg {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  fill: #000;
  max-width: 100%;
  max-height: 100%;
}

.contact-section {
  margin: 0 0 1.25rem 2.8125rem;
}

.contact-phone,
.contact-mail {
  position: relative;
  display: inline-block;
  font-size: 2rem;
  color: #000;
  text-decoration: none;
  font-weight: 300;
  line-height: 1.2;
}

.contact-phone:hover,
.contact-mail:hover {
  color: #ffce4b;
}

.blog-container {
  margin: -2.5rem 0 0;
  padding: 2.5rem 0;
}

.blog-sidebar {
  position: relative;
  margin-top: 2.5rem;
}

@media only screen and (min-width: 768px) {
  .blog-sidebar {
    padding-left: 1rem !important;
  }
}

@media only screen and (min-width: 768px) {
  .blog-sidebar {
    margin-top: 0;
  }
}

@media only screen and (min-width: 768px) {
  .blog-content {
    padding-right: 1rem !important;
  }
}

.blog-post {
  padding-bottom: 2.5rem;
  border-bottom: 1px solid #dddddd;
}

.blog-post:not(:first-child) {
  margin-top: 2.5rem;
}

.blog-post__title {
  font-size: 2rem;
  color: #333333;
  text-decoration: none;
  display: inline-block;
  margin: 0 0 1.25rem;
  font-weight: 700;
  transition: color .25s;
}

.blog-post__title:hover {
  color: #ffce4b;
}

.blog-post__image img {
  display: block;
  max-width: 100%;
  max-height: 100%;
}

.blog-post__meta {
  margin: 0 0 1.25rem;
  display: flex;
}

.blog-post__photo {
  display: block;
  width: 2.875rem;
  height: 2.875rem;
  margin: 0 0.9375rem 0 0;
}

.blog-post__photo img {
  display: block;
  max-width: 100%;
  max-height: 100%;
  border-radius: 50%;
}

.blog-post__author {
  font-size: 16px;
  color: #ffce4b;
  font-weight: 700;
  margin-bottom: 0.3125rem;
}

.blog-post__sundry {
  display: flex;
  margin: 0 -0.9375rem;
  align-items: center;
}

.blog-post__date {
  color: #bbb;
  font-size: 14px;
  padding: 0 0.9375rem;
}

.blog-post__views {
  color: #333;
  font-size: 14px;
  padding: 0 0.9375rem;
  display: none;
}

@media only screen and (min-width: 640px) {
  .blog-post__views {
    display: block;
  }
}

.blog-post__views i, .blog-post__views span {
  display: inline-block;
  vertical-align: middle;
}

.blog-post__views i {
  position: relative;
  width: 1.5rem;
  height: 1.5rem;
  margin: 0 0.625rem 0 0;
}

.blog-post__views i svg {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  max-width: 100%;
  max-height: 100%;
  fill: #666;
}

.blog-post__comments {
  color: #333;
  padding: 0 0.9375rem;
  display: none;
}

@media only screen and (min-width: 640px) {
  .blog-post__comments {
    display: block;
  }
}

.blog-post__comments i, .blog-post__comments span {
  display: inline-block;
  vertical-align: middle;
}

.blog-post__comments i {
  position: relative;
  width: 1.5rem;
  height: 1.5rem;
  margin: 0 0.625rem 0 0;
}

.blog-post__comments i svg {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  max-width: 100%;
  max-height: 100%;
  fill: #666;
}

.blog-post__descr {
  font-size: 1rem;
  color: #333333;
  line-height: 1.5;
  margin: 0 0 1.25rem;
}

.blog-post__link {
  display: inline-block;
  font-size: 14px;
  color: #333333;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  transition: color .25s;
}

.blog-post__link i, .blog-post__link span {
  display: inline-block;
  vertical-align: middle;
}

.blog-post__link i {
  width: 18px;
  height: 18px;
  margin: 0 0 0 0.625rem;
  transition: margin .25s;
}

.blog-post__link i svg {
  max-width: 100%;
  max-height: 100%;
  fill: #ffce4b;
}

.blog-post__link:hover {
  color: #ec1c23;
}

.blog-post__link:hover i {
  margin: 0 0 0 0.9375rem;
}

.blog-nav {
  background: #fff;
}

.blog-nav__item {
  display: block;
  font-size: 14px;
  color: #787a7d;
  text-transform: uppercase;
  text-decoration: none;
  padding: 0.625rem 0.9375rem;
  transition: background .2s, color .2s;
}

.blog-nav__item:hover {
  background: #ffce4b;
  color: #000;
}

.blog-nav__item--active {
  background: #ffce4b;
  color: #000;
}

.last-news {
  position: relative;
  line-height: 1.2;
}

.last-news:not(:last-child) {
  margin-bottom: 1.5625rem;
  padding-bottom: 1.5625rem;
}

.last-news:not(:last-child):after {
  content: '';
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 3.75rem;
  height: 1px;
  background: #000;
}

.last-news__title {
  display: inline-block;
  color: #000;
  text-transform: uppercase;
  font-weight: 400;
  margin-bottom: 0.3125rem;
  text-decoration: none;
  transition: color 0.2s ease;
}

.last-news__title:hover {
  color: #ffdc4b;
}

.last-news__date {
  font-size: 14px;
  color: #999999;
}

.tag {
  display: inline-block;
  height: 32px;
  line-height: 32px;
  background: #dcdcdc;
  padding: 0 20px;
  color: #333333;
  text-transform: uppercase;
  font-size: 12px;
  border-radius: 2px;
  user-select: none;
  margin: 5px 0;
}

.feedback {
  display: flex;
  flex-wrap: nowrap;
}

.feedback:not(:last-child) {
  margin-bottom: 2.5rem;
}

.feedback__avatar {
  flex-shrink: 0;
  margin: 0 1.25rem 0 0;
}

.feedback__avatar img {
  display: block;
  border-radius: 50%;
}

.feedback__body {
  flex-grow: 1;
}

.feedback__header {
  margin: 0 0 0.9375rem;
}

.feedback__name {
  display: inline-block;
  vertical-align: baseline;
  font-size: 1rem;
  color: #333333;
  font-weight: 700;
  margin: 0 1.25rem 0 0;
}

.feedback__date {
  display: inline-block;
  vertical-align: baseline;
  color: #999999;
  font-size: 0.875rem;
}

.feedback__date i {
  display: inline-block;
  vertical-align: top;
  width: 0.875rem;
  height: 0.875rem;
  margin: 0 0.3125rem 0 0;
}

.feedback__date i svg {
  display: block;
  max-width: 100%;
  max-height: 100%;
  fill: #999999;
}

.feedback__date span {
  display: inline-block;
  vertical-align: middle;
}

.feedback__msg {
  font-size: 1rem;
  color: #666666;
  line-height: 1.4;
}

.blog-header {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.blog-header__avatar {
  display: block;
  width: 2.875rem;
  height: 2.875rem;
  margin: 0 1.25rem 0 0;
  flex-shrink: 0;
}

.blog-header__avatar img {
  display: block;
  max-width: 100%;
  max-height: 100%;
  border-radius: 50%;
}

.blog-header__body {
  flex-grow: 1;
}

.blog-header__author {
  color: #ffce4b;
  font-size: 0.875rem;
  margin: 0 0 0.625rem;
  font-weight: 700;
}

.blog-header__info {
  display: flex;
  margin: 0 -0.8125rem;
}

.blog-header__meta {
  color: #999999;
  font-size: 0.875rem;
  margin: 0 0.8125rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.blog-header__meta i {
  display: inline-block;
  vertical-align: top;
  width: 0.875rem;
  height: 0.875rem;
  margin: 0 0.3125rem 0 0;
}

.blog-header__meta i svg {
  display: block;
  max-width: 100%;
  max-height: 100%;
  fill: #999999;
}

.blog-header__meta span {
  display: inline-block;
  vertical-align: middle;
}

.blog-header__meta a {
  display: inline-block;
  vertical-align: middle;
  color: #333;
  text-decoration: none;
}

.blog-header__meta a:hover {
  text-decoration: underline;
}

.blog-header__meta:last-child {
  text-decoration: none;
}

.blog-header__meta:last-child span {
  color: #000;
  cursor: pointer;
}

.blog-header__meta:last-child:hover span {
  text-decoration: underline;
}

.blog-footer {
  padding: 1.875rem 0;
  margin: 0 0 2.5rem;
  border-bottom: 1px solid #dddddd;
}

.feedback-list {
  margin: 0 0 2.5rem;
  padding: 0 0 3.75rem;
  border-bottom: 1px solid #dddddd;
}

.scroll-up {
  position: fixed;
  width: 3.75rem;
  height: 3.75rem;
  background: transparent;
  right: 1.25rem;
  bottom: 1.25rem;
  border-radius: 0.25rem;
  box-sizing: border-box;
  border: 0.25rem solid #ec1c23;
  cursor: pointer;
  z-index: 500;
  visibility: hidden;
  opacity: 0;
  transition: visibility .25s, opacity .25s;
}

.scroll-up--visible {
  visibility: visible;
  opacity: 1;
}

.scroll-up:hover {
  background: #ec1c23;
}

.scroll-up:hover svg {
  fill: #fff;
}

.scroll-up svg {
  fill: #ec1c23;
  max-width: 60%;
  max-height: 60%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.feedback-slider {
  padding: 7.5rem 0;
  background: url("pic/feedback.jpg") 50% no-repeat;
  background-size: cover;
  color: #ffffff;
}

.feedback-slider__title {
  font-weight: 700;
  text-align: center;
  margin: 0 0 2.5rem;
  font-size: 1.75rem;
}

@media only screen and (min-width: 768px) {
  .feedback-slider__title {
    font-size: 2.125rem;
  }
}

@media only screen and (min-width: 768px) {
  .feedback-slider__title {
    font-size: 2.625rem;
  }
}

.feedback-slider__slide {
  text-align: center;
  max-width: 960px;
  margin: 0 auto;
}

@media only screen and (min-width: 640px) {
  .feedback-slider__slide {
    padding: 0 6.25rem;
  }
}

.feedback-slider__msg {
  font-size: 1.5rem;
  font-weight: 300;
  margin: 0 0 1.875rem;
}

.feedback-slider__author {
  font-size: 1rem;
  font-weight: 700;
  margin: 0 0 0.625rem;
}

.feedback-slider__link {
  position: relative;
  font-weight: 300;
  color: #ec1c23;
  text-decoration: none;
  display: inline-block;
  margin: 0 0 10px;
  cursor: none;
}

.feedback-slider__link:after {
  content: '';
  position: absolute;
  bottom: -10px;
  width: 100%;
  left: 0;
  border-bottom: 1px solid #ec1c23;
  opacity: 0;
  transition: bottom .25s, opacity .25s;
}

.feedback-slider__link:hover:after {
  bottom: -2px;
  opacity: 1;
}

.feedback-slider__next, .feedback-slider__prev {
  display: none;
  position: absolute;
  width: 5rem;
  height: 5rem;
  top: 50%;
  transform: translate(0, -60%);
  cursor: pointer;
  z-index: 100;
  opacity: .7;
  transition: opacity .25s, right .25s, left .25s;
}

.feedback-slider__next:hover, .feedback-slider__prev:hover {
  opacity: 1;
}

@media only screen and (min-width: 640px) {
  .feedback-slider__next, .feedback-slider__prev {
    display: block;
  }
}

.feedback-slider__next svg, .feedback-slider__prev svg {
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  fill: transparent;
  stroke: #fff;
  stroke-width: 2px;
}

.feedback-slider__next {
  right: 0;
}

.feedback-slider__next:active {
  right: -0.3125rem;
}

.feedback-slider__prev {
  left: 0;
}

.feedback-slider__prev:active {
  left: -0.3125rem;
}

.feedback-slider__pagination {
  margin: 0 0 1.875rem;
  text-align: center;
}

.feedback-slider__pagination span {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: transparent;
  border: 2px solid #fff;
  opacity: 1;
  transition: background .25s, border .25s;
}

.feedback-slider__pagination span:hover {
  border: 2px solid #ec1c23;
}

.feedback-slider__pagination span.swiper-pagination-bullet-active {
  background: #ec1c23;
  border: 2px solid #ec1c23;
}

.certificate {
  display: block;
  position: relative;
  max-width: 320px;
  width: 100%;
  margin: 0 auto 2.1875rem;
  outline: none;
}

.certificate img {
  display: block;
  width: 100%;
}

.certificate__overlay {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
  bottom: 0;
  width: 100%;
  left: 0;
  height: 0.375rem;
  background: rgba(236, 28, 35, 0.85);
  transition: height .25s .25s;
}

.certificate:hover .certificate__overlay {
  height: 100%;
  transition: height .25s;
}

.certificate__icon {
  display: block;
  width: 4.375rem;
  height: 4.375rem;
  margin: 0 0 1.25rem;
  opacity: 0;
  transform: translate(0, -1.875rem);
  transition: transform .25s, opacity .25s;
}

.certificate__icon svg {
  fill: #fff;
  max-width: 100%;
  max-height: 100%;
}

.certificate:hover .certificate__icon {
  opacity: 1;
  transform: translate(0, 0);
  transition: transform .25s .25s, opacity .25s .25s;
}

.certificate__caption {
  font-size: 1.5rem;
  color: #fff;
  font-weight: 300;
  text-align: center;
  padding: 0 1.875rem;
  line-height: 1.4;
  opacity: 0;
  transform: translate(0, -1.875rem);
  transition: transform .25s, opacity .25s;
}

.certificate:hover .certificate__caption {
  opacity: 1;
  transform: translate(0, 0);
  transition: transform .25s .25s, opacity .25s .25s;
}

.portfolio-infographic img {
  display: block;
  margin: 0 auto;
}

@media only screen and (min-width: 768px) {
  .portfolio-infographic {
    display: block;
  }
}

.portfolio-overview {
  position: relative;
  color: #fff;
  padding: 6.25rem 0;
  background-size: cover;
  background-position: 50%;
  margin: -2.5rem 0 0;
}

@media only screen and (max-width: 1023px) {
  .portfolio-overview {
    background-image: none !important;
  }
}

@media only screen and (min-width: 1024px) {
  .portfolio-overview {
    padding: 12% 0;
  }
}

.portfolio-overview__logo {
  margin: 0 0 2.5rem;
}

.portfolio-overview__logo img {
  display: block;
}

.portfolio-overview__title {
  font-size: 2.625rem;
  font-weight: 700;
  line-height: 1.2;
  margin: 0 0 1.25rem;
}

.portfolio-overview__descr {
  font-size: 1.5rem;
  font-weight: 300;
  margin: 0 0 1.5625rem;
}

@media only screen and (min-width: 1024px) {
  .portfolio-overview__descr {
    color: rgba(255, 255, 255, 0.6);
  }
}

.portfolio-overview__service {
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  color: #fff;
  text-transform: uppercase;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.portfolio-overview__service:not(:last-child) {
  margin: 0 0.9375rem 0 0;
}

.portfolio-overview__service span {
  display: inline-block;
  vertical-align: middle;
}

.portfolio-overview__service i {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  margin: 0 5px 0 0;
}

.portfolio-overview__service i svg {
  fill: #fff;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  max-width: 80%;
  max-height: 80%;
}

.portfolio-footer {
  display: flex;
  flex-wrap: wrap;
  margin: 5rem 0 0;
  box-shadow: 0 0 20px rgba(29, 29, 27, 0.5);
}

.portfolio-footer__similar {
  width: 50%;
  align-self: stretch;
}

@media only screen and (min-width: 1400px) {
  .portfolio-footer__similar {
    width: 33.33%;
  }
}

.portfolio-footer__middle {
  width: 100%;
  order: 0;
}

@media only screen and (min-width: 1400px) {
  .portfolio-footer__middle {
    width: 33.33%;
    order: initial;
  }
}

.portfolio-similar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: 50%;
  background-color: #ccc;
  color: #fff;
  text-decoration: none;
  padding: 6.25rem 0;
  transition: opacity .25s;
  position: relative;
}

.portfolio-similar:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0);
  transition: all 0.3s linear;
}

.portfolio-similar:hover:after {
  background: rgba(0, 0, 0, 0.8);
}

@media only screen and (min-width: 1400px) {
  .portfolio-similar {
    padding: 0;
  }
}

.portfolio-similar:hover .portfolio-similar__project {
  opacity: 1;
}

.portfolio-similar:hover .portfolio-similar__title {
  opacity: 1;
}

.portfolio-similar__title {
  font-size: 1.5rem;
  font-weight: 300;
  margin: 0 0 0.625rem;
  opacity: 0;
  transition: all 0.3s linear;
}

.portfolio-similar__project {
  font-size: 2rem;
  font-weight: 700;
  opacity: 0;
  transition: all 0.2s linear;
}

.portfolio-social {
  padding: 2.8125rem;
  background: #fff;
  text-align: center;
  font-size: 0;
}

.social-button {
  color: #fff;
  display: inline-flex;
  height: 3.25rem;
  line-height: 3.25rem;
  min-width: 7.5rem;
  text-align: left;
  font-size: 1rem;
  text-decoration: none;
  border-radius: 4px;
  margin: 0.3125rem;
}

.social-button i {
  display: block;
  position: relative;
  width: 3.25rem;
  height: 3.25rem;
}

.social-button i svg {
  position: absolute;
  max-width: 50%;
  max-height: 50%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  fill: #fff;
}

.social-button--fb {
  background: #3b5998;
}

.social-button--fb:hover {
  background: #2d4373;
}

.social-button--pt {
  background: #cb2027;
}

.social-button--pt:hover {
  background: #9f191f;
}

.social-button--tw {
  background: #64a8f2;
}

.social-button--tw:hover {
  background: #358eee;
}

.social-button--gp {
  background: #dd5044;
}

.social-button--gp:hover {
  background: #c93225;
}

.portfolio-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ec1c23;
  padding: 2.8125rem;
  color: #fff;
  font-size: 1.5rem;
  text-decoration: none;
  font-weight: 300;
  transition: background .25s;
}

.portfolio-link:hover {
  background: #c51017;
}

.portfolio-link i {
  display: block;
  width: 1.875rem;
  height: 1.875rem;
  margin: 0 0 0.9375rem;
}

.portfolio-link i svg {
  fill: #fff;
  max-width: 100%;
  max-height: 100%;
}

.compare {
  color: #fff;
  text-align: center;
  overflow: hidden;
}

@media only screen and (min-width: 640px) {
  .compare {
    display: block;
  }
}

.compare__title {
  font-size: 2rem;
  font-weight: 700;
  text-transform: uppercase;
  max-width: 720px;
  margin: 0 auto 1.25rem;
}

.compare__title:after {
  content: '';
  display: block;
  margin: 0.9375rem auto 0;
  width: 3.75rem;
  height: 2px;
  background: #fff;
}

.compare__caption {
  margin: 0 auto;
  font-size: 1.5rem;
  font-weight: 300;
  max-width: 720px;
  line-height: 1.4;
}

.compare__container {
  position: relative;
  max-width: 1350px;
  margin: 0 auto;
  user-select: none;
}

.compare__container img {
  display: block;
  margin: 0 auto;
}

.compare__container > .compare__content {
  position: absolute;
  background: rgba(236, 28, 35, 0.8);
  width: 88.9%;
  height: 87.5%;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
}

.twentytwenty-wrapper {
  position: absolute;
  width: 88.9%;
  height: 87.5%;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  min-height: 1px;
  min-width: 1px;
}

.twentytwenty-container {
  box-sizing: content-box;
  overflow: hidden;
  position: relative;
  user-select: none;
}

.twentytwenty-before-label,
.twentytwenty-after-label,
.twentytwenty-overlay {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}

.twentytwenty-before-label,
.twentytwenty-after-label,
.twentytwenty-overlay {
  transition-duration: 0.5s;
}

.twentytwenty-before-label,
.twentytwenty-after-label {
  transition-property: opacity;
}

.twentytwenty-before-label:before,
.twentytwenty-after-label:before {
  color: white;
  font-size: 50px;
  font-weight: 700;
}

.twentytwenty-before-label:before,
.twentytwenty-after-label:before {
  position: absolute;
  top: 50%;
  margin-top: -0.6em;
  line-height: 1.2;
  padding: 0 20px;
}

.twentytwenty-container img {
  position: absolute;
  top: 0;
  display: block;
}

.twentytwenty-container.active .twentytwenty-overlay,
.twentytwenty-container.active :hover.twentytwenty-overlay {
  background: rgba(0, 0, 0, 0);
}

.twentytwenty-container.active .twentytwenty-overlay .twentytwenty-before-label,
.twentytwenty-container.active .twentytwenty-overlay .twentytwenty-after-label {
  opacity: 0;
}

.twentytwenty-container.active:hover.twentytwenty-overlay .twentytwenty-before-label,
.twentytwenty-container.active:hover.twentytwenty-overlay .twentytwenty-after-label {
  opacity: 0;
}

.twentytwenty-container * {
  box-sizing: content-box;
}

.twentytwenty-before-label {
  opacity: 1;
}

.twentytwenty-after-label {
  opacity: 1;
}

.twentytwenty-overlay {
  transition-property: background;
  background: rgba(0, 0, 0, 0);
  z-index: 25;
}

.twentytwenty-overlay:hover .twentytwenty-after-label {
  opacity: 1;
}

.twentytwenty-overlay:hover .twentytwenty-before-label {
  opacity: 1;
}

.twentytwenty-before {
  z-index: 20;
}

.twentytwenty-after {
  z-index: 10;
}

.twentytwenty-handle {
  height: 64px;
  width: 64px;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 40;
  background: #ec1c23;
  cursor: move;
  transform: translate(-50%, -50%);
  transition: background .25s;
}

.twentytwenty-handle:hover {
  background: #c51017;
}

.twentytwenty-handle:before,
.twentytwenty-handle:after {
  content: "";
  display: block;
  position: absolute;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  top: 50%;
  transform: translate(0, -50%);
}

.twentytwenty-handle:before {
  border-right: 7px solid #fff;
  right: 54%;
}

.twentytwenty-handle:after {
  border-left: 7px solid #fff;
  left: 54%;
}

.worker-section {
  position: relative;
}

@media only screen and (min-width: 480px) {
  .worker-section {
    padding: 0 3.75rem;
  }
}

@media only screen and (min-width: 1024px) {
  .worker-section {
    padding: 0 7.5rem;
  }
}

.worker-section__prev, .worker-section__next {
  position: absolute;
  cursor: pointer;
  z-index: 100;
  top: 50%;
  width: 2.5rem;
  height: 5rem;
  transform: translate(0, -50%);
  opacity: .5;
  transition: left .2s, right .2s, opacity .2s;
}

.worker-section__prev svg, .worker-section__next svg {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  max-width: 100%;
  max-height: 100%;
  fill: none;
  stroke: #333333;
  stroke-width: 1px;
  transition: stroke .2s;
}

.worker-section__prev:hover, .worker-section__next:hover {
  opacity: 1;
}

.worker-section__prev {
  left: 0.625rem;
}

.worker-section__prev:active {
  left: 0;
}

.worker-section__next {
  right: 0.625rem;
}

.worker-section__next:active {
  right: 0;
}

.worker-slider {
  margin: 3.75rem 0 0;
}

.worker-slider .swiper-slide {
  width: 100%;
  align-self: flex-end;
}

@media only screen and (min-width: 768px) {
  .worker-slider .swiper-slide {
    width: 50%;
  }
}

@media only screen and (min-width: 1280px) {
  .worker-slider .swiper-slide {
    width: 33.3333%;
  }
}

.worker {
  max-width: 300px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'ProximaNova', sans-serif;
}

.worker__photo {
  margin: 0 0 1.25rem;
}

.worker__photo img {
  display: block;
  margin: auto;
}

.worker__name {
  font-weight: 700;
  font-size: 1.5rem;
  color: #000;
  text-align: center;
}

.worker__position {
  color: #787a7d;
  font-size: 1rem;
  margin: 0 0 0.625rem;
}

.about-slider {
  display: none;
}

@media only screen and (min-width: 480px) {
  .about-slider {
    display: block;
  }
}

.about-slider__pagination {
  display: block;
  position: absolute;
  bottom: 1.875rem !important;
  padding: 0 1.25rem;
  margin: 0 -0.9375rem;
  z-index: 100;
}

@media only screen and (min-width: 768px) {
  .about-slider__pagination {
    padding: 0 7.5rem;
    bottom: 3.75rem !important;
  }
}

.about-slider__pagination .swiper-pagination-bullet {
  width: 5rem;
  height: 0.375rem;
  background: #fff;
  opacity: .3;
  border-radius: 0;
  margin: 0 0.9375rem;
  transition: opacity .25s;
}

.about-slider__pagination .swiper-pagination-bullet:hover {
  opacity: .6;
}

.about-slider__pagination .swiper-pagination-bullet-active {
  opacity: 1 !important;
}

.about-slider__prev, .about-slider__next {
  display: none;
  position: absolute;
  cursor: pointer;
  z-index: 100;
  top: 50%;
  width: 2.5rem;
  height: 5rem;
  transform: translate(0, -50%);
  opacity: .5;
  transition: left .2s, right .2s, opacity .2s;
}

@media only screen and (min-width: 768px) {
  .about-slider__prev, .about-slider__next {
    display: block;
  }
}

.about-slider__prev svg, .about-slider__next svg {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  max-width: 100%;
  max-height: 100%;
  fill: none;
  stroke: #fff;
  stroke-width: 2px;
  transition: stroke .2s;
}

.about-slider__prev:hover, .about-slider__next:hover {
  opacity: 1;
}

.about-slider__prev {
  left: 1.875rem;
}

.about-slider__prev:active {
  left: 1.25rem;
}

.about-slider__next {
  right: 1.875rem;
}

.about-slider__next:active {
  right: 1.25rem;
}

.about-slide__image {
  display: block;
  width: 100%;
}

.about-slide__content {
  position: absolute;
  left: 20px;
  top: 50px;
  padding: 0 1.25rem 5rem;
  color: #fff;
  font-family: 'ProximaNova', sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

@media only screen and (min-width: 768px) {
  .about-slide__content {
    padding: 0 7.5rem 7.5rem;
  }
}

.about-slide__title {
  font-weight: 700;
  font-size: 2.625rem;
  margin: 0 0 0.9375rem;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.7);
}

.about-slide__caption {
  font-weight: 300;
  font-size: 1.5rem;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.7);
}

.vacancy-card {
  background: #fff;
  padding: 2.1875rem 1.875rem;
  margin: 0 0 1.25rem;
  min-height: 23.125rem;
  display: flex;
  flex-direction: column;
  color: #333333;
  font-family: 'ProximaNova', sans-serif;
  position: relative;
}

.vacancy-card__title {
  margin: 0 0 1.5625rem;
  font-size: 1.5rem;
  font-weight: 700;
}

.vacancy-card__descr {
  font-size: 1rem;
  flex-grow: 1;
  line-height: 1.4;
}

.vacancy-card__link {
  display: inline-block;
  font-size: 14px;
  color: #333333;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  outline: none;
  transition: color .25s;
}

.vacancy-card__link i, .vacancy-card__link span {
  display: inline-block;
  vertical-align: middle;
}

.vacancy-card__link i {
  width: 18px;
  height: 18px;
  margin: 0 0 0 0.625rem;
  transition: margin .25s;
}

.vacancy-card__link i svg {
  max-width: 100%;
  max-height: 100%;
  fill: #ec1c23;
}

.vacancy-card__link:hover {
  color: #ec1c23;
}

.vacancy-card__link:hover i {
  margin: 0 0 0 0.9375rem;
}

.vacancy-card .iconVacancy {
  width: 18px;
  height: 18px;
  position: absolute;
  right: 15px;
  top: 15px;
}

.vacancy-card .iconVacancy svg {
  fill: #e01216;
  max-width: 100%;
  max-height: 100%;
  vertical-align: top;
}

.vacancy-send {
  min-height: 23.125rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2.1875rem 1.875rem;
}

.vacancy-send__descr {
  color: #333333;
  font-size: 1rem;
  text-align: center;
  line-height: 1.4;
}

.vacancy-send__descr a {
  color: #ec1c23;
}

.vacancy-send__plus {
  position: relative;
  width: 3.375rem;
  height: 3.375rem;
  margin: 0 auto;
  transform: translate(-50%, 0);
}

.vacancy-send__plus:before, .vacancy-send__plus:after {
  content: '';
  display: block;
  position: absolute;
  left: 50%;
  width: 3.375rem;
  height: 0.875rem;
  background: #cecfd1;
}

.vacancy-send__plus:after {
  transform-origin: 50%;
  transform: rotate(-90deg);
}

.portfolio-list {
  margin: -2.5rem 0 5rem;
}

.portfolio-preview {
  display: block;
  position: relative;
  color: #fff;
  padding: 8.125rem 1.25rem;
  background-size: cover;
  background-position: 50%;
  min-height: 50rem;
  text-align: center;
  text-decoration: none;
}

.portfolio-preview:before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  opacity: 1;
  transition: opacity .35s;
}

@media only screen and (min-width: 1024px) {
  .portfolio-preview:before {
    opacity: 0;
  }
}

@media only screen and (min-width: 1024px) {
  .portfolio-preview:hover:before {
    opacity: 0;
  }
}

@media only screen and (min-width: 1024px) {
  .portfolio-preview {
    text-align: left;
  }
}

.portfolio-preview__logo {
  margin: 0 0 2.5rem;
}

.portfolio-preview__logo img {
  margin: 0 auto;
}

@media only screen and (min-width: 1024px) {
  .portfolio-preview__logo img {
    margin: 0;
  }
}

.portfolio-preview__title {
  font-size: 2.625rem;
  font-weight: 700;
  line-height: 1.2;
  margin: 0 0 1.25rem;
  font-family: Coco Gothic, Arial, sans-serif;
}

.portfolio-preview__descr {
  font-size: 1.5rem;
  font-weight: 300;
  margin: 0 0 1.5625rem;
  font-family: ProximaNova, Arial, sans-serif;
}

@media only screen and (min-width: 1024px) {
  .portfolio-preview__descr {
    color: rgba(255, 255, 255, 0.6);
  }
}

.portfolio-preview__service {
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  color: #fff;
  text-transform: uppercase;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.portfolio-preview__service:not(:last-child) {
  margin: 0 0.9375rem 0 0;
}

.portfolio-preview__service span {
  display: inline-block;
  vertical-align: middle;
}

.portfolio-preview__service i {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  margin: 0 5px 0 0;
}

.portfolio-preview__service i svg {
  fill: #fff;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  max-width: 80%;
  max-height: 80%;
}

.portfolio-nav {
  text-align: center;
  margin: 2.8125rem 0;
  font-size: 0;
}

.portfolio-nav__link {
  display: inline-block;
  padding: 0.625rem 0.9375rem;
  font-size: 0.875rem;
  color: #787a7d;
  text-transform: uppercase;
  text-decoration: none;
  transition: background .25s, color .25s;
}

.portfolio-nav__link:hover {
  background: #fff;
}

.portfolio-nav__link--active {
  color: #fff;
  background: #ec1c23 !important;
}

.portfolio-card {
  position: relative;
  display: block;
  padding: 100% 0 0;
  text-decoration: none;
  background-size: cover;
  background-position: 50%;
}

.portfolio-card__logo {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: .6;
  transition: opacity .5s;
}

.portfolio-card__logo img {
  display: block;
  max-width: 60%;
  max-height: 60%;
}

.portfolio-card:hover .portfolio-card__logo {
  opacity: 0;
}

.portfolio-card__overlay {
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  color: #fff;
  opacity: 0;
  transition: opacity .5s;
}

@media only screen and (min-width: 768px) {
  .portfolio-card__overlay {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.portfolio-card:hover .portfolio-card__overlay {
  opacity: .85;
}

.portfolio-card__title {
  font-size: 1.5rem;
  font-weight: 700;
  margin: 0 0 1.25rem;
  text-align: center;
  max-width: 90%;
}

.portfolio-card__meta {
  max-width: 90%;
  text-align: center;
  margin: 0 0 1.875rem;
  font-size: 0;
}

.portfolio-card__service {
  display: inline-block;
  font-size: 12px;
  color: #fff;
  text-transform: uppercase;
  margin: 0 0.625rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.portfolio-card__service span {
  display: inline-block;
  vertical-align: middle;
}

.portfolio-card__service i {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 20px;
}

.portfolio-card__service i svg {
  fill: #fff;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  max-width: 80%;
  max-height: 80%;
}

.portfolio-card__target {
  display: inline-block;
  font-size: 1rem;
  color: #fff;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
}

.portfolio-card__target i, .portfolio-card__target span {
  display: inline-block;
  vertical-align: middle;
}

.portfolio-card__target i {
  width: 18px;
  height: 18px;
  margin: 0 0 0 0.625rem;
}

.portfolio-card__target i svg {
  max-width: 100%;
  max-height: 100%;
  fill: #fff;
}

.hidden-menu__wrapper .grid > .gcell {
  text-align: center;
}

@media only screen and (max-width: 1023px) {
  .portfolio-overview:after {
    background: rgba(0, 0, 0, 0.6) none repeat scroll 0 0;
    bottom: 0;
    content: "";
    display: block;
    left: 0;
    opacity: 1;
    position: absolute;
    right: 0;
    top: 0;
    transition: opacity 0.35s ease 0s;
    z-index: 1;
  }
  .portfolio-overview > * {
    z-index: 2;
    position: relative;
  }
}

@media only screen and (max-width: 479px) {
  .footer-social__button {
    width: 3.5rem;
    height: 3.5rem;
    margin: 0.3125rem 0.1rem;
  }
}

.wezom-logo__caption span.mobi {
  display: none;
}

@media only screen and (max-width: 768px) {
  .wezom-logo__caption {
    display: flex;
  }
}

.wezom-logo__caption span.mobi {
  display: none;
}

.resp_on {
  display: none !important;
}

@media only screen and (max-width: 768px) {
  .hidden-menu__body {
    top: 80px;
  }
  .hamburger {
    padding: 0;
    margin-right: 20px;
  }
  .resp_off {
    display: none !important;
  }
  .resp_on {
    display: inline-block !important;
  }
  .resp_on img {
    vertical-align: top;
  }
  .wezom-logo__icon--back {
    transform: rotateX(-90deg) translateY(40px);
  }
  .wezom-logo__cube {
    margin-left: 0;
    height: 80px;
    width: 80px;
  }
  .wezom-logo__icon {
    width: 100%;
    height: 100%;
  }
  .page-header__size {
    padding: 0;
  }
  .wezom-logo__title {
    display: none;
  }
  .wezom-logo__caption {
    display: flex;
    line-height: 1.1;
  }
  .wezom-logo__caption span.mobi {
    display: inline;
  }
  .page-header {
    padding: 0;
  }
}

@media screen and (max-width: 1400px) {
  .portfolio-similar {
    order: 1 !important;
  }
  .portfolio-footer__middle {
    order: 2 !important;
  }
  .portfolio-similar__title {
    text-align: center;
  }
  .portfolio-similar__project {
    text-align: center;
  }
}

@media screen and (max-width: 1024px) {
  .hidden-menu__wrapper {
    display: block;
  }
  .main-slider__slide {
    background-color: rgba(0, 0, 0, 0.45);
  }
}

@media screen and (max-width: 768px) {
  .hidden-menu__body {
    top: 80px;
  }
  .blog-card__image {
    margin: 0 1.25rem 0 0;
    width: 100px;
    padding-top: 0;
    position: relative;
    height: auto;
    min-width: 100px;
    max-width: 100px;
    overflow: hidden;
  }
  .blog-card__image img {
    position: absolute;
    min-width: 0%;
    min-height: 0%;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: auto;
    max-width: 100%;
    max-height: 100%;
  }
}

@media screen and (max-width: 720px) {
  .page-head__prev span, .page-head__next span {
    display: none;
  }
  .page-head__next {
    right: 0;
  }
  .page-head__prev {
    left: 0;
  }
}

@media only screen and (max-width: 360px) {
  .wezom-logo__cube {
    height: 55px;
    width: 55px;
    margin-right: 3px;
  }
  .page-header .grid--space-def > .gcell {
    padding: 0 5px;
  }
  .hidden-menu__body {
    top: 55px;
  }
  .wezom-logo__icon--back {
    transform: rotateX(-90deg) translateY(27px);
  }
}

@media only screen and (max-height: 460px) {
  .hidden-menu__body {
    top: 55px;
  }
  .wezom-logo__cube {
    height: 55px;
    width: 55px;
    margin-right: 3px;
  }
  .page-header .grid--space-def > .gcell {
    padding: 0 5px;
  }
}

.pageErrorWrap {
  color: #fff;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: url(../css/pic/allcrafted_grain2.png) 0 0 repeat, url(../css/pic/bg_404.jpg) 50% 50%/cover no-repeat;
}

.pageErrorConteiner {
  padding: 5% 20px;
  max-width: 800px;
  position: relative;
  text-align: center;
  top: 40%;
  margin: auto;
  transform: translateY(-50%);
}

.pageErrorLogo {
  display: inline-block;
  margin-top: 100px;
  margin-bottom: 100px;
}

.pageErrorTitle {
  position: relative;
  letter-spacing: -1px;
  line-height: 60px;
  font-size: 60px;
  font-weight: 100;
  margin: 0 0 50px;
  color: rgba(255, 255, 255, 0.2);
}

.pageErrorSubtitle {
  font: 24px/1.2em Arial, Helvetica, sans-serif;
  color: #fff;
  margin-bottom: 40px;
}

.pageErrorContent {
  text-transform: uppercase;
}

.pageErrorConteiner .wysiwyg {
  color: #fff;
}

.roistat-promo-wrap {
  display: none !important;
}

.blog-subscriber {
  display: block !important;
  position: fixed;
  bottom: 0;
  opacity: 0;
  left: 50%;
  transform: translateX(-50%) translateY(110%);
  max-width: 1200px;
  width: 96%;
  padding: 1.5rem 1.5rem 2rem;
  margin: 0 auto;
  background-color: #111;
  z-index: 501;
  transition: all 0.5s;
}

.blog-subscriber .form-element {
  margin-bottom: 2rem;
}

.blog-subscriber.is-visible {
  opacity: 1;
  transform: translateX(-50%) translateY(0);
}

.blog-subscriber__title {
  color: #fff;
  text-align: center;
  font-size: 1.2rem;
  border-bottom: 2px solid #ffdb4b;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  font-weight: 700;
  padding-right: 15px;
}

@media only screen and (min-width: 768px) {
  .blog-subscriber__title {
    font-size: 1.5rem;
    text-align: left;
  }
}

.blog-subscriber__close {
  width: 2.5rem;
  height: 2.5rem;
  line-height: 50px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: .65;
  padding: 0;
  color: #FFF;
  font-style: normal;
  font-size: 2.5rem;
  font-family: Arial,Baskerville,monospace;
  cursor: pointer;
  background: transparent;
  border: 0;
  outline: none;
  box-shadow: none;
  touch-action: manipulation;
}

.blog-subscriber__close:hover, .blog-subscriber__close:active {
  opacity: 1;
}

.blog-subscriber__close:active {
  transform: scale(0.8);
}

.mm-hidden {
  display: none !important;
}

.mm-wrapper {
  overflow-x: hidden;
  position: relative;
}

.mm-menu {
  box-sizing: border-box;
  background: inherit;
  display: block;
  padding: 0;
  margin: 0;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 0;
}

.mm-panels, .mm-panels > .mm-panel {
  background: inherit;
  border-color: inherit;
  box-sizing: border-box;
  margin: 0;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 0;
}

.mm-panels {
  overflow: hidden;
}

.mm-panel {
  -webkit-transform: translate(100%, 0);
  -ms-transform: translate(100%, 0);
  transform: translate(100%, 0);
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
  -webkit-transition: -webkit-transform .4s ease;
  transition: -webkit-transform .4s ease;
  transition: transform .4s ease;
  transition: transform .4s ease, -webkit-transform .4s ease;
  -webkit-transform-origin: top left;
  -ms-transform-origin: top left;
  transform-origin: top left;
}

.mm-panel.mm-opened {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.mm-panel.mm-subopened {
  -webkit-transform: translate(-30%, 0);
  -ms-transform: translate(-30%, 0);
  transform: translate(-30%, 0);
  -webkit-transform: translate3d(-30%, 0, 0);
  transform: translate3d(-30%, 0, 0);
}

.mm-panel.mm-highest {
  z-index: 1;
}

.mm-panel.mm-noanimation {
  -webkit-transition: none !important;
  transition: none !important;
}

.mm-panel.mm-noanimation.mm-subopened {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.mm-panels > .mm-panel {
  -webkit-overflow-scrolling: touch;
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0 20px;
}

.mm-panels > .mm-panel.mm-hasnavbar {
  padding-top: 40px;
}

.mm-panels > .mm-panel:not(.mm-hidden) {
  display: block;
}

.mm-panels > .mm-panel:after, .mm-panels > .mm-panel:before {
  content: '';
  display: block;
  height: 20px;
}

.mm-vertical .mm-panel {
  -webkit-transform: none !important;
  -ms-transform: none !important;
  transform: none !important;
}

.mm-listview .mm-vertical .mm-panel, .mm-vertical .mm-listview .mm-panel {
  display: none;
  padding: 10px 0 10px 10px;
}

.mm-listview .mm-vertical .mm-panel .mm-listview > li:last-child:after, .mm-vertical .mm-listview .mm-panel .mm-listview > li:last-child:after {
  border-color: transparent;
}

.mm-vertical li.mm-opened > .mm-panel, li.mm-vertical.mm-opened > .mm-panel {
  display: block;
}

.mm-listview > li.mm-vertical > .mm-next, .mm-vertical .mm-listview > li > .mm-next {
  box-sizing: border-box;
  height: 40px;
  bottom: auto;
}

.mm-listview > li.mm-vertical.mm-opened > .mm-next:after, .mm-vertical .mm-listview > li.mm-opened > .mm-next:after {
  -webkit-transform: rotate(225deg);
  -ms-transform: rotate(225deg);
  transform: rotate(225deg);
  right: 19px;
}

.mm-btn {
  box-sizing: border-box;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 0;
  z-index: 1;
}

.mm-clear:after, .mm-clear:before, .mm-close:after, .mm-close:before {
  content: '';
  border: 2px solid transparent;
  display: block;
  width: 5px;
  height: 5px;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.mm-clear:before, .mm-close:before {
  border-right: none;
  border-bottom: none;
  right: 18px;
}

.mm-clear:after, .mm-close:after {
  border-left: none;
  border-top: none;
  right: 25px;
}

.mm-next:after, .mm-prev:before {
  content: '';
  border-top: 2px solid transparent;
  border-left: 2px solid transparent;
  display: block;
  width: 8px;
  height: 8px;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
}

.mm-prev:before {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  left: 23px;
  right: auto;
}

.mm-next:after {
  -webkit-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  transform: rotate(135deg);
  right: 23px;
  left: auto;
}

.mm-navbar {
  border-bottom: 1px solid;
  border-color: inherit;
  text-align: center;
  line-height: 20px;
  height: 40px;
  padding: 0 40px;
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.mm-navbar > * {
  display: block;
  padding: 10px 0;
}

.mm-navbar a, .mm-navbar a:hover {
  text-decoration: none;
}

.mm-navbar .mm-title {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.mm-navbar .mm-btn:first-child {
  left: 0;
}

.mm-navbar .mm-btn:last-child {
  text-align: right;
  right: 0;
}

.mm-panel .mm-navbar {
  display: none;
}

.mm-panel.mm-hasnavbar .mm-navbar {
  display: block;
}

.mm-listview, .mm-listview > li {
  list-style: none;
  display: block;
  padding: 0;
  margin: 0;
}

.mm-listview {
  font: inherit;
  font-size: 14px;
  line-height: 20px;
}

.mm-listview a, .mm-listview a:hover {
  text-decoration: none;
}

.mm-listview > li {
  position: relative;
}

.mm-listview > li, .mm-listview > li .mm-next, .mm-listview > li .mm-next:before, .mm-listview > li:after {
  border-color: inherit;
}

.mm-listview > li > a, .mm-listview > li > span {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: inherit;
  display: block;
  padding: 10px 10px 10px 20px;
  margin: 0;
}

.mm-listview > li:not(.mm-divider):after {
  content: '';
  border-bottom-width: 1px;
  border-bottom-style: solid;
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}

.mm-listview > li:not(.mm-divider):after {
  left: 20px;
}

.mm-listview .mm-next {
  background: rgba(3, 2, 1, 0);
  width: 50px;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2;
}

.mm-listview .mm-next:before {
  content: '';
  border-left-width: 1px;
  border-left-style: solid;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

.mm-listview .mm-next + a, .mm-listview .mm-next + span {
  margin-right: 50px;
}

.mm-listview .mm-next.mm-fullsubopen {
  width: 100%;
}

.mm-listview .mm-next.mm-fullsubopen:before {
  border-left: none;
}

.mm-listview .mm-next.mm-fullsubopen + a, .mm-listview .mm-next.mm-fullsubopen + span {
  padding-right: 50px;
  margin-right: 0;
}

.mm-panels > .mm-panel > .mm-listview {
  margin: 20px -20px;
}

.mm-panels > .mm-panel > .mm-listview:first-child, .mm-panels > .mm-panel > .mm-navbar + .mm-listview {
  margin-top: -20px;
}

.mm-menu {
  background: #272937;
  color: rgba(255, 255, 255, 0.75);
}

.mm-menu .mm-navbar a, .mm-menu .mm-navbar > * {
  color: rgba(255, 255, 255, 0.5);
  text-transform: uppercase;
}

.mm-menu .mm-btn:after, .mm-menu .mm-btn:before {
  border-color: rgba(255, 255, 255, 0.3);
}

.mm-menu .mm-listview {
  border-color: rgba(255, 255, 255, 0.1);
}

.mm-menu .mm-listview > li .mm-next:after {
  border-color: rgba(255, 255, 255, 0.3);
}

.mm-menu .mm-listview > li a:not(.mm-next) {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0.5);
  tap-highlight-color: rgba(255, 255, 255, 0.5);
}

.mm-menu .mm-listview > li.mm-selected > a:not(.mm-next), .mm-menu .mm-listview > li.mm-selected > span {
  background: rgba(255, 255, 255, 0.5);
}

.mm-menu .mm-listview > li.mm-opened.mm-vertical > .mm-panel, .mm-menu .mm-listview > li.mm-opened.mm-vertical > a.mm-next, .mm-menu.mm-vertical .mm-listview > li.mm-opened > .mm-panel, .mm-menu.mm-vertical .mm-listview > li.mm-opened > a.mm-next {
  background: rgba(255, 255, 255, 0.05);
}

.mm-menu .mm-divider {
  background: rgba(255, 255, 255, 0.05);
}

.mm-page {
  box-sizing: border-box;
  position: relative;
  height: 100%;
}

.mm-slideout {
  -webkit-transition: -webkit-transform .4s ease;
  transition: -webkit-transform .4s ease;
  transition: transform .4s ease;
  transition: transform .4s ease, -webkit-transform .4s ease;
  z-index: 1;
}

html.mm-opened {
  overflow-x: hidden;
  position: relative;
  height: inherit;
}

html.mm-blocking {
  overflow: hidden;
}

html.mm-blocking body {
  overflow: hidden;
}

html.mm-background .mm-page {
  background: inherit;
}

#mm-blocker {
  background: rgba(3, 2, 1, 0);
  display: none;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
}

html.mm-blocking #mm-blocker {
  display: block;
}

.mm-menu.mm-offcanvas {
  z-index: 0;
  display: none;
  position: fixed;
}

.mm-menu.mm-offcanvas.mm-opened {
  display: block;
}

.mm-menu.mm-offcanvas.mm-no-csstransforms.mm-opened {
  z-index: 10;
}

.mm-menu.mm-offcanvas {
  width: 80%;
  min-width: 140px;
  max-width: 440px;
}

html.mm-opening .mm-menu.mm-opened ~ .mm-slideout {
  -webkit-transform: translate(80%, 0);
  -ms-transform: translate(80%, 0);
  transform: translate(80%, 0);
  -webkit-transform: translate3d(80%, 0, 0);
  transform: translate3d(80%, 0, 0);
}

@media all and (max-width: 175px) {
  html.mm-opening .mm-menu.mm-opened ~ .mm-slideout {
    -webkit-transform: translate(140px, 0);
    -ms-transform: translate(140px, 0);
    transform: translate(140px, 0);
    -webkit-transform: translate3d(140px, 0, 0);
    transform: translate3d(140px, 0, 0);
  }
}

@media all and (min-width: 550px) {
  html.mm-opening .mm-menu.mm-opened ~ .mm-slideout {
    -webkit-transform: translate(440px, 0);
    -ms-transform: translate(440px, 0);
    transform: translate(440px, 0);
    -webkit-transform: translate3d(440px, 0, 0);
    transform: translate3d(440px, 0, 0);
  }
}

.mm-sronly {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  white-space: nowrap !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  overflow: hidden !important;
  position: absolute !important;
}

.mm-navbars-bottom, .mm-navbars-top {
  background: inherit;
  border-color: inherit;
  border-width: 0;
  overflow: hidden;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 3;
}

.mm-navbars-bottom > .mm-navbar, .mm-navbars-top > .mm-navbar {
  border: none;
  padding: 0;
  position: relative;
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.mm-navbars-top {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  top: 0;
}

.mm-navbars-bottom {
  border-top-style: solid;
  border-top-width: 1px;
  bottom: 0;
}

.mm-navbar.mm-hasbtns {
  padding: 0 40px;
}

.mm-navbar[class*=mm-navbar-content-] > * {
  box-sizing: border-box;
  display: block;
  float: left;
}

.mm-navbar .mm-breadcrumbs {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
  text-align: left;
  padding: 0 0 0 17px;
}

.mm-navbar .mm-breadcrumbs > * {
  display: inline-block;
  padding: 10px 3px;
}

.mm-navbar .mm-breadcrumbs > a {
  text-decoration: underline;
}

.mm-navbar.mm-hasbtns .mm-breadcrumbs {
  margin-left: -40px;
}

.mm-navbar.mm-hasbtns .mm-btn:not(.mm-hidden) + .mm-breadcrumbs {
  margin-left: 0;
  padding-left: 0;
}

.mm-hasnavbar-top-1 .mm-panels {
  top: 40px;
}

.mm-hasnavbar-top-2 .mm-panels {
  top: 80px;
}

.mm-hasnavbar-top-3 .mm-panels {
  top: 120px;
}

.mm-hasnavbar-top-4 .mm-panels {
  top: 160px;
}

.mm-hasnavbar-bottom-1 .mm-panels {
  bottom: 40px;
}

.mm-hasnavbar-bottom-2 .mm-panels {
  bottom: 80px;
}

.mm-hasnavbar-bottom-3 .mm-panels {
  bottom: 120px;
}

.mm-hasnavbar-bottom-4 .mm-panels {
  bottom: 160px;
}

.mm-navbar-size-2 {
  height: 80px;
}

.mm-navbar-size-3 {
  height: 120px;
}

.mm-navbar-size-4 {
  height: 160px;
}

.mm-navbar-content-2 > * {
  width: 50%;
}

.mm-navbar-content-3 > * {
  width: 33.33%;
}

.mm-navbar-content-4 > * {
  width: 25%;
}

.mm-navbar-content-5 > * {
  width: 20%;
}

.mm-navbar-content-6 > * {
  width: 16.67%;
}

.mmenu__promo {
  letter-spacing: 1px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.mmenu__soc {
  display: flex;
}

.mmenu__soc a {
  flex-grow: 1;
}

.mmenu__soc a + a {
  border-left: 1px solid rgba(255, 255, 255, 0.5);
}

button, input[type="text email"], select, textarea {
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif;
  -webkit-appearance: none;
  border-radius: 0;
}

.wrapper {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  position: relative;
  min-height: 100%;
  padding-top: 3.125rem;
  z-index: 1;
  line-height: 1.6;
  overflow: hidden;
}

.section {
  position: relative;
}

.w-container {
  max-width: 1700px;
  box-sizing: content-box;
  margin-left: auto;
  margin-right: auto;
}

.w-container > * {
  box-sizing: border-box;
}

.w-container .w-container {
  padding-left: 0;
  padding-right: 0;
}

.w-container::before, .w-container::after {
  content: "";
  display: table;
}

.w-container::after {
  clear: both;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #fff;
  z-index: 9;
  transition: -webkit-transform 0.32s ease-out;
  transition: transform 0.32s ease-out;
  transition: transform 0.32s ease-out, -webkit-transform 0.32s ease-out;
  font-size: 0;
  line-height: 1;
}

.header.is-hidden {
  -webkit-transform: translateY(-101%);
  transform: translateY(-101%);
}

.header__inner {
  height: 3.125rem;
}

._head-hide {
  display: none;
}

.link-phone {
  text-decoration: none;
  font-weight: 700;
  outline: none;
}

.link-phone--header {
  font-size: 0.875rem;
  line-height: 1.2em;
  color: #000;
}

.link-phone--callback {
  display: inline-block;
  vertical-align: top;
  font: 700 16px/1.5em Coco Gothic, Arial;
  color: #fff;
}

.text-b11 {
  font-size: 11px;
  line-height: 1.2em;
  color: rgba(0, 0, 0, 0.7);
}

.wezom {
  display: -webkit-inline-box;
  display: inline-flex;
  line-height: 1em;
  -webkit-box-align: center;
  align-items: center;
  color: #000;
}

.wezom__image {
  width: 38px;
  height: 21px;
  margin-right: .6875rem;
}

.wezom__image > svg {
  width: 100%;
  height: 100%;
  fill: currentColor;
}

.wezom__txt {
  font-family: Coco Gothic, Arial;
  font-weight: 700;
  font-size: 16px;
  line-height: 1em;
}

a.wezom {
  text-decoration: none;
}

a.wezom:hover {
  color: #d60000;
}

.button {
  position: relative;
  padding: 0.3125rem 1.25rem;
  height: 3.15625rem;
  max-width: 100%;
  text-decoration: none !important;
  outline: none;
  box-sizing: border-box;
  cursor: pointer;
  letter-spacing: 0.5px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: -webkit-inline-box;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  text-transform: uppercase;
  vertical-align: top;
  text-align: center;
}

.button__text {
  font-family: "Arial";
  font-size: 0.875rem;
  line-height: 1.3em;
  font-weight: 700;
}

.button--default .button__text {
  color: #000;
}

.button--transparent .button__text,
.button--yellow .button__text {
  color: #fff;
}

.button--default {
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: transparent;
}

.button--default:hover, .button--default:focus {
  border-color: #000;
}

.button--default:active {
  border-color: rgba(0, 0, 0, 0.5);
}

.button--transparent {
  border: 1px solid rgba(255, 255, 255, 0.3);
  background-color: transparent;
}

.button--transparent:hover, .button--transparent:focus {
  border-color: #fff;
}

.button--transparent:active {
  border-color: rgba(255, 255, 255, 0.5);
}

.button--yellow {
  border: none;
  background-color: #f00;
  transition: background 0.18s ease;
}

.button--yellow:hover, .button--yellow:focus {
  background: #d60000;
}

.button--yellow:active {
  background: #f00;
}

.button--wide {
  display: block;
  width: 100%;
}

.h-list {
  display: -webkit-box;
  display: flex;
}

.h-list__item {
  position: relative;
  padding: 0 0.7rem;
}

.h-list__item > .h-list {
  position: absolute;
  top: 100%;
  left: -0.8rem;
  background: #fff;
  padding: 1rem 0.5rem;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.15s ease-out, visibility 0s linear;
  box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.03);
}

.h-list__item:hover > .h-list {
  opacity: 1;
  visibility: visible;
}

.h-list__link {
  font-family: "Arial";
  font-size: 0.875rem;
  font-weight: 700;
  color: #000;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 0.5px;
  outline: none;
}

.h-list__link:hover, .h-list__link:focus, .h-list__link.is-active {
  color: #d60000;
}

.h-list .h-list .h-list__link {
  display: inline-block;
  vertical-align: top;
  text-transform: none;
  white-space: nowrap;
  font-weight: 400;
  line-height: 2em;
}

.v-list {
  line-height: 1em;
}

.v-list__title {
  font-size: 11px;
  line-height: 1.4em;
  margin-bottom: 1.74em;
  font-weight: 700;
}

.v-list__title > a {
  text-decoration: none;
  color: #000;
}

.v-list__title > a:hover {
  color: #d60000;
}

.v-list__item {
  margin-bottom: .45rem;
}

.v-list__link {
  display: inline-block;
  vertical-align: top;
  text-decoration: none;
  outline: none;
}

.v-list__link:focus, .v-list__link:hover {
  text-decoration: underline;
}

.promocode-mobile {
  font-size: 10px;
  line-height: 11px;
  text-transform: none;
  text-align: center;
}

.fix-panel {
  position: fixed;
  z-index: 10;
  bottom: 0.5rem;
}

.fix-panel--left {
  left: 0.5rem;
}

.fix-panel--right {
  right: 0.5rem;
}

.fix-panel__item--top {
  top: 0;
  -webkit-transform-origin: bottom left;
  transform-origin: bottom left;
  margin-left: -3px;
}

.fix-panel__item--bottom {
  bottom: 0;
  -webkit-transform-origin: bottom left;
  transform-origin: bottom left;
  margin-left: 6px;
}

.fix-panel__balancer {
  position: absolute;
  top: 0;
}

.fix-panel__item--top .fix-panel__balancer {
  right: 0;
}

.fix-panel__item--bottom .fix-panel__balancer {
  left: 0;
}

.fix-panel__btn {
  position: relative;
  height: 2.875rem;
  width: 2.875rem;
}

.noty {
  text-align: center;
  padding-bottom: 2rem;
}

.noty__image {
  position: relative;
  margin-bottom: 5rem;
}

.noty__icon {
  position: absolute;
  display: flex;
  width: 8rem;
  height: 8rem;
  left: 50%;
  background: #d60000;
  margin-left: -4rem;
  bottom: -4rem;
  border-radius: 50%;
}

.noty__icon > svg {
  fill: #fff;
  margin: auto;
  max-width: 55%;
  max-height: 55%;
}

.noty__message {
  margin-bottom: 1rem;
  padding: 1rem;
  font-size: 1.8rem;
  line-height: 1.4em;
}

.noty .mfp-close {
  color: #fff;
  text-shadow: 0 1px 2px #000;
  font-size: 2rem;
}

.promocode {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  font-size: 11px;
  line-height: 12px;
  color: #000;
  text-transform: uppercase;
  font-weight: 700;
  white-space: nowrap;
  letter-spacing: 2px;
}

.promocode__number {
  display: block;
}

.promocode__number--is-load {
  white-space: nowrap;
  overflow: hidden;
  -webkit-animation: typing 1.5s steps(6, end);
  animation: typing 1.5s steps(6, end);
}

@-webkit-keyframes typing {
  0% {
    width: 0;
  }
  100% {
    width: 48px;
  }
}

@keyframes typing {
  0% {
    width: 0;
  }
  100% {
    width: 48px;
  }
}

.soc-link {
  white-space: nowrap;
}

.soc-link__item {
  text-decoration: none;
  font-size: 11px;
  line-height: 12px;
  padding: 0.8rem 0;
  color: rgba(0, 0, 0, 0.7);
  letter-spacing: 2px;
  text-transform: uppercase;
  font-weight: 700;
  margin: -0.5rem 0 0 1rem;
}

.soc-link__item:hover {
  color: #d60000;
}

.soc-link__item:active {
  color: #f00;
}

.circle-btn {
  position: relative;
  cursor: pointer;
  background: currentColor;
  border-radius: 50%;
  transition: 0.2s ease;
  border: none;
  outline: none;
  display: -webkit-box;
  display: flex;
  padding: 0;
}

.circle-btn:hover,
.circle-btn-wrapper:hover .circle-btn {
  background: #fff;
  box-shadow: inset 0 0 0 1px;
}

.circle-btn:hover svg,
.circle-btn-wrapper:hover .circle-btn svg {
  fill: currentColor;
}

.circle-btn svg {
  margin: auto;
  max-width: 50%;
  max-height: 50%;
  fill: #fff;
}

.circle-btn--default {
  width: 3.125rem;
  height: 3.125rem;
}

.circle-btn--wide {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.circle-btn--binotel {
  color: #8d5047;
}

.circle-btn--facebook {
  color: #0084ff;
}

.circle-btn--telegram {
  color: #08c;
}

.circle-btn--chat {
  color: #FF6550;
}

.circle-btn--play {
  color: #de0000;
}

.top-btn {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  left: 50%;
  bottom: 0;
  margin-left: 0.75rem;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  white-space: nowrap;
  -webkit-transform-origin: bottom left;
  transform-origin: bottom left;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: 0.22s ease;
  opacity: 0;
  visibility: hidden;
  padding: 0.25rem 0;
}

.top-btn.is-visible {
  opacity: 1;
  visibility: visible;
}

.top-btn:hover {
  color: #d60000;
}

.top-btn:hover .top-btn__arrow {
  width: 6.25rem;
}

.top-btn:hover .top-btn__text {
  color: #d60000;
}

.top-btn:active {
  color: #f00;
}

.top-btn:active .top-btn__text {
  color: #f00;
}

.top-btn__text {
  font-weight: 700;
  color: rgba(0, 0, 0, 0.7);
  text-transform: uppercase;
  font-size: 11px;
  padding-right: 0.625rem;
  letter-spacing: 2px;
  font-family: "Arial";
}

.top-btn__arrow {
  display: block;
  vertical-align: top;
  position: relative;
  transition: width 0.3s ease;
  height: 2px;
  margin: 5px 0;
  width: 2.5rem;
  background: currentColor;
}

.top-btn__arrow::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 100%;
  margin-left: -6px;
  border: 2px solid;
  border-bottom-width: 0;
  border-left-width: 0;
  width: 8px;
  height: 8px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transform-origin: top right;
  transform-origin: top right;
}

.bwidget {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.bwidget:hover::before {
  top: -50%;
  right: 50%;
  content: '';
  bottom: -50%;
  width: 150%;
  position: absolute;
  z-index: 0;
}

.bwidget__icon {
  left: 1px;
  top: 1px;
  width: auto;
  height: auto;
  bottom: 1px;
  right: 1px;
  transition-delay: 0.2s;
}

.no-touchevents .bwidget:hover .bwidget__icon--telegram,
.touchevents .bwidget.is-open .bwidget__icon--telegram {
  transition-delay: 0s;
  -webkit-transform: translate3d(-115%, 0, 1px);
  transform: translate3d(-115%, 0, 1px);
}

.no-touchevents .bwidget:hover .bwidget__icon--facebook,
.touchevents .bwidget.is-open .bwidget__icon--facebook {
  transition-delay: 0s;
  -webkit-transform: translate3d(-45%, -110%, 1px);
  transform: translate3d(-45%, -110%, 1px);
}

.link-burger {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  height: 3.125rem;
  width: 3.125rem;
  padding: 1rem;
}

.link-burger__icon {
  position: relative;
  background: #000;
  height: 2px;
  width: 100%;
  transition: width 0.2s ease;
}

.link-burger__icon::after, .link-burger__icon::before {
  position: absolute;
  content: '';
  background: #000;
  height: 2px;
  left: 0;
  transition: width 0.2s ease;
}

.link-burger__icon::after {
  top: -0.375rem;
  width: 100%;
}

.link-burger__icon::before {
  width: 50%;
  top: 0.4375rem;
}

.mm-opened .link-burger__icon {
  -webkit-animation: link-burger 0.75s 0.35s both;
  animation: link-burger 0.75s 0.35s both;
}

.mm-opened .link-burger__icon::before {
  top: 0.1875rem;
  width: 30%;
  -webkit-transform-origin: 0.45rem -0.01rem;
  transform-origin: 0.45rem -0.01rem;
  -webkit-animation: link-burger-before 0.5s 1.1s both;
  animation: link-burger-before 0.5s 1.1s both;
}

.mm-opened .link-burger__icon::after {
  top: -0.65rem;
  width: 30%;
  -webkit-transform-origin: 0.49rem 0;
  transform-origin: 0.49rem 0;
  -webkit-transform: scaleY(1) rotate(-90deg);
  transform: scaleY(1) rotate(-90deg);
  -webkit-animation: link-burger-after 0.5s 1.1s both;
  animation: link-burger-after 0.5s 1.1s both;
}

@-webkit-keyframes link-burger {
  0% {
    -webkit-transform: scaleX(1) rotate(0);
    transform: scaleX(1) rotate(0);
  }
  50% {
    -webkit-transform: scaleX(0) rotate(0deg);
    transform: scaleX(0) rotate(0deg);
  }
  100% {
    -webkit-transform: scaleX(1) rotate(-45deg);
    transform: scaleX(1) rotate(-45deg);
  }
}

@keyframes link-burger {
  0% {
    -webkit-transform: scaleX(1) rotate(0);
    transform: scaleX(1) rotate(0);
  }
  50% {
    -webkit-transform: scaleX(0) rotate(0deg);
    transform: scaleX(0) rotate(0deg);
  }
  100% {
    -webkit-transform: scaleX(1) rotate(-45deg);
    transform: scaleX(1) rotate(-45deg);
  }
}

@-webkit-keyframes link-burger-before {
  0% {
    -webkit-transform: scaleY(0) rotate(0deg);
    transform: scaleY(0) rotate(0deg);
  }
  50% {
    -webkit-transform: scaleY(0) rotate(-90deg);
    transform: scaleY(0) rotate(-90deg);
  }
  100% {
    -webkit-transform: scaleY(1) rotate(-90deg);
    transform: scaleY(1) rotate(-90deg);
  }
}

@keyframes link-burger-before {
  0% {
    -webkit-transform: scaleY(0) rotate(0deg);
    transform: scaleY(0) rotate(0deg);
  }
  50% {
    -webkit-transform: scaleY(0) rotate(-90deg);
    transform: scaleY(0) rotate(-90deg);
  }
  100% {
    -webkit-transform: scaleY(1) rotate(-90deg);
    transform: scaleY(1) rotate(-90deg);
  }
}

@-webkit-keyframes link-burger-after {
  0% {
    -webkit-transform: scaleY(0) rotate(0deg);
    transform: scaleY(0) rotate(0deg);
  }
  50% {
    -webkit-transform: scaleY(0) rotate(-90deg);
    transform: scaleY(0) rotate(-90deg);
  }
  100% {
    -webkit-transform: scaleY(1) rotate(-90deg);
    transform: scaleY(1) rotate(-90deg);
  }
}

@keyframes link-burger-after {
  0% {
    -webkit-transform: scaleY(0) rotate(0deg);
    transform: scaleY(0) rotate(0deg);
  }
  50% {
    -webkit-transform: scaleY(0) rotate(-90deg);
    transform: scaleY(0) rotate(-90deg);
  }
  100% {
    -webkit-transform: scaleY(1) rotate(-90deg);
    transform: scaleY(1) rotate(-90deg);
  }
}

.title {
  line-height: 1.2em;
  font-weight: 700;
  color: #000;
}

.title--small {
  font-size: 0.6875rem;
}

.title--group {
  font-family: "Arial";
  font-size: 0.6875rem;
  text-transform: uppercase;
  color: #d60000;
  letter-spacing: 2px;
}

.title--section {
  font-family: "Arial";
  font-size: 0.875rem;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  max-width: 213px;
}

.title--middle {
  font-family: Coco Gothic, Arial;
  font-size: 1.875rem;
}

.title--case {
  font-family: Coco Gothic, Arial;
  text-transform: uppercase;
  color: #fff;
  font-size: 1.25rem;
  margin-bottom: 0.4rem;
}

.title--big {
  font-family: Coco Gothic, Arial;
  font-size: 1.875rem;
  line-height: 1em;
  flex-wrap: wrap;
}

.title--big.is-anim .lt-anim {
  opacity: 1;
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

.title--big > span {
  display: inline-block;
  vertical-align: top;
  white-space: nowrap;
}

.title--logo {
  font-size: 1.25rem;
  line-height: 1.1em;
  text-transform: uppercase;
  font-family: Coco Gothic, Arial;
  margin-bottom: 0.5em;
}

.title--bot-line {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.title--decor {
  height: 0.625rem;
  width: 1.875rem;
  margin: 0.2rem 0;
  background-color: #000;
}

.grid {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
}

.magnific-popup {
  position: relative;
  max-width: 100%;
  margin: 0 auto;
  overflow: visible;
  background-color: #fff;
}

.magnific-popup--sm {
  max-width: 440px;
}

.magnific-popup--lg {
  max-width: 1000px;
}

.grid > * {
  min-width: 0;
  min-height: 1px;
  box-sizing: border-box;
}

._col-1 {
  flex-basis: 8.33333%;
  width: 8.33333%;
  max-width: 8.33333%;
}

._col-2 {
  flex-basis: 16.66667%;
  width: 16.66667%;
  max-width: 16.66667%;
}

._col-6 {
  flex-basis: 50%;
  width: 50%;
  max-width: 50%;
}

._col-10 {
  flex-basis: 83.33333%;
  width: 83.33333%;
  max-width: 83.33333%;
}

._col-12 {
  flex-basis: 100%;
  width: 100%;
  max-width: 100%;
}

._pb-md {
  padding-bottom: 1.5625rem;
}

._pt-md {
  padding-top: 1.5625rem;
}

._pt-sm {
  padding-top: 0.625rem;
}

._pr-md {
  padding-right: 1.5625rem;
}

._pl-md {
  padding-left: 1.5625rem;
}

._p-md {
  padding: 1.5625rem;
}

._ptb-sm {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
}

._mb-md {
  margin-bottom: 1.5625rem;
}

._mr-auto {
  margin-right: auto;
}

._mr-sm {
  margin-right: 0.625rem;
}

._ml-auto {
  margin-left: auto;
}

._mb-ms {
  margin-bottom: 0.9375rem;
}

._text-center {
  text-align: center;
}

._justify-center {
  -webkit-box-pack: center;
  justify-content: center;
}

._justify-between {
  -webkit-box-pack: justify;
  justify-content: space-between;
}

._items-center {
  -webkit-box-align: center;
  align-items: center;
}

._flex-grow {
  -webkit-box-flex: 1;
  flex-grow: 1;
}

._flex-nowrap {
  flex-wrap: nowrap;
}

._flex-noshrink {
  flex-shrink: 0;
}

._color-black {
  color: black;
}

._min-height {
  min-height: 100%;
}

._height {
  height: 100%;
}

._mb-ms {
  margin-bottom: .9375rem;
}

._text-center {
  text-align: center;
}

.grid--hspace-def {
  margin-left: -1.25rem;
}

.grid--hspace-def > * {
  padding-left: 1.25rem;
}

.grid--hspace-xl {
  margin-left: -1.875rem;
}

.grid--hspace-xl > * {
  padding-left: 1.875rem;
}

._color-white {
  color: #fff;
}

.valuation-section .valuation-content {
  background: white;
  padding: 3rem;
}

@media only screen and (min-width: 480px) {
  ._sm-col-6 {
    flex-basis: 50%;
    width: 50%;
    max-width: 50%;
  }
  ._sm-pl-ms {
    padding-left: 0.9375rem;
  }
  ._sm-hide {
    display: none;
  }
}

@media only screen and (min-width: 640px) {
  .button {
    padding: 0.3125rem 1.375rem;
  }
}

@media only screen and (min-width: 768px) {
  ._md-col-6 {
    flex-basis: 50%;
    width: 50%;
    max-width: 50%;
  }
}

@media only screen and (min-width: 1025px) {
  .wrapper {
    padding-left: 4.5vw;
    padding-right: 4.5vw;
    padding-top: 4.375rem;
  }
  .header__inner {
    height: 4.375rem;
  }
  .header {
    left: 4.5vw;
    right: 4.5vw;
  }
  .fix-panel {
    z-index: 3;
    width: 4.5vw;
    min-height: 25rem;
    top: 6.25rem;
    bottom: 6.25rem;
  }
  .fix-panel--left {
    left: 0;
  }
  .fix-panel--right {
    right: 0;
  }
  .fix-panel__item {
    position: absolute;
    left: 50%;
    width: 12px;
    height: 12px;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }
  .fix-panel__btn {
    position: absolute;
    margin: -1.4375rem 0 0 -1.4375rem;
    top: 50%;
    left: 50%;
  }
  .no-touchevents .bwidget:hover .bwidget__icon--telegram,
  .touchevents .bwidget.is-open .bwidget__icon--telegram {
    -webkit-transform: translate3d(-95%, 55%, 1px);
    transform: translate3d(-95%, 55%, 1px);
  }
  .no-touchevents .bwidget:hover .bwidget__icon--facebook,
  .touchevents .bwidget.is-open .bwidget__icon--facebook {
    -webkit-transform: translate3d(-95%, -55%, 1px);
    transform: translate3d(-95%, -55%, 1px);
  }
  ._def-p-def {
    padding: 3.125rem;
  }
  ._def-pb-lg {
    padding-bottom: 6.25rem;
  }
  ._def-pb-md {
    padding-bottom: 1.5625rem;
  }
  ._def-ptb-none {
    padding-top: 0;
    padding-bottom: 0;
  }
  ._def-mb-md {
    margin-bottom: 1.5625rem;
  }
  ._def-pt-def {
    padding-top: 3.125rem;
  }
  ._def-text-left {
    text-align: left;
  }
  ._def-justify-between {
    -webkit-box-pack: justify;
    justify-content: space-between;
  }
  .grid--def-hspace-sm {
    margin-left: -0.3125rem;
  }
  .grid--def-hspace-sm > * {
    padding-left: 0.3125rem;
  }
  ._def-col-3 {
    flex-basis: 25%;
    width: 25%;
    max-width: 25%;
  }
  ._def-col-4 {
    flex-basis: 33.33333%;
    width: 33.33333%;
    max-width: 33.33333%;
  }
  ._def-mb-sm {
    margin-bottom: 0.625rem;
  }
  ._def-flex-column {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
  }
  ._def-hide {
    display: none;
  }
}

@media only screen and (min-width: 1280px) {
  .button {
    padding: 0.3125rem 2.5rem;
  }
  .h-list__item {
    padding: 0 1rem;
  }
  ._lg-pl-md {
    padding-left: 1.5625rem;
  }
}

@media only screen and (min-width: 1366px) {
  .wrapper {
    padding-left: 100px;
    padding-right: 100px;
    padding-top: 100px;
  }
  .header {
    left: 6.25rem;
    right: 6.25rem;
  }
  .header__inner {
    height: 6.25rem;
  }
  .fix-panel {
    top: 9.375rem;
    width: 6.25rem;
    bottom: 9.375rem;
  }
  .fix-panel__btn {
    height: 3.75rem;
    width: 3.75rem;
    margin: -1.875rem 0 0 -1.875rem;
  }
  .grid--xl-hspace-md {
    margin-left: -0.9375rem;
  }
  .grid--xl-hspace-md > * {
    padding-left: 0.9375rem;
  }
  ._xl-pl-lg {
    padding-left: 6.25rem;
  }
}

@media only screen and (min-width: 1600px) {
  ._head-hide {
    display: block;
  }
}

@media only screen and (max-width: 479px) {
  ._sm-show {
    display: none;
  }
}

@media only screen and (max-width: 1024px) {
  ._def-show {
    display: none;
  }
}

@media only screen and (max-width: 1365px) {
  ._xl-show {
    display: none;
  }
}

.grid {
  display: flex;
  flex-wrap: wrap;
}

.grid .gbox {
  height: 100%;
  min-height: 100%;
}

.grid--1 > .gcell {
  width: 100%;
  max-width: 100%;
  flex-basis: 100%;
}

.grid--2 > .gcell {
  width: 50%;
  max-width: 50%;
  flex-basis: 50%;
}

.grid--3 > .gcell {
  width: 33.3333333%;
  max-width: 33.3333333%;
  flex-basis: 33.3333333%;
}

.grid--4 > .gcell {
  width: 25%;
  max-width: 25%;
  flex-basis: 25%;
}

.grid--5 > .gcell {
  width: 20%;
  max-width: 20%;
  flex-basis: 20%;
}

.grid--6 > .gcell {
  width: 16.6666667%;
  max-width: 16.6666667%;
  flex-basis: 16.6666667%;
}

.grid--7 > .gcell {
  width: 14.2857143%;
  max-width: 14.2857143%;
  flex-basis: 14.2857143%;
}

.grid--8 > .gcell {
  width: 12.5%;
  max-width: 12.5%;
  flex-basis: 12.5%;
}

.grid--9 > .gcell {
  width: 11.1111111%;
  max-width: 11.1111111%;
  flex-basis: 11.1111111%;
}

.grid--10 > .gcell {
  width: 10%;
  max-width: 10%;
  flex-basis: 10%;
}

.grid--11 > .gcell {
  width: 9.0909091%;
  max-width: 9.0909091%;
  flex-basis: 9.0909091%;
}

.grid--12 > .gcell {
  width: 8.3333333%;
  max-width: 8.3333333%;
  flex-basis: 8.3333333%;
}

.grid--space-sm {
  margin-left: -2.5px;
  margin-right: -2.5px;
}

.grid--space-sm > .gcell {
  padding-left: 2.5px;
  padding-right: 2.5px;
}

.grid--space-md {
  margin-left: -5px;
  margin-right: -5px;
}

.grid--space-md > .gcell {
  padding-left: 5px;
  padding-right: 5px;
}

.grid--space-def {
  margin-left: -10px;
  margin-right: -10px;
}

.grid--space-def > .gcell {
  padding-left: 10px;
  padding-right: 10px;
}

.grid--space-lg {
  margin-left: -15px;
  margin-right: -15px;
}

.grid--space-lg > .gcell {
  padding-left: 15px;
  padding-right: 15px;
}

.grid--space-xl {
  margin-left: -20px;
  margin-right: -20px;
}

.grid--space-xl > .gcell {
  padding-left: 20px;
  padding-right: 20px;
}

.grid--space-mg {
  margin-left: -30px;
  margin-right: -30px;
}

.grid--space-mg > .gcell {
  padding-left: 30px;
  padding-right: 30px;
}

.grid > .gcell {
  display: inline-block;
}

.grid > .gcell--1 {
  width: 8.3333333%;
  max-width: 8.3333333%;
  flex-basis: 8.3333333%;
}

.grid > .gcell--2 {
  width: 16.6666667%;
  max-width: 16.6666667%;
  flex-basis: 16.6666667%;
}

.grid > .gcell--3 {
  width: 25%;
  max-width: 25%;
  flex-basis: 25%;
}

.grid > .gcell--4 {
  width: 33.3333333%;
  max-width: 33.3333333%;
  flex-basis: 33.3333333%;
}

.grid > .gcell--5 {
  width: 41.6666667%;
  max-width: 41.6666667%;
  flex-basis: 41.6666667%;
}

.grid > .gcell--6 {
  width: 50%;
  max-width: 50%;
  flex-basis: 50%;
}

.grid > .gcell--7 {
  width: 58.3333333%;
  max-width: 58.3333333%;
  flex-basis: 58.3333333%;
}

.grid > .gcell--8 {
  width: 66.6666667%;
  max-width: 66.6666667%;
  flex-basis: 66.6666667%;
}

.grid > .gcell--9 {
  width: 75%;
  max-width: 75%;
  flex-basis: 75%;
}

.grid > .gcell--10 {
  width: 83.3333333%;
  max-width: 83.3333333%;
  flex-basis: 83.3333333%;
}

.grid > .gcell--11 {
  width: 91.6666667%;
  max-width: 91.6666667%;
  flex-basis: 91.6666667%;
}

.grid > .gcell--12 {
  width: 100%;
  max-width: 100%;
  flex-basis: 100%;
}

@media only screen and (min-width: 375px) {
  .grid--xs-1 > .gcell {
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
  }
  .grid--xs-2 > .gcell {
    width: 50%;
    max-width: 50%;
    flex-basis: 50%;
  }
  .grid--xs-3 > .gcell {
    width: 33.3333333%;
    max-width: 33.3333333%;
    flex-basis: 33.3333333%;
  }
  .grid--xs-4 > .gcell {
    width: 25%;
    max-width: 25%;
    flex-basis: 25%;
  }
  .grid--xs-5 > .gcell {
    width: 20%;
    max-width: 20%;
    flex-basis: 20%;
  }
  .grid--xs-6 > .gcell {
    width: 16.6666667%;
    max-width: 16.6666667%;
    flex-basis: 16.6666667%;
  }
  .grid--xs-7 > .gcell {
    width: 14.2857143%;
    max-width: 14.2857143%;
    flex-basis: 14.2857143%;
  }
  .grid--xs-8 > .gcell {
    width: 12.5%;
    max-width: 12.5%;
    flex-basis: 12.5%;
  }
  .grid--xs-9 > .gcell {
    width: 11.1111111%;
    max-width: 11.1111111%;
    flex-basis: 11.1111111%;
  }
  .grid--xs-10 > .gcell {
    width: 10%;
    max-width: 10%;
    flex-basis: 10%;
  }
  .grid--xs-11 > .gcell {
    width: 9.0909091%;
    max-width: 9.0909091%;
    flex-basis: 9.0909091%;
  }
  .grid--xs-12 > .gcell {
    width: 8.3333333%;
    max-width: 8.3333333%;
    flex-basis: 8.3333333%;
  }
  .grid--xs-auto > .gcell {
    width: auto;
    max-width: none;
    flex-basis: auto;
  }
  .grid > .gcell--xs-1 {
    width: 8.3333333%;
    max-width: 8.3333333%;
    flex-basis: 8.3333333%;
  }
  .grid > .gcell--xs-2 {
    width: 16.6666667%;
    max-width: 16.6666667%;
    flex-basis: 16.6666667%;
  }
  .grid > .gcell--xs-3 {
    width: 25%;
    max-width: 25%;
    flex-basis: 25%;
  }
  .grid > .gcell--xs-4 {
    width: 33.3333333%;
    max-width: 33.3333333%;
    flex-basis: 33.3333333%;
  }
  .grid > .gcell--xs-5 {
    width: 41.6666667%;
    max-width: 41.6666667%;
    flex-basis: 41.6666667%;
  }
  .grid > .gcell--xs-6 {
    width: 50%;
    max-width: 50%;
    flex-basis: 50%;
  }
  .grid > .gcell--xs-7 {
    width: 58.3333333%;
    max-width: 58.3333333%;
    flex-basis: 58.3333333%;
  }
  .grid > .gcell--xs-8 {
    width: 66.6666667%;
    max-width: 66.6666667%;
    flex-basis: 66.6666667%;
  }
  .grid > .gcell--xs-9 {
    width: 75%;
    max-width: 75%;
    flex-basis: 75%;
  }
  .grid > .gcell--xs-10 {
    width: 83.3333333%;
    max-width: 83.3333333%;
    flex-basis: 83.3333333%;
  }
  .grid > .gcell--xs-11 {
    width: 91.6666667%;
    max-width: 91.6666667%;
    flex-basis: 91.6666667%;
  }
  .grid > .gcell--xs-12 {
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
  }
  .grid > .gcell--xs-auto {
    width: auto;
    max-width: 100%;
    flex-basis: auto;
  }
}

@media only screen and (min-width: 480px) {
  .grid--sm-1 > .gcell {
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
  }
  .grid--sm-2 > .gcell {
    width: 50%;
    max-width: 50%;
    flex-basis: 50%;
  }
  .grid--sm-3 > .gcell {
    width: 33.3333333%;
    max-width: 33.3333333%;
    flex-basis: 33.3333333%;
  }
  .grid--sm-4 > .gcell {
    width: 25%;
    max-width: 25%;
    flex-basis: 25%;
  }
  .grid--sm-5 > .gcell {
    width: 20%;
    max-width: 20%;
    flex-basis: 20%;
  }
  .grid--sm-6 > .gcell {
    width: 16.6666667%;
    max-width: 16.6666667%;
    flex-basis: 16.6666667%;
  }
  .grid--sm-7 > .gcell {
    width: 14.2857143%;
    max-width: 14.2857143%;
    flex-basis: 14.2857143%;
  }
  .grid--sm-8 > .gcell {
    width: 12.5%;
    max-width: 12.5%;
    flex-basis: 12.5%;
  }
  .grid--sm-9 > .gcell {
    width: 11.1111111%;
    max-width: 11.1111111%;
    flex-basis: 11.1111111%;
  }
  .grid--sm-10 > .gcell {
    width: 10%;
    max-width: 10%;
    flex-basis: 10%;
  }
  .grid--sm-11 > .gcell {
    width: 9.0909091%;
    max-width: 9.0909091%;
    flex-basis: 9.0909091%;
  }
  .grid--sm-12 > .gcell {
    width: 8.3333333%;
    max-width: 8.3333333%;
    flex-basis: 8.3333333%;
  }
  .grid--sm-auto > .gcell {
    width: auto;
    max-width: none;
    flex-basis: auto;
  }
  .grid > .gcell--sm-1 {
    width: 8.3333333%;
    max-width: 8.3333333%;
    flex-basis: 8.3333333%;
  }
  .grid > .gcell--sm-2 {
    width: 16.6666667%;
    max-width: 16.6666667%;
    flex-basis: 16.6666667%;
  }
  .grid > .gcell--sm-3 {
    width: 25%;
    max-width: 25%;
    flex-basis: 25%;
  }
  .grid > .gcell--sm-4 {
    width: 33.3333333%;
    max-width: 33.3333333%;
    flex-basis: 33.3333333%;
  }
  .grid > .gcell--sm-5 {
    width: 41.6666667%;
    max-width: 41.6666667%;
    flex-basis: 41.6666667%;
  }
  .grid > .gcell--sm-6 {
    width: 50%;
    max-width: 50%;
    flex-basis: 50%;
  }
  .grid > .gcell--sm-7 {
    width: 58.3333333%;
    max-width: 58.3333333%;
    flex-basis: 58.3333333%;
  }
  .grid > .gcell--sm-8 {
    width: 66.6666667%;
    max-width: 66.6666667%;
    flex-basis: 66.6666667%;
  }
  .grid > .gcell--sm-9 {
    width: 75%;
    max-width: 75%;
    flex-basis: 75%;
  }
  .grid > .gcell--sm-10 {
    width: 83.3333333%;
    max-width: 83.3333333%;
    flex-basis: 83.3333333%;
  }
  .grid > .gcell--sm-11 {
    width: 91.6666667%;
    max-width: 91.6666667%;
    flex-basis: 91.6666667%;
  }
  .grid > .gcell--sm-12 {
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
  }
  .grid > .gcell--sm-auto {
    width: auto;
    max-width: 100%;
    flex-basis: auto;
  }
}

@media only screen and (min-width: 640px) {
  .grid--ms-1 > .gcell {
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
  }
  .grid--ms-2 > .gcell {
    width: 50%;
    max-width: 50%;
    flex-basis: 50%;
  }
  .grid--ms-3 > .gcell {
    width: 33.3333333%;
    max-width: 33.3333333%;
    flex-basis: 33.3333333%;
  }
  .grid--ms-4 > .gcell {
    width: 25%;
    max-width: 25%;
    flex-basis: 25%;
  }
  .grid--ms-5 > .gcell {
    width: 20%;
    max-width: 20%;
    flex-basis: 20%;
  }
  .grid--ms-6 > .gcell {
    width: 16.6666667%;
    max-width: 16.6666667%;
    flex-basis: 16.6666667%;
  }
  .grid--ms-7 > .gcell {
    width: 14.2857143%;
    max-width: 14.2857143%;
    flex-basis: 14.2857143%;
  }
  .grid--ms-8 > .gcell {
    width: 12.5%;
    max-width: 12.5%;
    flex-basis: 12.5%;
  }
  .grid--ms-9 > .gcell {
    width: 11.1111111%;
    max-width: 11.1111111%;
    flex-basis: 11.1111111%;
  }
  .grid--ms-10 > .gcell {
    width: 10%;
    max-width: 10%;
    flex-basis: 10%;
  }
  .grid--ms-11 > .gcell {
    width: 9.0909091%;
    max-width: 9.0909091%;
    flex-basis: 9.0909091%;
  }
  .grid--ms-12 > .gcell {
    width: 8.3333333%;
    max-width: 8.3333333%;
    flex-basis: 8.3333333%;
  }
  .grid--ms-auto > .gcell {
    width: auto;
    max-width: none;
    flex-basis: auto;
  }
  .grid > .gcell--ms-1 {
    width: 8.3333333%;
    max-width: 8.3333333%;
    flex-basis: 8.3333333%;
  }
  .grid > .gcell--ms-2 {
    width: 16.6666667%;
    max-width: 16.6666667%;
    flex-basis: 16.6666667%;
  }
  .grid > .gcell--ms-3 {
    width: 25%;
    max-width: 25%;
    flex-basis: 25%;
  }
  .grid > .gcell--ms-4 {
    width: 33.3333333%;
    max-width: 33.3333333%;
    flex-basis: 33.3333333%;
  }
  .grid > .gcell--ms-5 {
    width: 41.6666667%;
    max-width: 41.6666667%;
    flex-basis: 41.6666667%;
  }
  .grid > .gcell--ms-6 {
    width: 50%;
    max-width: 50%;
    flex-basis: 50%;
  }
  .grid > .gcell--ms-7 {
    width: 58.3333333%;
    max-width: 58.3333333%;
    flex-basis: 58.3333333%;
  }
  .grid > .gcell--ms-8 {
    width: 66.6666667%;
    max-width: 66.6666667%;
    flex-basis: 66.6666667%;
  }
  .grid > .gcell--ms-9 {
    width: 75%;
    max-width: 75%;
    flex-basis: 75%;
  }
  .grid > .gcell--ms-10 {
    width: 83.3333333%;
    max-width: 83.3333333%;
    flex-basis: 83.3333333%;
  }
  .grid > .gcell--ms-11 {
    width: 91.6666667%;
    max-width: 91.6666667%;
    flex-basis: 91.6666667%;
  }
  .grid > .gcell--ms-12 {
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
  }
  .grid > .gcell--ms-auto {
    width: auto;
    max-width: 100%;
    flex-basis: auto;
  }
}

@media only screen and (min-width: 768px) {
  .grid--md-1 > .gcell {
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
  }
  .grid--md-2 > .gcell {
    width: 50%;
    max-width: 50%;
    flex-basis: 50%;
  }
  .grid--md-3 > .gcell {
    width: 33.3333333%;
    max-width: 33.3333333%;
    flex-basis: 33.3333333%;
  }
  .grid--md-4 > .gcell {
    width: 25%;
    max-width: 25%;
    flex-basis: 25%;
  }
  .grid--md-5 > .gcell {
    width: 20%;
    max-width: 20%;
    flex-basis: 20%;
  }
  .grid--md-6 > .gcell {
    width: 16.6666667%;
    max-width: 16.6666667%;
    flex-basis: 16.6666667%;
  }
  .grid--md-7 > .gcell {
    width: 14.2857143%;
    max-width: 14.2857143%;
    flex-basis: 14.2857143%;
  }
  .grid--md-8 > .gcell {
    width: 12.5%;
    max-width: 12.5%;
    flex-basis: 12.5%;
  }
  .grid--md-9 > .gcell {
    width: 11.1111111%;
    max-width: 11.1111111%;
    flex-basis: 11.1111111%;
  }
  .grid--md-10 > .gcell {
    width: 10%;
    max-width: 10%;
    flex-basis: 10%;
  }
  .grid--md-11 > .gcell {
    width: 9.0909091%;
    max-width: 9.0909091%;
    flex-basis: 9.0909091%;
  }
  .grid--md-12 > .gcell {
    width: 8.3333333%;
    max-width: 8.3333333%;
    flex-basis: 8.3333333%;
  }
  .grid--md-auto > .gcell {
    width: auto;
    max-width: none;
    flex-basis: auto;
  }
  .grid > .gcell--md-1 {
    width: 8.3333333%;
    max-width: 8.3333333%;
    flex-basis: 8.3333333%;
  }
  .grid > .gcell--md-2 {
    width: 16.6666667%;
    max-width: 16.6666667%;
    flex-basis: 16.6666667%;
  }
  .grid > .gcell--md-3 {
    width: 25%;
    max-width: 25%;
    flex-basis: 25%;
  }
  .grid > .gcell--md-4 {
    width: 33.3333333%;
    max-width: 33.3333333%;
    flex-basis: 33.3333333%;
  }
  .grid > .gcell--md-5 {
    width: 41.6666667%;
    max-width: 41.6666667%;
    flex-basis: 41.6666667%;
  }
  .grid > .gcell--md-6 {
    width: 50%;
    max-width: 50%;
    flex-basis: 50%;
  }
  .grid > .gcell--md-7 {
    width: 58.3333333%;
    max-width: 58.3333333%;
    flex-basis: 58.3333333%;
  }
  .grid > .gcell--md-8 {
    width: 66.6666667%;
    max-width: 66.6666667%;
    flex-basis: 66.6666667%;
  }
  .grid > .gcell--md-9 {
    width: 75%;
    max-width: 75%;
    flex-basis: 75%;
  }
  .grid > .gcell--md-10 {
    width: 83.3333333%;
    max-width: 83.3333333%;
    flex-basis: 83.3333333%;
  }
  .grid > .gcell--md-11 {
    width: 91.6666667%;
    max-width: 91.6666667%;
    flex-basis: 91.6666667%;
  }
  .grid > .gcell--md-12 {
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
  }
  .grid > .gcell--md-auto {
    width: auto;
    max-width: 100%;
    flex-basis: auto;
  }
}

@media only screen and (min-width: 1024px) {
  .grid--def-1 > .gcell {
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
  }
  .grid--def-2 > .gcell {
    width: 50%;
    max-width: 50%;
    flex-basis: 50%;
  }
  .grid--def-3 > .gcell {
    width: 33.3333333%;
    max-width: 33.3333333%;
    flex-basis: 33.3333333%;
  }
  .grid--def-4 > .gcell {
    width: 25%;
    max-width: 25%;
    flex-basis: 25%;
  }
  .grid--def-5 > .gcell {
    width: 20%;
    max-width: 20%;
    flex-basis: 20%;
  }
  .grid--def-6 > .gcell {
    width: 16.6666667%;
    max-width: 16.6666667%;
    flex-basis: 16.6666667%;
  }
  .grid--def-7 > .gcell {
    width: 14.2857143%;
    max-width: 14.2857143%;
    flex-basis: 14.2857143%;
  }
  .grid--def-8 > .gcell {
    width: 12.5%;
    max-width: 12.5%;
    flex-basis: 12.5%;
  }
  .grid--def-9 > .gcell {
    width: 11.1111111%;
    max-width: 11.1111111%;
    flex-basis: 11.1111111%;
  }
  .grid--def-10 > .gcell {
    width: 10%;
    max-width: 10%;
    flex-basis: 10%;
  }
  .grid--def-11 > .gcell {
    width: 9.0909091%;
    max-width: 9.0909091%;
    flex-basis: 9.0909091%;
  }
  .grid--def-12 > .gcell {
    width: 8.3333333%;
    max-width: 8.3333333%;
    flex-basis: 8.3333333%;
  }
  .grid--def-auto > .gcell {
    width: auto;
    max-width: none;
    flex-basis: auto;
  }
  .grid > .gcell--def-1 {
    width: 8.3333333%;
    max-width: 8.3333333%;
    flex-basis: 8.3333333%;
  }
  .grid > .gcell--def-2 {
    width: 16.6666667%;
    max-width: 16.6666667%;
    flex-basis: 16.6666667%;
  }
  .grid > .gcell--def-3 {
    width: 25%;
    max-width: 25%;
    flex-basis: 25%;
  }
  .grid > .gcell--def-4 {
    width: 33.3333333%;
    max-width: 33.3333333%;
    flex-basis: 33.3333333%;
  }
  .grid > .gcell--def-5 {
    width: 41.6666667%;
    max-width: 41.6666667%;
    flex-basis: 41.6666667%;
  }
  .grid > .gcell--def-6 {
    width: 50%;
    max-width: 50%;
    flex-basis: 50%;
  }
  .grid > .gcell--def-7 {
    width: 58.3333333%;
    max-width: 58.3333333%;
    flex-basis: 58.3333333%;
  }
  .grid > .gcell--def-8 {
    width: 66.6666667%;
    max-width: 66.6666667%;
    flex-basis: 66.6666667%;
  }
  .grid > .gcell--def-9 {
    width: 75%;
    max-width: 75%;
    flex-basis: 75%;
  }
  .grid > .gcell--def-10 {
    width: 83.3333333%;
    max-width: 83.3333333%;
    flex-basis: 83.3333333%;
  }
  .grid > .gcell--def-11 {
    width: 91.6666667%;
    max-width: 91.6666667%;
    flex-basis: 91.6666667%;
  }
  .grid > .gcell--def-12 {
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
  }
  .grid > .gcell--def-auto {
    width: auto;
    max-width: 100%;
    flex-basis: auto;
  }
}

@media only screen and (min-width: 1280px) {
  .grid--lg-1 > .gcell {
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
  }
  .grid--lg-2 > .gcell {
    width: 50%;
    max-width: 50%;
    flex-basis: 50%;
  }
  .grid--lg-3 > .gcell {
    width: 33.3333333%;
    max-width: 33.3333333%;
    flex-basis: 33.3333333%;
  }
  .grid--lg-4 > .gcell {
    width: 25%;
    max-width: 25%;
    flex-basis: 25%;
  }
  .grid--lg-5 > .gcell {
    width: 20%;
    max-width: 20%;
    flex-basis: 20%;
  }
  .grid--lg-6 > .gcell {
    width: 16.6666667%;
    max-width: 16.6666667%;
    flex-basis: 16.6666667%;
  }
  .grid--lg-7 > .gcell {
    width: 14.2857143%;
    max-width: 14.2857143%;
    flex-basis: 14.2857143%;
  }
  .grid--lg-8 > .gcell {
    width: 12.5%;
    max-width: 12.5%;
    flex-basis: 12.5%;
  }
  .grid--lg-9 > .gcell {
    width: 11.1111111%;
    max-width: 11.1111111%;
    flex-basis: 11.1111111%;
  }
  .grid--lg-10 > .gcell {
    width: 10%;
    max-width: 10%;
    flex-basis: 10%;
  }
  .grid--lg-11 > .gcell {
    width: 9.0909091%;
    max-width: 9.0909091%;
    flex-basis: 9.0909091%;
  }
  .grid--lg-12 > .gcell {
    width: 8.3333333%;
    max-width: 8.3333333%;
    flex-basis: 8.3333333%;
  }
  .grid--lg-auto > .gcell {
    width: auto;
    max-width: none;
    flex-basis: auto;
  }
  .grid > .gcell--lg-1 {
    width: 8.3333333%;
    max-width: 8.3333333%;
    flex-basis: 8.3333333%;
  }
  .grid > .gcell--lg-2 {
    width: 16.6666667%;
    max-width: 16.6666667%;
    flex-basis: 16.6666667%;
  }
  .grid > .gcell--lg-3 {
    width: 25%;
    max-width: 25%;
    flex-basis: 25%;
  }
  .grid > .gcell--lg-4 {
    width: 33.3333333%;
    max-width: 33.3333333%;
    flex-basis: 33.3333333%;
  }
  .grid > .gcell--lg-5 {
    width: 41.6666667%;
    max-width: 41.6666667%;
    flex-basis: 41.6666667%;
  }
  .grid > .gcell--lg-6 {
    width: 50%;
    max-width: 50%;
    flex-basis: 50%;
  }
  .grid > .gcell--lg-7 {
    width: 58.3333333%;
    max-width: 58.3333333%;
    flex-basis: 58.3333333%;
  }
  .grid > .gcell--lg-8 {
    width: 66.6666667%;
    max-width: 66.6666667%;
    flex-basis: 66.6666667%;
  }
  .grid > .gcell--lg-9 {
    width: 75%;
    max-width: 75%;
    flex-basis: 75%;
  }
  .grid > .gcell--lg-10 {
    width: 83.3333333%;
    max-width: 83.3333333%;
    flex-basis: 83.3333333%;
  }
  .grid > .gcell--lg-11 {
    width: 91.6666667%;
    max-width: 91.6666667%;
    flex-basis: 91.6666667%;
  }
  .grid > .gcell--lg-12 {
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
  }
  .grid > .gcell--lg-auto {
    width: auto;
    max-width: 100%;
    flex-basis: auto;
  }
}

@media only screen and (min-width: 1400px) {
  .grid--xl-1 > .gcell {
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
  }
  .grid--xl-2 > .gcell {
    width: 50%;
    max-width: 50%;
    flex-basis: 50%;
  }
  .grid--xl-3 > .gcell {
    width: 33.3333333%;
    max-width: 33.3333333%;
    flex-basis: 33.3333333%;
  }
  .grid--xl-4 > .gcell {
    width: 25%;
    max-width: 25%;
    flex-basis: 25%;
  }
  .grid--xl-5 > .gcell {
    width: 20%;
    max-width: 20%;
    flex-basis: 20%;
  }
  .grid--xl-6 > .gcell {
    width: 16.6666667%;
    max-width: 16.6666667%;
    flex-basis: 16.6666667%;
  }
  .grid--xl-7 > .gcell {
    width: 14.2857143%;
    max-width: 14.2857143%;
    flex-basis: 14.2857143%;
  }
  .grid--xl-8 > .gcell {
    width: 12.5%;
    max-width: 12.5%;
    flex-basis: 12.5%;
  }
  .grid--xl-9 > .gcell {
    width: 11.1111111%;
    max-width: 11.1111111%;
    flex-basis: 11.1111111%;
  }
  .grid--xl-10 > .gcell {
    width: 10%;
    max-width: 10%;
    flex-basis: 10%;
  }
  .grid--xl-11 > .gcell {
    width: 9.0909091%;
    max-width: 9.0909091%;
    flex-basis: 9.0909091%;
  }
  .grid--xl-12 > .gcell {
    width: 8.3333333%;
    max-width: 8.3333333%;
    flex-basis: 8.3333333%;
  }
  .grid--xl-auto > .gcell {
    width: auto;
    max-width: none;
    flex-basis: auto;
  }
  .grid > .gcell--xl-1 {
    width: 8.3333333%;
    max-width: 8.3333333%;
    flex-basis: 8.3333333%;
  }
  .grid > .gcell--xl-2 {
    width: 16.6666667%;
    max-width: 16.6666667%;
    flex-basis: 16.6666667%;
  }
  .grid > .gcell--xl-3 {
    width: 25%;
    max-width: 25%;
    flex-basis: 25%;
  }
  .grid > .gcell--xl-4 {
    width: 33.3333333%;
    max-width: 33.3333333%;
    flex-basis: 33.3333333%;
  }
  .grid > .gcell--xl-5 {
    width: 41.6666667%;
    max-width: 41.6666667%;
    flex-basis: 41.6666667%;
  }
  .grid > .gcell--xl-6 {
    width: 50%;
    max-width: 50%;
    flex-basis: 50%;
  }
  .grid > .gcell--xl-7 {
    width: 58.3333333%;
    max-width: 58.3333333%;
    flex-basis: 58.3333333%;
  }
  .grid > .gcell--xl-8 {
    width: 66.6666667%;
    max-width: 66.6666667%;
    flex-basis: 66.6666667%;
  }
  .grid > .gcell--xl-9 {
    width: 75%;
    max-width: 75%;
    flex-basis: 75%;
  }
  .grid > .gcell--xl-10 {
    width: 83.3333333%;
    max-width: 83.3333333%;
    flex-basis: 83.3333333%;
  }
  .grid > .gcell--xl-11 {
    width: 91.6666667%;
    max-width: 91.6666667%;
    flex-basis: 91.6666667%;
  }
  .grid > .gcell--xl-12 {
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
  }
  .grid > .gcell--xl-auto {
    width: auto;
    max-width: 100%;
    flex-basis: auto;
  }
}

.grid--inline {
  display: inline-flex;
}

.grid--items-start {
  align-items: flex-start;
}

.grid--items-end {
  align-items: flex-end;
}

.grid--items-center {
  align-items: center;
}

.grid--items-stretch {
  align-items: stretch;
}

.grid--justify-start {
  justify-content: flex-start;
}

.grid--justify-end {
  justify-content: flex-end;
}

.grid--justify-center {
  justify-content: center;
}

.grid--justify-between {
  justify-content: space-between;
}

.grid--justify-around {
  justify-content: space-around;
}

.grid--nowrap {
  flex-wrap: nowrap;
}

.grid--column {
  flex-direction: column;
}

.grid--column > .gcell {
  flex-basis: auto;
}

.grid--row {
  flex-direction: row;
}

.grid--row-reverse {
  flex-direction: row-reverse;
}

.grid > .gcell--start {
  align-self: flex-start;
}

.grid > .gcell--end {
  align-self: flex-end;
}

.grid > .gcell--center {
  align-self: center;
}

.grid > .gcell--stretch {
  align-self: stretch;
}

.grid > .gcell--nogrow {
  flex-grow: 0;
}

.grid > .gcell--grow {
  flex-grow: 1;
}

.grid > .gcell--shrink {
  flex-shrink: 1;
}

.grid > .gcell--noshrink {
  flex-shrink: 0;
}

.grid > .gcell--order-start {
  order: -1;
}

.grid > .gcell--order-end {
  order: 1;
}

.grid > .gcell--noorder {
  order: 0;
}

.grid > .gcell--nobasis {
  flex-basis: 0;
}

@media only screen and (min-width: 375px) {
  .grid--xs-items-start {
    align-items: flex-start;
  }
  .grid--xs-items-end {
    align-items: flex-end;
  }
  .grid--xs-items-center {
    align-items: center;
  }
  .grid--xs-items-stretch {
    align-items: stretch;
  }
  .grid--xs-justify-start {
    justify-content: flex-start;
  }
  .grid--xs-justify-end {
    justify-content: flex-end;
  }
  .grid--xs-justify-center {
    justify-content: center;
  }
  .grid--xs-justify-between {
    justify-content: space-between;
  }
  .grid--xs-justify-around {
    justify-content: space-around;
  }
  .grid--xs-nowrap {
    flex-wrap: nowrap;
  }
  .grid--xs-wrap {
    flex-wrap: wrap;
  }
  .grid--xs-column {
    flex-direction: column;
  }
  .grid--xs-row {
    flex-direction: row;
  }
  .grid--xs-row-reverse {
    flex-direction: row-reverse;
  }
  .grid > .gcell--xs-start {
    align-self: flex-start;
  }
  .grid > .gcell--xs-end {
    align-self: flex-end;
  }
  .grid > .gcell--xs-center {
    align-self: center;
  }
  .grid > .gcell--xs-stretch {
    align-self: stretch;
  }
  .grid > .gcell--xs-nogrow {
    flex-grow: 0;
  }
  .grid > .gcell--xs-grow {
    flex-grow: 1;
  }
  .grid > .gcell--xs-shrink {
    flex-shrink: 1;
  }
  .grid > .gcell--xs-noshrink {
    flex-shrink: 0;
  }
  .grid > .gcell--xs-order-start {
    order: -1;
  }
  .grid > .gcell--xs-order-end {
    order: 1;
  }
  .grid > .gcell--xs-noorder {
    order: 0;
  }
  .grid > .gcell--xs-nobasis {
    flex-basis: 0;
  }
}

@media only screen and (min-width: 480px) {
  .grid--sm-items-start {
    align-items: flex-start;
  }
  .grid--sm-items-end {
    align-items: flex-end;
  }
  .grid--sm-items-center {
    align-items: center;
  }
  .grid--sm-items-stretch {
    align-items: stretch;
  }
  .grid--sm-justify-start {
    justify-content: flex-start;
  }
  .grid--sm-justify-end {
    justify-content: flex-end;
  }
  .grid--sm-justify-center {
    justify-content: center;
  }
  .grid--sm-justify-between {
    justify-content: space-between;
  }
  .grid--sm-justify-around {
    justify-content: space-around;
  }
  .grid--sm-nowrap {
    flex-wrap: nowrap;
  }
  .grid--sm-wrap {
    flex-wrap: wrap;
  }
  .grid--sm-column {
    flex-direction: column;
  }
  .grid--sm-row {
    flex-direction: row;
  }
  .grid--sm-row-reverse {
    flex-direction: row-reverse;
  }
  .grid > .gcell--sm-start {
    align-self: flex-start;
  }
  .grid > .gcell--sm-end {
    align-self: flex-end;
  }
  .grid > .gcell--sm-center {
    align-self: center;
  }
  .grid > .gcell--sm-stretch {
    align-self: stretch;
  }
  .grid > .gcell--sm-nogrow {
    flex-grow: 0;
  }
  .grid > .gcell--sm-grow {
    flex-grow: 1;
  }
  .grid > .gcell--sm-shrink {
    flex-shrink: 1;
  }
  .grid > .gcell--sm-noshrink {
    flex-shrink: 0;
  }
  .grid > .gcell--sm-order-start {
    order: -1;
  }
  .grid > .gcell--sm-order-end {
    order: 1;
  }
  .grid > .gcell--sm-noorder {
    order: 0;
  }
  .grid > .gcell--sm-nobasis {
    flex-basis: 0;
  }
}

@media only screen and (min-width: 640px) {
  .grid--ms-items-start {
    align-items: flex-start;
  }
  .grid--ms-items-end {
    align-items: flex-end;
  }
  .grid--ms-items-center {
    align-items: center;
  }
  .grid--ms-items-stretch {
    align-items: stretch;
  }
  .grid--ms-justify-start {
    justify-content: flex-start;
  }
  .grid--ms-justify-end {
    justify-content: flex-end;
  }
  .grid--ms-justify-center {
    justify-content: center;
  }
  .grid--ms-justify-between {
    justify-content: space-between;
  }
  .grid--ms-justify-around {
    justify-content: space-around;
  }
  .grid--ms-nowrap {
    flex-wrap: nowrap;
  }
  .grid--ms-wrap {
    flex-wrap: wrap;
  }
  .grid--ms-column {
    flex-direction: column;
  }
  .grid--ms-row {
    flex-direction: row;
  }
  .grid--ms-row-reverse {
    flex-direction: row-reverse;
  }
  .grid > .gcell--ms-start {
    align-self: flex-start;
  }
  .grid > .gcell--ms-end {
    align-self: flex-end;
  }
  .grid > .gcell--ms-center {
    align-self: center;
  }
  .grid > .gcell--ms-stretch {
    align-self: stretch;
  }
  .grid > .gcell--ms-nogrow {
    flex-grow: 0;
  }
  .grid > .gcell--ms-grow {
    flex-grow: 1;
  }
  .grid > .gcell--ms-shrink {
    flex-shrink: 1;
  }
  .grid > .gcell--ms-noshrink {
    flex-shrink: 0;
  }
  .grid > .gcell--ms-order-start {
    order: -1;
  }
  .grid > .gcell--ms-order-end {
    order: 1;
  }
  .grid > .gcell--ms-noorder {
    order: 0;
  }
  .grid > .gcell--ms-nobasis {
    flex-basis: 0;
  }
}

@media only screen and (min-width: 768px) {
  .grid--md-items-start {
    align-items: flex-start;
  }
  .grid--md-items-end {
    align-items: flex-end;
  }
  .grid--md-items-center {
    align-items: center;
  }
  .grid--md-items-stretch {
    align-items: stretch;
  }
  .grid--md-justify-start {
    justify-content: flex-start;
  }
  .grid--md-justify-end {
    justify-content: flex-end;
  }
  .grid--md-justify-center {
    justify-content: center;
  }
  .grid--md-justify-between {
    justify-content: space-between;
  }
  .grid--md-justify-around {
    justify-content: space-around;
  }
  .grid--md-nowrap {
    flex-wrap: nowrap;
  }
  .grid--md-wrap {
    flex-wrap: wrap;
  }
  .grid--md-column {
    flex-direction: column;
  }
  .grid--md-row {
    flex-direction: row;
  }
  .grid--md-row-reverse {
    flex-direction: row-reverse;
  }
  .grid > .gcell--md-start {
    align-self: flex-start;
  }
  .grid > .gcell--md-end {
    align-self: flex-end;
  }
  .grid > .gcell--md-center {
    align-self: center;
  }
  .grid > .gcell--md-stretch {
    align-self: stretch;
  }
  .grid > .gcell--md-nogrow {
    flex-grow: 0;
  }
  .grid > .gcell--md-grow {
    flex-grow: 1;
  }
  .grid > .gcell--md-shrink {
    flex-shrink: 1;
  }
  .grid > .gcell--md-noshrink {
    flex-shrink: 0;
  }
  .grid > .gcell--md-order-start {
    order: -1;
  }
  .grid > .gcell--md-order-end {
    order: 1;
  }
  .grid > .gcell--md-noorder {
    order: 0;
  }
  .grid > .gcell--md-nobasis {
    flex-basis: 0;
  }
}

@media only screen and (min-width: 1024px) {
  .grid--def-items-start {
    align-items: flex-start;
  }
  .grid--def-items-end {
    align-items: flex-end;
  }
  .grid--def-items-center {
    align-items: center;
  }
  .grid--def-items-stretch {
    align-items: stretch;
  }
  .grid--def-justify-start {
    justify-content: flex-start;
  }
  .grid--def-justify-end {
    justify-content: flex-end;
  }
  .grid--def-justify-center {
    justify-content: center;
  }
  .grid--def-justify-between {
    justify-content: space-between;
  }
  .grid--def-justify-around {
    justify-content: space-around;
  }
  .grid--def-nowrap {
    flex-wrap: nowrap;
  }
  .grid--def-wrap {
    flex-wrap: wrap;
  }
  .grid--def-column {
    flex-direction: column;
  }
  .grid--def-row {
    flex-direction: row;
  }
  .grid--def-row-reverse {
    flex-direction: row-reverse;
  }
  .grid > .gcell--def-start {
    align-self: flex-start;
  }
  .grid > .gcell--def-end {
    align-self: flex-end;
  }
  .grid > .gcell--def-center {
    align-self: center;
  }
  .grid > .gcell--def-stretch {
    align-self: stretch;
  }
  .grid > .gcell--def-nogrow {
    flex-grow: 0;
  }
  .grid > .gcell--def-grow {
    flex-grow: 1;
  }
  .grid > .gcell--def-shrink {
    flex-shrink: 1;
  }
  .grid > .gcell--def-noshrink {
    flex-shrink: 0;
  }
  .grid > .gcell--def-order-start {
    order: -1;
  }
  .grid > .gcell--def-order-end {
    order: 1;
  }
  .grid > .gcell--def-noorder {
    order: 0;
  }
  .grid > .gcell--def-nobasis {
    flex-basis: 0;
  }
}

@media only screen and (min-width: 1280px) {
  .grid--lg-items-start {
    align-items: flex-start;
  }
  .grid--lg-items-end {
    align-items: flex-end;
  }
  .grid--lg-items-center {
    align-items: center;
  }
  .grid--lg-items-stretch {
    align-items: stretch;
  }
  .grid--lg-justify-start {
    justify-content: flex-start;
  }
  .grid--lg-justify-end {
    justify-content: flex-end;
  }
  .grid--lg-justify-center {
    justify-content: center;
  }
  .grid--lg-justify-between {
    justify-content: space-between;
  }
  .grid--lg-justify-around {
    justify-content: space-around;
  }
  .grid--lg-nowrap {
    flex-wrap: nowrap;
  }
  .grid--lg-wrap {
    flex-wrap: wrap;
  }
  .grid--lg-column {
    flex-direction: column;
  }
  .grid--lg-row {
    flex-direction: row;
  }
  .grid--lg-row-reverse {
    flex-direction: row-reverse;
  }
  .grid > .gcell--lg-start {
    align-self: flex-start;
  }
  .grid > .gcell--lg-end {
    align-self: flex-end;
  }
  .grid > .gcell--lg-center {
    align-self: center;
  }
  .grid > .gcell--lg-stretch {
    align-self: stretch;
  }
  .grid > .gcell--lg-nogrow {
    flex-grow: 0;
  }
  .grid > .gcell--lg-grow {
    flex-grow: 1;
  }
  .grid > .gcell--lg-shrink {
    flex-shrink: 1;
  }
  .grid > .gcell--lg-noshrink {
    flex-shrink: 0;
  }
  .grid > .gcell--lg-order-start {
    order: -1;
  }
  .grid > .gcell--lg-order-end {
    order: 1;
  }
  .grid > .gcell--lg-noorder {
    order: 0;
  }
  .grid > .gcell--lg-nobasis {
    flex-basis: 0;
  }
}

@media only screen and (min-width: 1400px) {
  .grid--xl-items-start {
    align-items: flex-start;
  }
  .grid--xl-items-end {
    align-items: flex-end;
  }
  .grid--xl-items-center {
    align-items: center;
  }
  .grid--xl-items-stretch {
    align-items: stretch;
  }
  .grid--xl-justify-start {
    justify-content: flex-start;
  }
  .grid--xl-justify-end {
    justify-content: flex-end;
  }
  .grid--xl-justify-center {
    justify-content: center;
  }
  .grid--xl-justify-between {
    justify-content: space-between;
  }
  .grid--xl-justify-around {
    justify-content: space-around;
  }
  .grid--xl-nowrap {
    flex-wrap: nowrap;
  }
  .grid--xl-wrap {
    flex-wrap: wrap;
  }
  .grid--xl-column {
    flex-direction: column;
  }
  .grid--xl-row {
    flex-direction: row;
  }
  .grid--xl-row-reverse {
    flex-direction: row-reverse;
  }
  .grid > .gcell--xl-start {
    align-self: flex-start;
  }
  .grid > .gcell--xl-end {
    align-self: flex-end;
  }
  .grid > .gcell--xl-center {
    align-self: center;
  }
  .grid > .gcell--xl-stretch {
    align-self: stretch;
  }
  .grid > .gcell--xl-nogrow {
    flex-grow: 0;
  }
  .grid > .gcell--xl-grow {
    flex-grow: 1;
  }
  .grid > .gcell--xl-shrink {
    flex-shrink: 1;
  }
  .grid > .gcell--xl-noshrink {
    flex-shrink: 0;
  }
  .grid > .gcell--xl-order-start {
    order: -1;
  }
  .grid > .gcell--xl-order-end {
    order: 1;
  }
  .grid > .gcell--xl-noorder {
    order: 0;
  }
  .grid > .gcell--xl-nobasis {
    flex-basis: 0;
  }
}

.grid > .gcell--parser-half {
  width: 360px;
  max-width: 360px;
  flex-basis: 360px;
}

@media only screen and (min-width: 375px) {
  .grid--xs-space-sm {
    margin-left: -2.5px;
    margin-right: -2.5px;
  }
  .grid--xs-space-sm > .gcell {
    padding-left: 2.5px;
    padding-right: 2.5px;
  }
  .grid--xs-space-md {
    margin-left: -5px;
    margin-right: -5px;
  }
  .grid--xs-space-md > .gcell {
    padding-left: 5px;
    padding-right: 5px;
  }
  .grid--xs-space-def {
    margin-left: -10px;
    margin-right: -10px;
  }
  .grid--xs-space-def > .gcell {
    padding-left: 10px;
    padding-right: 10px;
  }
  .grid--xs-space-lg {
    margin-left: -15px;
    margin-right: -15px;
  }
  .grid--xs-space-lg > .gcell {
    padding-left: 15px;
    padding-right: 15px;
  }
  .grid--xs-space-xl {
    margin-left: -20px;
    margin-right: -20px;
  }
  .grid--xs-space-xl > .gcell {
    padding-left: 20px;
    padding-right: 20px;
  }
  .grid--xs-space-mg {
    margin-left: -30px;
    margin-right: -30px;
  }
  .grid--xs-space-mg > .gcell {
    padding-left: 30px;
    padding-right: 30px;
  }
  .grid--xs-nospace {
    margin: 0;
  }
  .grid--xs-nospace > .gcell {
    padding: 0;
  }
}

@media only screen and (min-width: 480px) {
  .grid--sm-space-sm {
    margin-left: -2.5px;
    margin-right: -2.5px;
  }
  .grid--sm-space-sm > .gcell {
    padding-left: 2.5px;
    padding-right: 2.5px;
  }
  .grid--sm-space-md {
    margin-left: -5px;
    margin-right: -5px;
  }
  .grid--sm-space-md > .gcell {
    padding-left: 5px;
    padding-right: 5px;
  }
  .grid--sm-space-def {
    margin-left: -10px;
    margin-right: -10px;
  }
  .grid--sm-space-def > .gcell {
    padding-left: 10px;
    padding-right: 10px;
  }
  .grid--sm-space-lg {
    margin-left: -15px;
    margin-right: -15px;
  }
  .grid--sm-space-lg > .gcell {
    padding-left: 15px;
    padding-right: 15px;
  }
  .grid--sm-space-xl {
    margin-left: -20px;
    margin-right: -20px;
  }
  .grid--sm-space-xl > .gcell {
    padding-left: 20px;
    padding-right: 20px;
  }
  .grid--sm-space-mg {
    margin-left: -30px;
    margin-right: -30px;
  }
  .grid--sm-space-mg > .gcell {
    padding-left: 30px;
    padding-right: 30px;
  }
  .grid--sm-nospace {
    margin: 0;
  }
  .grid--sm-nospace > .gcell {
    padding: 0;
  }
}

@media only screen and (min-width: 640px) {
  .grid--ms-space-sm {
    margin-left: -2.5px;
    margin-right: -2.5px;
  }
  .grid--ms-space-sm > .gcell {
    padding-left: 2.5px;
    padding-right: 2.5px;
  }
  .grid--ms-space-md {
    margin-left: -5px;
    margin-right: -5px;
  }
  .grid--ms-space-md > .gcell {
    padding-left: 5px;
    padding-right: 5px;
  }
  .grid--ms-space-def {
    margin-left: -10px;
    margin-right: -10px;
  }
  .grid--ms-space-def > .gcell {
    padding-left: 10px;
    padding-right: 10px;
  }
  .grid--ms-space-lg {
    margin-left: -15px;
    margin-right: -15px;
  }
  .grid--ms-space-lg > .gcell {
    padding-left: 15px;
    padding-right: 15px;
  }
  .grid--ms-space-xl {
    margin-left: -20px;
    margin-right: -20px;
  }
  .grid--ms-space-xl > .gcell {
    padding-left: 20px;
    padding-right: 20px;
  }
  .grid--ms-space-mg {
    margin-left: -30px;
    margin-right: -30px;
  }
  .grid--ms-space-mg > .gcell {
    padding-left: 30px;
    padding-right: 30px;
  }
  .grid--ms-nospace {
    margin: 0;
  }
  .grid--ms-nospace > .gcell {
    padding: 0;
  }
}

@media only screen and (min-width: 768px) {
  .grid--md-space-sm {
    margin-left: -2.5px;
    margin-right: -2.5px;
  }
  .grid--md-space-sm > .gcell {
    padding-left: 2.5px;
    padding-right: 2.5px;
  }
  .grid--md-space-md {
    margin-left: -5px;
    margin-right: -5px;
  }
  .grid--md-space-md > .gcell {
    padding-left: 5px;
    padding-right: 5px;
  }
  .grid--md-space-def {
    margin-left: -10px;
    margin-right: -10px;
  }
  .grid--md-space-def > .gcell {
    padding-left: 10px;
    padding-right: 10px;
  }
  .grid--md-space-lg {
    margin-left: -15px;
    margin-right: -15px;
  }
  .grid--md-space-lg > .gcell {
    padding-left: 15px;
    padding-right: 15px;
  }
  .grid--md-space-xl {
    margin-left: -20px;
    margin-right: -20px;
  }
  .grid--md-space-xl > .gcell {
    padding-left: 20px;
    padding-right: 20px;
  }
  .grid--md-space-mg {
    margin-left: -30px;
    margin-right: -30px;
  }
  .grid--md-space-mg > .gcell {
    padding-left: 30px;
    padding-right: 30px;
  }
  .grid--md-nospace {
    margin: 0;
  }
  .grid--md-nospace > .gcell {
    padding: 0;
  }
}

@media only screen and (min-width: 1024px) {
  .grid--def-space-sm {
    margin-left: -2.5px;
    margin-right: -2.5px;
  }
  .grid--def-space-sm > .gcell {
    padding-left: 2.5px;
    padding-right: 2.5px;
  }
  .grid--def-space-md {
    margin-left: -5px;
    margin-right: -5px;
  }
  .grid--def-space-md > .gcell {
    padding-left: 5px;
    padding-right: 5px;
  }
  .grid--def-space-def {
    margin-left: -10px;
    margin-right: -10px;
  }
  .grid--def-space-def > .gcell {
    padding-left: 10px;
    padding-right: 10px;
  }
  .grid--def-space-lg {
    margin-left: -15px;
    margin-right: -15px;
  }
  .grid--def-space-lg > .gcell {
    padding-left: 15px;
    padding-right: 15px;
  }
  .grid--def-space-xl {
    margin-left: -20px;
    margin-right: -20px;
  }
  .grid--def-space-xl > .gcell {
    padding-left: 20px;
    padding-right: 20px;
  }
  .grid--def-space-mg {
    margin-left: -30px;
    margin-right: -30px;
  }
  .grid--def-space-mg > .gcell {
    padding-left: 30px;
    padding-right: 30px;
  }
  .grid--def-nospace {
    margin: 0;
  }
  .grid--def-nospace > .gcell {
    padding: 0;
  }
}

@media only screen and (min-width: 1280px) {
  .grid--lg-space-sm {
    margin-left: -2.5px;
    margin-right: -2.5px;
  }
  .grid--lg-space-sm > .gcell {
    padding-left: 2.5px;
    padding-right: 2.5px;
  }
  .grid--lg-space-md {
    margin-left: -5px;
    margin-right: -5px;
  }
  .grid--lg-space-md > .gcell {
    padding-left: 5px;
    padding-right: 5px;
  }
  .grid--lg-space-def {
    margin-left: -10px;
    margin-right: -10px;
  }
  .grid--lg-space-def > .gcell {
    padding-left: 10px;
    padding-right: 10px;
  }
  .grid--lg-space-lg {
    margin-left: -15px;
    margin-right: -15px;
  }
  .grid--lg-space-lg > .gcell {
    padding-left: 15px;
    padding-right: 15px;
  }
  .grid--lg-space-xl {
    margin-left: -20px;
    margin-right: -20px;
  }
  .grid--lg-space-xl > .gcell {
    padding-left: 20px;
    padding-right: 20px;
  }
  .grid--lg-space-mg {
    margin-left: -30px;
    margin-right: -30px;
  }
  .grid--lg-space-mg > .gcell {
    padding-left: 30px;
    padding-right: 30px;
  }
  .grid--lg-nospace {
    margin: 0;
  }
  .grid--lg-nospace > .gcell {
    padding: 0;
  }
}

@media only screen and (min-width: 1400px) {
  .grid--xl-space-sm {
    margin-left: -2.5px;
    margin-right: -2.5px;
  }
  .grid--xl-space-sm > .gcell {
    padding-left: 2.5px;
    padding-right: 2.5px;
  }
  .grid--xl-space-md {
    margin-left: -5px;
    margin-right: -5px;
  }
  .grid--xl-space-md > .gcell {
    padding-left: 5px;
    padding-right: 5px;
  }
  .grid--xl-space-def {
    margin-left: -10px;
    margin-right: -10px;
  }
  .grid--xl-space-def > .gcell {
    padding-left: 10px;
    padding-right: 10px;
  }
  .grid--xl-space-lg {
    margin-left: -15px;
    margin-right: -15px;
  }
  .grid--xl-space-lg > .gcell {
    padding-left: 15px;
    padding-right: 15px;
  }
  .grid--xl-space-xl {
    margin-left: -20px;
    margin-right: -20px;
  }
  .grid--xl-space-xl > .gcell {
    padding-left: 20px;
    padding-right: 20px;
  }
  .grid--xl-space-mg {
    margin-left: -30px;
    margin-right: -30px;
  }
  .grid--xl-space-mg > .gcell {
    padding-left: 30px;
    padding-right: 30px;
  }
  .grid--xl-nospace {
    margin: 0;
  }
  .grid--xl-nospace > .gcell {
    padding: 0;
  }
}

._color-black {
  color: #000;
}

._color-gray90 {
  color: #1a1a1a;
}

._color-gray75 {
  color: #404040;
}

._color-gray50 {
  color: #807f7f;
}

._color-gray25 {
  color: #bfbfbf;
}

._color-gray10 {
  color: #e6e6e6;
}

._color-white {
  color: #fff;
}

._color-link {
  color: #43f;
}

._color-primary {
  color: #ec1c23;
}

._color-secondary {
  color: #e652a6;
}

._color-success {
  color: #c1af7a;
}

._color-info {
  color: #00b3fe;
}

._color-warning {
  color: #c89600;
}

._color-danger {
  color: #ff6262;
}

._bgcolor-black {
  background-color: #000;
}

._bgcolor-gray90 {
  background-color: #1a1a1a;
}

._bgcolor-gray75 {
  background-color: #404040;
}

._bgcolor-gray50 {
  background-color: #807f7f;
}

._bgcolor-gray25 {
  background-color: #bfbfbf;
}

._bgcolor-gray10 {
  background-color: #e6e6e6;
}

._bgcolor-white {
  background-color: #fff;
}

._bgcolor-link {
  background-color: #43f;
}

._bgcolor-primary {
  background-color: #ec1c23;
}

._bgcolor-secondary {
  background-color: #e652a6;
}

._bgcolor-success {
  background-color: #c1af7a;
}

._bgcolor-info {
  background-color: #00b3fe;
}

._bgcolor-warning {
  background-color: #c89600;
}

._bgcolor-danger {
  background-color: #ff6262;
}

._hide {
  display: none;
}

._hide\! {
  display: none !important;
}

@media print {
  ._noprint {
    display: none !important;
  }
}

@media only screen and (min-width: 375px) {
  ._xs-hide {
    display: none;
  }
  ._xs-hide\! {
    display: none !important;
  }
}

@media only screen and (max-width: 374px) {
  ._xs-show {
    display: none;
  }
  ._xs-show\! {
    display: none !important;
  }
}

@media only screen and (min-width: 480px) {
  ._sm-hide {
    display: none;
  }
  ._sm-hide\! {
    display: none !important;
  }
}

@media only screen and (max-width: 479px) {
  ._sm-show {
    display: none;
  }
  ._sm-show\! {
    display: none !important;
  }
}

@media only screen and (min-width: 640px) {
  ._ms-hide {
    display: none;
  }
  ._ms-hide\! {
    display: none !important;
  }
}

@media only screen and (max-width: 639px) {
  ._ms-show {
    display: none;
  }
  ._ms-show\! {
    display: none !important;
  }
}

@media only screen and (min-width: 768px) {
  ._md-hide {
    display: none;
  }
  ._md-hide\! {
    display: none !important;
  }
}

@media only screen and (max-width: 767px) {
  ._md-show {
    display: none;
  }
  ._md-show\! {
    display: none !important;
  }
}

@media only screen and (min-width: 1024px) {
  ._def-hide {
    display: none;
  }
  ._def-hide\! {
    display: none !important;
  }
}

@media only screen and (max-width: 1023px) {
  ._def-show {
    display: none;
  }
  ._def-show\! {
    display: none !important;
  }
}

@media only screen and (min-width: 1280px) {
  ._lg-hide {
    display: none;
  }
  ._lg-hide\! {
    display: none !important;
  }
}

@media only screen and (max-width: 1279px) {
  ._lg-show {
    display: none;
  }
  ._lg-show\! {
    display: none !important;
  }
}

@media only screen and (min-width: 1400px) {
  ._xl-hide {
    display: none;
  }
  ._xl-hide\! {
    display: none !important;
  }
}

@media only screen and (max-width: 1399px) {
  ._xl-show {
    display: none;
  }
  ._xl-show\! {
    display: none !important;
  }
}

._old-m-sm {
  margin: 0.3125rem;
}

._old-m-md {
  margin: 0.625rem;
}

._old-m-def {
  margin: 1.25rem;
}

._old-m-lg {
  margin: 1.875rem;
}

._old-m-xl {
  margin: 2.5rem;
}

._old-m-mg {
  margin: 3.75rem;
}

._old-mt-sm {
  margin-top: 0.3125rem;
}

._old-mt-md {
  margin-top: 0.625rem;
}

._old-mt-def {
  margin-top: 1.25rem;
}

._old-mt-lg {
  margin-top: 1.875rem;
}

._old-mt-xl {
  margin-top: 2.5rem;
}

._old-mt-mg {
  margin-top: 3.75rem;
}

._old-mt-none {
  margin-top: 0 !important;
}

._old-mr-sm {
  margin-right: 0.3125rem;
}

._old-mr-md {
  margin-right: 0.625rem;
}

._old-mr-def {
  margin-right: 1.25rem;
}

._old-mr-lg {
  margin-right: 1.875rem;
}

._old-mr-xl {
  margin-right: 2.5rem;
}

._old-mr-mg {
  margin-right: 3.75rem;
}

._old-mr-none {
  margin-right: 0 !important;
}

._old-mb-sm {
  margin-bottom: 0.3125rem;
}

._old-mb-md {
  margin-bottom: 0.625rem;
}

._old-mb-def {
  margin-bottom: 1.25rem;
}

._old-mb-lg {
  margin-bottom: 1.875rem;
}

._old-mb-xl {
  margin-bottom: 2.5rem;
}

._old-mb-mg {
  margin-bottom: 3.75rem;
}

._old-mb-none {
  margin-bottom: 0 !important;
}

._old-ml-sm {
  margin-left: 0.3125rem;
}

._old-ml-md {
  margin-left: 0.625rem;
}

._old-ml-def {
  margin-left: 1.25rem;
}

._old-ml-lg {
  margin-left: 1.875rem;
}

._old-ml-xl {
  margin-left: 2.5rem;
}

._old-ml-mg {
  margin-left: 3.75rem;
}

._old-ml-none {
  margin-left: 0 !important;
}

._old-m-none {
  margin: 0 !important;
}

._old-p-sm {
  padding: 0.3125rem;
}

._old-p-md {
  padding: 0.625rem;
}

._old-p-def {
  padding: 1.25rem;
}

._old-p-lg {
  padding: 1.875rem;
}

._old-p-xl {
  padding: 2.5rem;
}

._old-p-mg {
  padding: 3.75rem;
}

._old-pt-sm {
  padding-top: 0.3125rem;
}

._old-pt-md {
  padding-top: 0.625rem;
}

._old-pt-def {
  padding-top: 1.25rem;
}

._old-pt-lg {
  padding-top: 1.875rem;
}

._old-pt-xl {
  padding-top: 2.5rem;
}

._old-pt-mg {
  padding-top: 3.75rem;
}

._old-pt-none {
  padding-top: 0 !important;
}

._old-pr-sm {
  padding-right: 0.3125rem;
}

._old-pr-md {
  padding-right: 0.625rem;
}

._old-pr-def {
  padding-right: 1.25rem;
}

._old-pr-lg {
  padding-right: 1.875rem;
}

._old-pr-xl {
  padding-right: 2.5rem;
}

._old-pr-mg {
  padding-right: 3.75rem;
}

._old-pr-none {
  padding-right: 0 !important;
}

._old-pb-sm {
  padding-bottom: 0.3125rem;
}

._old-pb-md {
  padding-bottom: 0.625rem;
}

._old-pb-def {
  padding-bottom: 1.25rem;
}

._old-pb-lg {
  padding-bottom: 1.875rem;
}

._old-pb-xl {
  padding-bottom: 2.5rem;
}

._old-pb-mg {
  padding-bottom: 3.75rem;
}

._old-pb-none {
  padding-bottom: 0 !important;
}

._old-pl-sm {
  padding-left: 0.3125rem;
}

._old-pl-md {
  padding-left: 0.625rem;
}

._old-pl-def {
  padding-left: 1.25rem;
}

._old-pl-lg {
  padding-left: 1.875rem;
}

._old-pl-xl {
  padding-left: 2.5rem;
}

._old-pl-mg {
  padding-left: 3.75rem;
}

._old-pl-none {
  padding-left: 0 !important;
}

._old-p-none {
  padding: 0 !important;
}

._old-m-auto {
  margin: auto !important;
}

._old-ml-auto {
  margin-left: auto !important;
}

._old-mr-auto {
  margin-right: auto !important;
}

@media only screen and (min-width: 375px) {
  ._old-xs-m-sm {
    margin: 0.3125rem;
  }
  ._old-xs-m-md {
    margin: 0.625rem;
  }
  ._old-xs-m-def {
    margin: 1.25rem;
  }
  ._old-xs-m-lg {
    margin: 1.875rem;
  }
  ._old-xs-m-xl {
    margin: 2.5rem;
  }
  ._old-xs-m-mg {
    margin: 3.75rem;
  }
  ._old-xs-mt-sm {
    margin-top: 0.3125rem;
  }
  ._old-xs-mt-md {
    margin-top: 0.625rem;
  }
  ._old-xs-mt-def {
    margin-top: 1.25rem;
  }
  ._old-xs-mt-lg {
    margin-top: 1.875rem;
  }
  ._old-xs-mt-xl {
    margin-top: 2.5rem;
  }
  ._old-xs-mt-mg {
    margin-top: 3.75rem;
  }
  ._old-xs-mt-none {
    margin-top: 0 !important;
  }
  ._old-xs-mr-sm {
    margin-right: 0.3125rem;
  }
  ._old-xs-mr-md {
    margin-right: 0.625rem;
  }
  ._old-xs-mr-def {
    margin-right: 1.25rem;
  }
  ._old-xs-mr-lg {
    margin-right: 1.875rem;
  }
  ._old-xs-mr-xl {
    margin-right: 2.5rem;
  }
  ._old-xs-mr-mg {
    margin-right: 3.75rem;
  }
  ._old-xs-mr-none {
    margin-right: 0 !important;
  }
  ._old-xs-mb-sm {
    margin-bottom: 0.3125rem;
  }
  ._old-xs-mb-md {
    margin-bottom: 0.625rem;
  }
  ._old-xs-mb-def {
    margin-bottom: 1.25rem;
  }
  ._old-xs-mb-lg {
    margin-bottom: 1.875rem;
  }
  ._old-xs-mb-xl {
    margin-bottom: 2.5rem;
  }
  ._old-xs-mb-mg {
    margin-bottom: 3.75rem;
  }
  ._old-xs-mb-none {
    margin-bottom: 0 !important;
  }
  ._old-xs-ml-sm {
    margin-left: 0.3125rem;
  }
  ._old-xs-ml-md {
    margin-left: 0.625rem;
  }
  ._old-xs-ml-def {
    margin-left: 1.25rem;
  }
  ._old-xs-ml-lg {
    margin-left: 1.875rem;
  }
  ._old-xs-ml-xl {
    margin-left: 2.5rem;
  }
  ._old-xs-ml-mg {
    margin-left: 3.75rem;
  }
  ._old-xs-ml-none {
    margin-left: 0 !important;
  }
  ._old-xs-m-none {
    margin: 0 !important;
  }
  ._old-xs-p-sm {
    padding: 0.3125rem;
  }
  ._old-xs-p-md {
    padding: 0.625rem;
  }
  ._old-xs-p-def {
    padding: 1.25rem;
  }
  ._old-xs-p-lg {
    padding: 1.875rem;
  }
  ._old-xs-p-xl {
    padding: 2.5rem;
  }
  ._old-xs-p-mg {
    padding: 3.75rem;
  }
  ._old-xs-pt-sm {
    padding-top: 0.3125rem;
  }
  ._old-xs-pt-md {
    padding-top: 0.625rem;
  }
  ._old-xs-pt-def {
    padding-top: 1.25rem;
  }
  ._old-xs-pt-lg {
    padding-top: 1.875rem;
  }
  ._old-xs-pt-xl {
    padding-top: 2.5rem;
  }
  ._old-xs-pt-mg {
    padding-top: 3.75rem;
  }
  ._old-xs-pt-none {
    padding-top: 0 !important;
  }
  ._old-xs-pr-sm {
    padding-right: 0.3125rem;
  }
  ._old-xs-pr-md {
    padding-right: 0.625rem;
  }
  ._old-xs-pr-def {
    padding-right: 1.25rem;
  }
  ._old-xs-pr-lg {
    padding-right: 1.875rem;
  }
  ._old-xs-pr-xl {
    padding-right: 2.5rem;
  }
  ._old-xs-pr-mg {
    padding-right: 3.75rem;
  }
  ._old-xs-pr-none {
    padding-right: 0 !important;
  }
  ._old-xs-pb-sm {
    padding-bottom: 0.3125rem;
  }
  ._old-xs-pb-md {
    padding-bottom: 0.625rem;
  }
  ._old-xs-pb-def {
    padding-bottom: 1.25rem;
  }
  ._old-xs-pb-lg {
    padding-bottom: 1.875rem;
  }
  ._old-xs-pb-xl {
    padding-bottom: 2.5rem;
  }
  ._old-xs-pb-mg {
    padding-bottom: 3.75rem;
  }
  ._old-xs-pb-none {
    padding-bottom: 0 !important;
  }
  ._old-xs-pl-sm {
    padding-left: 0.3125rem;
  }
  ._old-xs-pl-md {
    padding-left: 0.625rem;
  }
  ._old-xs-pl-def {
    padding-left: 1.25rem;
  }
  ._old-xs-pl-lg {
    padding-left: 1.875rem;
  }
  ._old-xs-pl-xl {
    padding-left: 2.5rem;
  }
  ._old-xs-pl-mg {
    padding-left: 3.75rem;
  }
  ._old-xs-pl-none {
    padding-left: 0 !important;
  }
  ._old-xs-p-none {
    padding: 0 !important;
  }
  ._old-xs-m-auto {
    margin: auto !important;
  }
  ._old-xs-ml-auto {
    margin-left: auto !important;
  }
  ._old-xs-mr-auto {
    margin-right: auto !important;
  }
}

@media only screen and (min-width: 480px) {
  ._old-sm-m-sm {
    margin: 0.3125rem;
  }
  ._old-sm-m-md {
    margin: 0.625rem;
  }
  ._old-sm-m-def {
    margin: 1.25rem;
  }
  ._old-sm-m-lg {
    margin: 1.875rem;
  }
  ._old-sm-m-xl {
    margin: 2.5rem;
  }
  ._old-sm-m-mg {
    margin: 3.75rem;
  }
  ._old-sm-mt-sm {
    margin-top: 0.3125rem;
  }
  ._old-sm-mt-md {
    margin-top: 0.625rem;
  }
  ._old-sm-mt-def {
    margin-top: 1.25rem;
  }
  ._old-sm-mt-lg {
    margin-top: 1.875rem;
  }
  ._old-sm-mt-xl {
    margin-top: 2.5rem;
  }
  ._old-sm-mt-mg {
    margin-top: 3.75rem;
  }
  ._old-sm-mt-none {
    margin-top: 0 !important;
  }
  ._old-sm-mr-sm {
    margin-right: 0.3125rem;
  }
  ._old-sm-mr-md {
    margin-right: 0.625rem;
  }
  ._old-sm-mr-def {
    margin-right: 1.25rem;
  }
  ._old-sm-mr-lg {
    margin-right: 1.875rem;
  }
  ._old-sm-mr-xl {
    margin-right: 2.5rem;
  }
  ._old-sm-mr-mg {
    margin-right: 3.75rem;
  }
  ._old-sm-mr-none {
    margin-right: 0 !important;
  }
  ._old-sm-mb-sm {
    margin-bottom: 0.3125rem;
  }
  ._old-sm-mb-md {
    margin-bottom: 0.625rem;
  }
  ._old-sm-mb-def {
    margin-bottom: 1.25rem;
  }
  ._old-sm-mb-lg {
    margin-bottom: 1.875rem;
  }
  ._old-sm-mb-xl {
    margin-bottom: 2.5rem;
  }
  ._old-sm-mb-mg {
    margin-bottom: 3.75rem;
  }
  ._old-sm-mb-none {
    margin-bottom: 0 !important;
  }
  ._old-sm-ml-sm {
    margin-left: 0.3125rem;
  }
  ._old-sm-ml-md {
    margin-left: 0.625rem;
  }
  ._old-sm-ml-def {
    margin-left: 1.25rem;
  }
  ._old-sm-ml-lg {
    margin-left: 1.875rem;
  }
  ._old-sm-ml-xl {
    margin-left: 2.5rem;
  }
  ._old-sm-ml-mg {
    margin-left: 3.75rem;
  }
  ._old-sm-ml-none {
    margin-left: 0 !important;
  }
  ._old-sm-m-none {
    margin: 0 !important;
  }
  ._old-sm-p-sm {
    padding: 0.3125rem;
  }
  ._old-sm-p-md {
    padding: 0.625rem;
  }
  ._old-sm-p-def {
    padding: 1.25rem;
  }
  ._old-sm-p-lg {
    padding: 1.875rem;
  }
  ._old-sm-p-xl {
    padding: 2.5rem;
  }
  ._old-sm-p-mg {
    padding: 3.75rem;
  }
  ._old-sm-pt-sm {
    padding-top: 0.3125rem;
  }
  ._old-sm-pt-md {
    padding-top: 0.625rem;
  }
  ._old-sm-pt-def {
    padding-top: 1.25rem;
  }
  ._old-sm-pt-lg {
    padding-top: 1.875rem;
  }
  ._old-sm-pt-xl {
    padding-top: 2.5rem;
  }
  ._old-sm-pt-mg {
    padding-top: 3.75rem;
  }
  ._old-sm-pt-none {
    padding-top: 0 !important;
  }
  ._old-sm-pr-sm {
    padding-right: 0.3125rem;
  }
  ._old-sm-pr-md {
    padding-right: 0.625rem;
  }
  ._old-sm-pr-def {
    padding-right: 1.25rem;
  }
  ._old-sm-pr-lg {
    padding-right: 1.875rem;
  }
  ._old-sm-pr-xl {
    padding-right: 2.5rem;
  }
  ._old-sm-pr-mg {
    padding-right: 3.75rem;
  }
  ._old-sm-pr-none {
    padding-right: 0 !important;
  }
  ._old-sm-pb-sm {
    padding-bottom: 0.3125rem;
  }
  ._old-sm-pb-md {
    padding-bottom: 0.625rem;
  }
  ._old-sm-pb-def {
    padding-bottom: 1.25rem;
  }
  ._old-sm-pb-lg {
    padding-bottom: 1.875rem;
  }
  ._old-sm-pb-xl {
    padding-bottom: 2.5rem;
  }
  ._old-sm-pb-mg {
    padding-bottom: 3.75rem;
  }
  ._old-sm-pb-none {
    padding-bottom: 0 !important;
  }
  ._old-sm-pl-sm {
    padding-left: 0.3125rem;
  }
  ._old-sm-pl-md {
    padding-left: 0.625rem;
  }
  ._old-sm-pl-def {
    padding-left: 1.25rem;
  }
  ._old-sm-pl-lg {
    padding-left: 1.875rem;
  }
  ._old-sm-pl-xl {
    padding-left: 2.5rem;
  }
  ._old-sm-pl-mg {
    padding-left: 3.75rem;
  }
  ._old-sm-pl-none {
    padding-left: 0 !important;
  }
  ._old-sm-p-none {
    padding: 0 !important;
  }
  ._old-sm-m-auto {
    margin: auto !important;
  }
  ._old-sm-ml-auto {
    margin-left: auto !important;
  }
  ._old-sm-mr-auto {
    margin-right: auto !important;
  }
}

@media only screen and (min-width: 640px) {
  ._old-ms-m-sm {
    margin: 0.3125rem;
  }
  ._old-ms-m-md {
    margin: 0.625rem;
  }
  ._old-ms-m-def {
    margin: 1.25rem;
  }
  ._old-ms-m-lg {
    margin: 1.875rem;
  }
  ._old-ms-m-xl {
    margin: 2.5rem;
  }
  ._old-ms-m-mg {
    margin: 3.75rem;
  }
  ._old-ms-mt-sm {
    margin-top: 0.3125rem;
  }
  ._old-ms-mt-md {
    margin-top: 0.625rem;
  }
  ._old-ms-mt-def {
    margin-top: 1.25rem;
  }
  ._old-ms-mt-lg {
    margin-top: 1.875rem;
  }
  ._old-ms-mt-xl {
    margin-top: 2.5rem;
  }
  ._old-ms-mt-mg {
    margin-top: 3.75rem;
  }
  ._old-ms-mt-none {
    margin-top: 0 !important;
  }
  ._old-ms-mr-sm {
    margin-right: 0.3125rem;
  }
  ._old-ms-mr-md {
    margin-right: 0.625rem;
  }
  ._old-ms-mr-def {
    margin-right: 1.25rem;
  }
  ._old-ms-mr-lg {
    margin-right: 1.875rem;
  }
  ._old-ms-mr-xl {
    margin-right: 2.5rem;
  }
  ._old-ms-mr-mg {
    margin-right: 3.75rem;
  }
  ._old-ms-mr-none {
    margin-right: 0 !important;
  }
  ._old-ms-mb-sm {
    margin-bottom: 0.3125rem;
  }
  ._old-ms-mb-md {
    margin-bottom: 0.625rem;
  }
  ._old-ms-mb-def {
    margin-bottom: 1.25rem;
  }
  ._old-ms-mb-lg {
    margin-bottom: 1.875rem;
  }
  ._old-ms-mb-xl {
    margin-bottom: 2.5rem;
  }
  ._old-ms-mb-mg {
    margin-bottom: 3.75rem;
  }
  ._old-ms-mb-none {
    margin-bottom: 0 !important;
  }
  ._old-ms-ml-sm {
    margin-left: 0.3125rem;
  }
  ._old-ms-ml-md {
    margin-left: 0.625rem;
  }
  ._old-ms-ml-def {
    margin-left: 1.25rem;
  }
  ._old-ms-ml-lg {
    margin-left: 1.875rem;
  }
  ._old-ms-ml-xl {
    margin-left: 2.5rem;
  }
  ._old-ms-ml-mg {
    margin-left: 3.75rem;
  }
  ._old-ms-ml-none {
    margin-left: 0 !important;
  }
  ._old-ms-m-none {
    margin: 0 !important;
  }
  ._old-ms-p-sm {
    padding: 0.3125rem;
  }
  ._old-ms-p-md {
    padding: 0.625rem;
  }
  ._old-ms-p-def {
    padding: 1.25rem;
  }
  ._old-ms-p-lg {
    padding: 1.875rem;
  }
  ._old-ms-p-xl {
    padding: 2.5rem;
  }
  ._old-ms-p-mg {
    padding: 3.75rem;
  }
  ._old-ms-pt-sm {
    padding-top: 0.3125rem;
  }
  ._old-ms-pt-md {
    padding-top: 0.625rem;
  }
  ._old-ms-pt-def {
    padding-top: 1.25rem;
  }
  ._old-ms-pt-lg {
    padding-top: 1.875rem;
  }
  ._old-ms-pt-xl {
    padding-top: 2.5rem;
  }
  ._old-ms-pt-mg {
    padding-top: 3.75rem;
  }
  ._old-ms-pt-none {
    padding-top: 0 !important;
  }
  ._old-ms-pr-sm {
    padding-right: 0.3125rem;
  }
  ._old-ms-pr-md {
    padding-right: 0.625rem;
  }
  ._old-ms-pr-def {
    padding-right: 1.25rem;
  }
  ._old-ms-pr-lg {
    padding-right: 1.875rem;
  }
  ._old-ms-pr-xl {
    padding-right: 2.5rem;
  }
  ._old-ms-pr-mg {
    padding-right: 3.75rem;
  }
  ._old-ms-pr-none {
    padding-right: 0 !important;
  }
  ._old-ms-pb-sm {
    padding-bottom: 0.3125rem;
  }
  ._old-ms-pb-md {
    padding-bottom: 0.625rem;
  }
  ._old-ms-pb-def {
    padding-bottom: 1.25rem;
  }
  ._old-ms-pb-lg {
    padding-bottom: 1.875rem;
  }
  ._old-ms-pb-xl {
    padding-bottom: 2.5rem;
  }
  ._old-ms-pb-mg {
    padding-bottom: 3.75rem;
  }
  ._old-ms-pb-none {
    padding-bottom: 0 !important;
  }
  ._old-ms-pl-sm {
    padding-left: 0.3125rem;
  }
  ._old-ms-pl-md {
    padding-left: 0.625rem;
  }
  ._old-ms-pl-def {
    padding-left: 1.25rem;
  }
  ._old-ms-pl-lg {
    padding-left: 1.875rem;
  }
  ._old-ms-pl-xl {
    padding-left: 2.5rem;
  }
  ._old-ms-pl-mg {
    padding-left: 3.75rem;
  }
  ._old-ms-pl-none {
    padding-left: 0 !important;
  }
  ._old-ms-p-none {
    padding: 0 !important;
  }
  ._old-ms-m-auto {
    margin: auto !important;
  }
  ._old-ms-ml-auto {
    margin-left: auto !important;
  }
  ._old-ms-mr-auto {
    margin-right: auto !important;
  }
}

@media only screen and (min-width: 768px) {
  ._old-md-m-sm {
    margin: 0.3125rem;
  }
  ._old-md-m-md {
    margin: 0.625rem;
  }
  ._old-md-m-def {
    margin: 1.25rem;
  }
  ._old-md-m-lg {
    margin: 1.875rem;
  }
  ._old-md-m-xl {
    margin: 2.5rem;
  }
  ._old-md-m-mg {
    margin: 3.75rem;
  }
  ._old-md-mt-sm {
    margin-top: 0.3125rem;
  }
  ._old-md-mt-md {
    margin-top: 0.625rem;
  }
  ._old-md-mt-def {
    margin-top: 1.25rem;
  }
  ._old-md-mt-lg {
    margin-top: 1.875rem;
  }
  ._old-md-mt-xl {
    margin-top: 2.5rem;
  }
  ._old-md-mt-mg {
    margin-top: 3.75rem;
  }
  ._old-md-mt-none {
    margin-top: 0 !important;
  }
  ._old-md-mr-sm {
    margin-right: 0.3125rem;
  }
  ._old-md-mr-md {
    margin-right: 0.625rem;
  }
  ._old-md-mr-def {
    margin-right: 1.25rem;
  }
  ._old-md-mr-lg {
    margin-right: 1.875rem;
  }
  ._old-md-mr-xl {
    margin-right: 2.5rem;
  }
  ._old-md-mr-mg {
    margin-right: 3.75rem;
  }
  ._old-md-mr-none {
    margin-right: 0 !important;
  }
  ._old-md-mb-sm {
    margin-bottom: 0.3125rem;
  }
  ._old-md-mb-md {
    margin-bottom: 0.625rem;
  }
  ._old-md-mb-def {
    margin-bottom: 1.25rem;
  }
  ._old-md-mb-lg {
    margin-bottom: 1.875rem;
  }
  ._old-md-mb-xl {
    margin-bottom: 2.5rem;
  }
  ._old-md-mb-mg {
    margin-bottom: 3.75rem;
  }
  ._old-md-mb-none {
    margin-bottom: 0 !important;
  }
  ._old-md-ml-sm {
    margin-left: 0.3125rem;
  }
  ._old-md-ml-md {
    margin-left: 0.625rem;
  }
  ._old-md-ml-def {
    margin-left: 1.25rem;
  }
  ._old-md-ml-lg {
    margin-left: 1.875rem;
  }
  ._old-md-ml-xl {
    margin-left: 2.5rem;
  }
  ._old-md-ml-mg {
    margin-left: 3.75rem;
  }
  ._old-md-ml-none {
    margin-left: 0 !important;
  }
  ._old-md-m-none {
    margin: 0 !important;
  }
  ._old-md-p-sm {
    padding: 0.3125rem;
  }
  ._old-md-p-md {
    padding: 0.625rem;
  }
  ._old-md-p-def {
    padding: 1.25rem;
  }
  ._old-md-p-lg {
    padding: 1.875rem;
  }
  ._old-md-p-xl {
    padding: 2.5rem;
  }
  ._old-md-p-mg {
    padding: 3.75rem;
  }
  ._old-md-pt-sm {
    padding-top: 0.3125rem;
  }
  ._old-md-pt-md {
    padding-top: 0.625rem;
  }
  ._old-md-pt-def {
    padding-top: 1.25rem;
  }
  ._old-md-pt-lg {
    padding-top: 1.875rem;
  }
  ._old-md-pt-xl {
    padding-top: 2.5rem;
  }
  ._old-md-pt-mg {
    padding-top: 3.75rem;
  }
  ._old-md-pt-none {
    padding-top: 0 !important;
  }
  ._old-md-pr-sm {
    padding-right: 0.3125rem;
  }
  ._old-md-pr-md {
    padding-right: 0.625rem;
  }
  ._old-md-pr-def {
    padding-right: 1.25rem;
  }
  ._old-md-pr-lg {
    padding-right: 1.875rem;
  }
  ._old-md-pr-xl {
    padding-right: 2.5rem;
  }
  ._old-md-pr-mg {
    padding-right: 3.75rem;
  }
  ._old-md-pr-none {
    padding-right: 0 !important;
  }
  ._old-md-pb-sm {
    padding-bottom: 0.3125rem;
  }
  ._old-md-pb-md {
    padding-bottom: 0.625rem;
  }
  ._old-md-pb-def {
    padding-bottom: 1.25rem;
  }
  ._old-md-pb-lg {
    padding-bottom: 1.875rem;
  }
  ._old-md-pb-xl {
    padding-bottom: 2.5rem;
  }
  ._old-md-pb-mg {
    padding-bottom: 3.75rem;
  }
  ._old-md-pb-none {
    padding-bottom: 0 !important;
  }
  ._old-md-pl-sm {
    padding-left: 0.3125rem;
  }
  ._old-md-pl-md {
    padding-left: 0.625rem;
  }
  ._old-md-pl-def {
    padding-left: 1.25rem;
  }
  ._old-md-pl-lg {
    padding-left: 1.875rem;
  }
  ._old-md-pl-xl {
    padding-left: 2.5rem;
  }
  ._old-md-pl-mg {
    padding-left: 3.75rem;
  }
  ._old-md-pl-none {
    padding-left: 0 !important;
  }
  ._old-md-p-none {
    padding: 0 !important;
  }
  ._old-md-m-auto {
    margin: auto !important;
  }
  ._old-md-ml-auto {
    margin-left: auto !important;
  }
  ._old-md-mr-auto {
    margin-right: auto !important;
  }
}

@media only screen and (min-width: 1024px) {
  ._old-def-m-sm {
    margin: 0.3125rem;
  }
  ._old-def-m-md {
    margin: 0.625rem;
  }
  ._old-def-m-def {
    margin: 1.25rem;
  }
  ._old-def-m-lg {
    margin: 1.875rem;
  }
  ._old-def-m-xl {
    margin: 2.5rem;
  }
  ._old-def-m-mg {
    margin: 3.75rem;
  }
  ._old-def-mt-sm {
    margin-top: 0.3125rem;
  }
  ._old-def-mt-md {
    margin-top: 0.625rem;
  }
  ._old-def-mt-def {
    margin-top: 1.25rem;
  }
  ._old-def-mt-lg {
    margin-top: 1.875rem;
  }
  ._old-def-mt-xl {
    margin-top: 2.5rem;
  }
  ._old-def-mt-mg {
    margin-top: 3.75rem;
  }
  ._old-def-mt-none {
    margin-top: 0 !important;
  }
  ._old-def-mr-sm {
    margin-right: 0.3125rem;
  }
  ._old-def-mr-md {
    margin-right: 0.625rem;
  }
  ._old-def-mr-def {
    margin-right: 1.25rem;
  }
  ._old-def-mr-lg {
    margin-right: 1.875rem;
  }
  ._old-def-mr-xl {
    margin-right: 2.5rem;
  }
  ._old-def-mr-mg {
    margin-right: 3.75rem;
  }
  ._old-def-mr-none {
    margin-right: 0 !important;
  }
  ._old-def-mb-sm {
    margin-bottom: 0.3125rem;
  }
  ._old-def-mb-md {
    margin-bottom: 0.625rem;
  }
  ._old-def-mb-def {
    margin-bottom: 1.25rem;
  }
  ._old-def-mb-lg {
    margin-bottom: 1.875rem;
  }
  ._old-def-mb-xl {
    margin-bottom: 2.5rem;
  }
  ._old-def-mb-mg {
    margin-bottom: 3.75rem;
  }
  ._old-def-mb-none {
    margin-bottom: 0 !important;
  }
  ._old-def-ml-sm {
    margin-left: 0.3125rem;
  }
  ._old-def-ml-md {
    margin-left: 0.625rem;
  }
  ._old-def-ml-def {
    margin-left: 1.25rem;
  }
  ._old-def-ml-lg {
    margin-left: 1.875rem;
  }
  ._old-def-ml-xl {
    margin-left: 2.5rem;
  }
  ._old-def-ml-mg {
    margin-left: 3.75rem;
  }
  ._old-def-ml-none {
    margin-left: 0 !important;
  }
  ._old-def-m-none {
    margin: 0 !important;
  }
  ._old-def-p-sm {
    padding: 0.3125rem;
  }
  ._old-def-p-md {
    padding: 0.625rem;
  }
  ._old-def-p-def {
    padding: 1.25rem;
  }
  ._old-def-p-lg {
    padding: 1.875rem;
  }
  ._old-def-p-xl {
    padding: 2.5rem;
  }
  ._old-def-p-mg {
    padding: 3.75rem;
  }
  ._old-def-pt-sm {
    padding-top: 0.3125rem;
  }
  ._old-def-pt-md {
    padding-top: 0.625rem;
  }
  ._old-def-pt-def {
    padding-top: 1.25rem;
  }
  ._old-def-pt-lg {
    padding-top: 1.875rem;
  }
  ._old-def-pt-xl {
    padding-top: 2.5rem;
  }
  ._old-def-pt-mg {
    padding-top: 3.75rem;
  }
  ._old-def-pt-none {
    padding-top: 0 !important;
  }
  ._old-def-pr-sm {
    padding-right: 0.3125rem;
  }
  ._old-def-pr-md {
    padding-right: 0.625rem;
  }
  ._old-def-pr-def {
    padding-right: 1.25rem;
  }
  ._old-def-pr-lg {
    padding-right: 1.875rem;
  }
  ._old-def-pr-xl {
    padding-right: 2.5rem;
  }
  ._old-def-pr-mg {
    padding-right: 3.75rem;
  }
  ._old-def-pr-none {
    padding-right: 0 !important;
  }
  ._old-def-pb-sm {
    padding-bottom: 0.3125rem;
  }
  ._old-def-pb-md {
    padding-bottom: 0.625rem;
  }
  ._old-def-pb-def {
    padding-bottom: 1.25rem;
  }
  ._old-def-pb-lg {
    padding-bottom: 1.875rem;
  }
  ._old-def-pb-xl {
    padding-bottom: 2.5rem;
  }
  ._old-def-pb-mg {
    padding-bottom: 3.75rem;
  }
  ._old-def-pb-none {
    padding-bottom: 0 !important;
  }
  ._old-def-pl-sm {
    padding-left: 0.3125rem;
  }
  ._old-def-pl-md {
    padding-left: 0.625rem;
  }
  ._old-def-pl-def {
    padding-left: 1.25rem;
  }
  ._old-def-pl-lg {
    padding-left: 1.875rem;
  }
  ._old-def-pl-xl {
    padding-left: 2.5rem;
  }
  ._old-def-pl-mg {
    padding-left: 3.75rem;
  }
  ._old-def-pl-none {
    padding-left: 0 !important;
  }
  ._old-def-p-none {
    padding: 0 !important;
  }
  ._old-def-m-auto {
    margin: auto !important;
  }
  ._old-def-ml-auto {
    margin-left: auto !important;
  }
  ._old-def-mr-auto {
    margin-right: auto !important;
  }
}

@media only screen and (min-width: 1280px) {
  ._old-lg-m-sm {
    margin: 0.3125rem;
  }
  ._old-lg-m-md {
    margin: 0.625rem;
  }
  ._old-lg-m-def {
    margin: 1.25rem;
  }
  ._old-lg-m-lg {
    margin: 1.875rem;
  }
  ._old-lg-m-xl {
    margin: 2.5rem;
  }
  ._old-lg-m-mg {
    margin: 3.75rem;
  }
  ._old-lg-mt-sm {
    margin-top: 0.3125rem;
  }
  ._old-lg-mt-md {
    margin-top: 0.625rem;
  }
  ._old-lg-mt-def {
    margin-top: 1.25rem;
  }
  ._old-lg-mt-lg {
    margin-top: 1.875rem;
  }
  ._old-lg-mt-xl {
    margin-top: 2.5rem;
  }
  ._old-lg-mt-mg {
    margin-top: 3.75rem;
  }
  ._old-lg-mt-none {
    margin-top: 0 !important;
  }
  ._old-lg-mr-sm {
    margin-right: 0.3125rem;
  }
  ._old-lg-mr-md {
    margin-right: 0.625rem;
  }
  ._old-lg-mr-def {
    margin-right: 1.25rem;
  }
  ._old-lg-mr-lg {
    margin-right: 1.875rem;
  }
  ._old-lg-mr-xl {
    margin-right: 2.5rem;
  }
  ._old-lg-mr-mg {
    margin-right: 3.75rem;
  }
  ._old-lg-mr-none {
    margin-right: 0 !important;
  }
  ._old-lg-mb-sm {
    margin-bottom: 0.3125rem;
  }
  ._old-lg-mb-md {
    margin-bottom: 0.625rem;
  }
  ._old-lg-mb-def {
    margin-bottom: 1.25rem;
  }
  ._old-lg-mb-lg {
    margin-bottom: 1.875rem;
  }
  ._old-lg-mb-xl {
    margin-bottom: 2.5rem;
  }
  ._old-lg-mb-mg {
    margin-bottom: 3.75rem;
  }
  ._old-lg-mb-none {
    margin-bottom: 0 !important;
  }
  ._old-lg-ml-sm {
    margin-left: 0.3125rem;
  }
  ._old-lg-ml-md {
    margin-left: 0.625rem;
  }
  ._old-lg-ml-def {
    margin-left: 1.25rem;
  }
  ._old-lg-ml-lg {
    margin-left: 1.875rem;
  }
  ._old-lg-ml-xl {
    margin-left: 2.5rem;
  }
  ._old-lg-ml-mg {
    margin-left: 3.75rem;
  }
  ._old-lg-ml-none {
    margin-left: 0 !important;
  }
  ._old-lg-m-none {
    margin: 0 !important;
  }
  ._old-lg-p-sm {
    padding: 0.3125rem;
  }
  ._old-lg-p-md {
    padding: 0.625rem;
  }
  ._old-lg-p-def {
    padding: 1.25rem;
  }
  ._old-lg-p-lg {
    padding: 1.875rem;
  }
  ._old-lg-p-xl {
    padding: 2.5rem;
  }
  ._old-lg-p-mg {
    padding: 3.75rem;
  }
  ._old-lg-pt-sm {
    padding-top: 0.3125rem;
  }
  ._old-lg-pt-md {
    padding-top: 0.625rem;
  }
  ._old-lg-pt-def {
    padding-top: 1.25rem;
  }
  ._old-lg-pt-lg {
    padding-top: 1.875rem;
  }
  ._old-lg-pt-xl {
    padding-top: 2.5rem;
  }
  ._old-lg-pt-mg {
    padding-top: 3.75rem;
  }
  ._old-lg-pt-none {
    padding-top: 0 !important;
  }
  ._old-lg-pr-sm {
    padding-right: 0.3125rem;
  }
  ._old-lg-pr-md {
    padding-right: 0.625rem;
  }
  ._old-lg-pr-def {
    padding-right: 1.25rem;
  }
  ._old-lg-pr-lg {
    padding-right: 1.875rem;
  }
  ._old-lg-pr-xl {
    padding-right: 2.5rem;
  }
  ._old-lg-pr-mg {
    padding-right: 3.75rem;
  }
  ._old-lg-pr-none {
    padding-right: 0 !important;
  }
  ._old-lg-pb-sm {
    padding-bottom: 0.3125rem;
  }
  ._old-lg-pb-md {
    padding-bottom: 0.625rem;
  }
  ._old-lg-pb-def {
    padding-bottom: 1.25rem;
  }
  ._old-lg-pb-lg {
    padding-bottom: 1.875rem;
  }
  ._old-lg-pb-xl {
    padding-bottom: 2.5rem;
  }
  ._old-lg-pb-mg {
    padding-bottom: 3.75rem;
  }
  ._old-lg-pb-none {
    padding-bottom: 0 !important;
  }
  ._old-lg-pl-sm {
    padding-left: 0.3125rem;
  }
  ._old-lg-pl-md {
    padding-left: 0.625rem;
  }
  ._old-lg-pl-def {
    padding-left: 1.25rem;
  }
  ._old-lg-pl-lg {
    padding-left: 1.875rem;
  }
  ._old-lg-pl-xl {
    padding-left: 2.5rem;
  }
  ._old-lg-pl-mg {
    padding-left: 3.75rem;
  }
  ._old-lg-pl-none {
    padding-left: 0 !important;
  }
  ._old-lg-p-none {
    padding: 0 !important;
  }
  ._old-lg-m-auto {
    margin: auto !important;
  }
  ._old-lg-ml-auto {
    margin-left: auto !important;
  }
  ._old-lg-mr-auto {
    margin-right: auto !important;
  }
}

@media only screen and (min-width: 1400px) {
  ._old-xl-m-sm {
    margin: 0.3125rem;
  }
  ._old-xl-m-md {
    margin: 0.625rem;
  }
  ._old-xl-m-def {
    margin: 1.25rem;
  }
  ._old-xl-m-lg {
    margin: 1.875rem;
  }
  ._old-xl-m-xl {
    margin: 2.5rem;
  }
  ._old-xl-m-mg {
    margin: 3.75rem;
  }
  ._old-xl-mt-sm {
    margin-top: 0.3125rem;
  }
  ._old-xl-mt-md {
    margin-top: 0.625rem;
  }
  ._old-xl-mt-def {
    margin-top: 1.25rem;
  }
  ._old-xl-mt-lg {
    margin-top: 1.875rem;
  }
  ._old-xl-mt-xl {
    margin-top: 2.5rem;
  }
  ._old-xl-mt-mg {
    margin-top: 3.75rem;
  }
  ._old-xl-mt-none {
    margin-top: 0 !important;
  }
  ._old-xl-mr-sm {
    margin-right: 0.3125rem;
  }
  ._old-xl-mr-md {
    margin-right: 0.625rem;
  }
  ._old-xl-mr-def {
    margin-right: 1.25rem;
  }
  ._old-xl-mr-lg {
    margin-right: 1.875rem;
  }
  ._old-xl-mr-xl {
    margin-right: 2.5rem;
  }
  ._old-xl-mr-mg {
    margin-right: 3.75rem;
  }
  ._old-xl-mr-none {
    margin-right: 0 !important;
  }
  ._old-xl-mb-sm {
    margin-bottom: 0.3125rem;
  }
  ._old-xl-mb-md {
    margin-bottom: 0.625rem;
  }
  ._old-xl-mb-def {
    margin-bottom: 1.25rem;
  }
  ._old-xl-mb-lg {
    margin-bottom: 1.875rem;
  }
  ._old-xl-mb-xl {
    margin-bottom: 2.5rem;
  }
  ._old-xl-mb-mg {
    margin-bottom: 3.75rem;
  }
  ._old-xl-mb-none {
    margin-bottom: 0 !important;
  }
  ._old-xl-ml-sm {
    margin-left: 0.3125rem;
  }
  ._old-xl-ml-md {
    margin-left: 0.625rem;
  }
  ._old-xl-ml-def {
    margin-left: 1.25rem;
  }
  ._old-xl-ml-lg {
    margin-left: 1.875rem;
  }
  ._old-xl-ml-xl {
    margin-left: 2.5rem;
  }
  ._old-xl-ml-mg {
    margin-left: 3.75rem;
  }
  ._old-xl-ml-none {
    margin-left: 0 !important;
  }
  ._old-xl-m-none {
    margin: 0 !important;
  }
  ._old-xl-p-sm {
    padding: 0.3125rem;
  }
  ._old-xl-p-md {
    padding: 0.625rem;
  }
  ._old-xl-p-def {
    padding: 1.25rem;
  }
  ._old-xl-p-lg {
    padding: 1.875rem;
  }
  ._old-xl-p-xl {
    padding: 2.5rem;
  }
  ._old-xl-p-mg {
    padding: 3.75rem;
  }
  ._old-xl-pt-sm {
    padding-top: 0.3125rem;
  }
  ._old-xl-pt-md {
    padding-top: 0.625rem;
  }
  ._old-xl-pt-def {
    padding-top: 1.25rem;
  }
  ._old-xl-pt-lg {
    padding-top: 1.875rem;
  }
  ._old-xl-pt-xl {
    padding-top: 2.5rem;
  }
  ._old-xl-pt-mg {
    padding-top: 3.75rem;
  }
  ._old-xl-pt-none {
    padding-top: 0 !important;
  }
  ._old-xl-pr-sm {
    padding-right: 0.3125rem;
  }
  ._old-xl-pr-md {
    padding-right: 0.625rem;
  }
  ._old-xl-pr-def {
    padding-right: 1.25rem;
  }
  ._old-xl-pr-lg {
    padding-right: 1.875rem;
  }
  ._old-xl-pr-xl {
    padding-right: 2.5rem;
  }
  ._old-xl-pr-mg {
    padding-right: 3.75rem;
  }
  ._old-xl-pr-none {
    padding-right: 0 !important;
  }
  ._old-xl-pb-sm {
    padding-bottom: 0.3125rem;
  }
  ._old-xl-pb-md {
    padding-bottom: 0.625rem;
  }
  ._old-xl-pb-def {
    padding-bottom: 1.25rem;
  }
  ._old-xl-pb-lg {
    padding-bottom: 1.875rem;
  }
  ._old-xl-pb-xl {
    padding-bottom: 2.5rem;
  }
  ._old-xl-pb-mg {
    padding-bottom: 3.75rem;
  }
  ._old-xl-pb-none {
    padding-bottom: 0 !important;
  }
  ._old-xl-pl-sm {
    padding-left: 0.3125rem;
  }
  ._old-xl-pl-md {
    padding-left: 0.625rem;
  }
  ._old-xl-pl-def {
    padding-left: 1.25rem;
  }
  ._old-xl-pl-lg {
    padding-left: 1.875rem;
  }
  ._old-xl-pl-xl {
    padding-left: 2.5rem;
  }
  ._old-xl-pl-mg {
    padding-left: 3.75rem;
  }
  ._old-xl-pl-none {
    padding-left: 0 !important;
  }
  ._old-xl-p-none {
    padding: 0 !important;
  }
  ._old-xl-m-auto {
    margin: auto !important;
  }
  ._old-xl-ml-auto {
    margin-left: auto !important;
  }
  ._old-xl-mr-auto {
    margin-right: auto !important;
  }
}
