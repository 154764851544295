

.portfolio-list {
	margin: rem(-40) 0 rem(80);
}


// portfolio-preview
// -------------------------------------------------------------------------

.portfolio-preview {
	display: block;
	position: relative;
	color: #fff;
	padding: rem(130) rem(20);
	background-size: cover;
	background-position: 50%;
	min-height: rem(800);
	text-align: center;
	text-decoration: none;

	&:before {
		content: '';
		display: block;
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		background: rgba(#000, .6);
		opacity: 1;
		transition: opacity .35s;

		@include media($def) {
			opacity: 0;
		}
	}

	@include media($def) {
		&:hover {
			&:before {
				opacity: 0;
			}
		}
	}

	@include media($def) {
		text-align: left;
	}

	&__logo {
		margin: 0 0 rem(40);

		img {
			margin: 0 auto;

			@include media($def) {
				margin: 0;
			}
		}
	}

	&__title {
		font-size: rem(42);
		font-weight: 700;
		line-height: 1.2;
		margin: 0 0 rem(20);
		font-family: Coco Gothic, Arial, sans-serif;
	}

	&__descr {
		font-size: rem(24);
		font-weight: 300;
		margin: 0 0 rem(25);
		font-family: ProximaNova, Arial, sans-serif;

		@include media($def) {
			color: rgba(#fff, .6);
		}
	}

	&__service {
		text-decoration: none;
		display: inline-block;
		font-size: 12px;
		color: $color-white;
		text-transform: uppercase;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;

		&:not(:last-child) {
			margin: 0 rem(15) 0 0;
		}

		span {
			display: inline-block;
			vertical-align: middle;
		}

		i {
			position: relative;
			display: inline-block;
			vertical-align: middle;
			width: 20px;
			height: 20px;
			margin: 0 5px 0 0;

			svg {
				fill: #fff;
				position: absolute;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
				margin: auto;
				max-width: 80%;
				max-height: 80%;
			}
		}
	}
}

// portfolio-nav
// -------------------------------------------------------------------------
.portfolio-nav {
	text-align: center;
	margin: rem(45) 0;
	font-size: 0;

	&__link {
		display: inline-block;
		padding: rem(10) rem(15);
		font-size: rem(14);
		color: #787a7d;
		text-transform: uppercase;
		text-decoration: none;
		transition: background .25s, color .25s;

		&:hover {
			background: #fff;
		}

		&--active {
			color: #fff;
			background: $color-primary !important;
		}
	}
}

// portfolio-card
// -------------------------------------------------------------------------

.portfolio-card {
	position: relative;
	display: block;
	padding: 100% 0 0;
	text-decoration: none;
	background-size: cover;
	background-position: 50%;

	&__logo {
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		opacity: .6;
		transition: opacity .5s;

		img {
			display: block;
			max-width: 60%;
			max-height: 60%;
		}

		.portfolio-card:hover & {
			opacity: 0;
		}
	}

	&__overlay {
		display: none;
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		color: #fff;
		opacity: 0;
		transition: opacity .5s;

		@include media($md) {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
		}

		.portfolio-card:hover & {
			opacity: .85;
		}
	}

	&__title {
		font-size: rem(24);
		font-weight: 700;
		margin: 0 0 rem(20);
		text-align: center;
		max-width: 90%;
	}

	&__meta {
		max-width: 90%;
		text-align: center;
		margin: 0 0 rem(30);
		font-size: 0;
	}

	&__service {
		display: inline-block;
		font-size: 12px;
		color: $color-white;
		text-transform: uppercase;
		margin: 0 rem(10);
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;

		span {
			display: inline-block;
			vertical-align: middle;
		}

		i {
			position: relative;
			display: inline-block;
			vertical-align: middle;
			width: 20px;
			height: 20px;

			svg {
				fill: #fff;
				position: absolute;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
				margin: auto;
				max-width: 80%;
				max-height: 80%;
			}
		}
	}

	&__target {
		display: inline-block;
		font-size: rem(16);
		color: #fff;
		font-weight: 700;
		text-transform: uppercase;
		text-decoration: none;

		i, span {
			display: inline-block;
			vertical-align: middle;
		}

		i {
			width: 18px;
			height: 18px;
			margin: 0 0 0 rem(10);

			svg {
				max-width: 100%;
				max-height: 100%;
				fill: #fff;
			}
		}
	}
}
