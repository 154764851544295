/*.form {
	position: relative;

	&__sending {
	  position: absolute;
	  top: 0;
	  left: 0;
	  width: 100%;
	  height: 100%;
	  background: transparent url('pic/ajax-sending.svg') no-repeat 50% 50%;
	  background-size: 50% 50%;
	}
}
.form-element {
	position: relative;
	font-size: rem(16);
	margin: 0 0 rem(20);

	&__info {
		position: absolute;
		right: 0;
		top: 0;
		visibility: hidden;
		opacity: 0;
		font-size: 12px;
		color: #fff;
		padding: rem(5) rem(10);
		background: darken(#aaaaaa, 15%);
		border-radius: 0 4px 0 4px;
		transition: visibility .2s, opacity .2s, transform .2s;

		input:focus ~ &,
		textarea:focus ~ & {
			visibility: visible;
			opacity: 1;
		}
	}

	&__input {
		width: 100%;
		height: rem(56);
		padding: 0 rem(20);
		border: 1px solid  #aaaaaa;
		border-radius: 4px;
		outline: 0;
		background: #fff;
		transition: border .2s;
		@include placeholder(#a5a4a4);

		&:focus,
		&:hover {
			border: 1px solid darken(#aaaaaa, 15%);
		}

		&.has-error {
			//border-color: $color-crimson;
		}
	}

	&__textarea {
		width: 100%;
		height: rem(200);
		max-height: rem(200);
		padding: rem(20) rem(20);
		border: 1px solid  #aaaaaa;
		border-radius: 4px;
		outline: 0;
		background: #fff;
		box-sizing: border-box;
		resize: vertical;

		&:focus,
		&:hover {
			//border-color: $color-bermuda-gray;
		}

		&.has-error {
			//border-color: $color-crimson;
		}
	}

	&__select {
		height: rem(56);
		width: 100%;
		padding: 0 rem(20);
		border: 1px solid  #aaaaaa;
		border-radius: 4px;
		appearance: none;
		color: #333333;
		outline: none;
		background: #fff url("pic/down.svg") calc(100% - 15px) 50% no-repeat;
		background-size: rem(25);

		&::-ms-value {
			color: #333333;
		}
	}

	&__file-holder {
		position: relative;
		display: flex !important;
		justify-content: space-between;
		flex-wrap: nowrap;
		align-items: center;
		margin: rem(10) 0;

		& > label {
			position: relative;
			display: flex !important;
			justify-content: space-between;
			flex-wrap: nowrap;
			align-items: center;
			cursor: pointer;
			max-width: calc(100% - 3.3333333rem);
			min-width: calc(100% - 3.3333333rem);
		}
	}

	&__file-input {
		visibility: hidden;
		opacity: 0;
		position: absolute;
		width: 1px;
		height: 1px;
		overflow: hidden;
	}

	&__file-result {
		display: block;
		flex-grow: 1;
		font-size: rem(20);
		color: $color-primary;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		font-weight: 300;

		&:hover {
			text-decoration: none;
		}
	}

	&__file-icon {
		flex-shrink: 0;
		position: relative;
		display: block;
		height: rem(40);
		width: rem(40);
		background-color: #fff;
		margin: 0 rem(10) 0 0;
		//box-shadow: 0 1px 2px $color-bombay;
		border-radius: rem(4);

		svg {
			position: absolute;
			max-width: 60%;
			max-height: 60%;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			margin: auto;
			//fill: $color-blue;
		}
	}

	&__file-remove {
		flex-shrink: 0;
		position: relative;
		display: block;
		height: rem(40);
		width: rem(40);
		margin: 0 0 0 rem(10);
		border-radius: rem(4);
		cursor: pointer;
		transition: background .25s;

		&:before,
		&:after {
			content: '';
			display: block;
			position: absolute;
			width: 80%;
			height: 3px;
			background: $color-primary;
			top: calc(50% - 1px);
			left: 10%;
			transform-origin: 50%;
			transition: background .25s;
		}

		&:before {
			transform: rotate(-45deg);
		}

		&:after {
			transform: rotate(45deg);
		}

		&:hover {
			background: $color-primary;
			&:before,
			&:after {
				background: #fff;
			}
		}
	}

	&--check,
	&--radio {
		position: relative;
		display: flex;
		line-height: 1.4;
		font-size: rem(13);
		//fill: $color-blue;
		//color: $color-bermuda-gray;
		input {
			z-index: -1;
			position: absolute;
			top: 0;
			left: 0;
			height: 1px;
			width: 1px;
		}
		i, span {
			vertical-align: middle;
			display: inline-block; // IE 10
		}
		i {
			position: relative;
			height: rem(16);
			width: rem(16);
			flex-shrink: 0;
			//border: 1px solid $color-bombay;
			cursor: pointer;
			transition: border .2s, box-shadow .2s;
			&:hover {
				//border-color: $color-bermuda-gray;
			}
		}
		svg {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			max-width: 70%;
			max-height: 70%;
			margin: auto;
			fill: #fff;
			transform: scale(0);
			transform-origin: bottom center;
			transition: transform .2s;
		}

		span {
			padding-left: rem(5);
			flex-grow: 1;
			flex-shrink: 1;
		}

		a {
			color: inherit;
			&:hover {
				text-decoration: none;
			}
		}
	}

	&--radio {
		input {
			&:checked ~ i {
				//border-color: $color-blue;
				//box-shadow: inset 0 0 0 rem(4) $color-blue;
			}
			&.has-error ~ i {
				//border-color: $color-crimson;
			}
		}
		i {
			border-radius: 50%;
		}
	}

	&--check {
		input {
			&:checked ~ i {
				//background: $color-blue;
				//border-color: $color-blue;
			}
			&:checked ~ i svg {
				transform: scale(1);
			}
			&.has-error ~ i {
				//border-color: $color-crimson;
			}
		}
		i {
			border-radius: 4px;
		}
	}

	&__checkbox {
		opacity: 0;
		width: 1px;
		height: 1px;
		position: absolute;

		& ~ i {
			display: block;
			width: rem(20);
			height: rem(20);
			box-sizing: border-box;
			border: 1px solid #c8c8c8;
			background: #fff;
			border-radius: 2px;
			cursor: pointer;
			position: relative;
			margin: 0 rem(20) 0 0;
			transition: background .2s;

			svg {
				position: absolute;
				max-width: 80%;
				max-height: 80%;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);
				visibility: hidden;
				opacity: 0;
				fill: #fff;
				transition: opacity .2s, visibility .2s;
			}
		}

		&:checked ~ i {
			//background: $color-blue;
			//border: 1px solid $color-blue;

			svg {
				visibility: visible;
				opacity: 1;
			}
		}
	}
}
select::-ms-expand {
	display: none;
}
.form-tips {
	font-size: 12px;
	color: #aaaaaa;
}
.form-label {
	font-size: rem(14);
	user-select: none;
	cursor: default;
	margin-bottom: rem(10);
	font-weight: 300;

	label & {
		cursor: inherit;
	}
}*/

////
/// @group _blocks/
////

/// Description
.form,
.form-group,
.form-controller {
	position: relative;
}

.form-control {
	border: 1px solid #ccc;
	padding: 0.8rem 1rem;
	width: 100%;
	font-family: Arial, Helvetica, sans-serif;
	font-size: rem(16px);
	line-height: 1.4em;
	transition: border-color 0.2s ease-in-out;

	&:hover {
		border-color: darken(#ccc, 25%);
	}

	&.has-error {
		border-color: #f00;

		@at-root {
			input[type="file"]#{&} { // sass-lint:disable-line force-attribute-nesting
				~ label {
					&.has-error {
						visibility: visible;
						position: relative;
					}
				}
			}
		}
	}

	@at-root {
		textarea#{&} {
			max-width: 100%;
			min-width: 100%;
		}
	}

	@at-root {
		[type="checkbox"]#{&} {
			width: 20px;
			height: 20px;
			padding: 0;

			&:checked  {
				~ span {
					opacity: 1;
				}
			}
		}
	}

	&--transparent {
		background: transparent;
		border: 1px solid rgba(#fff, 0.2);
		color: #fff;
	}

	&--arrow {
		background: rgb(255, 255, 255) url(pic/down.svg) calc(100% - 15px) 50% no-repeat;
		background-size: 1.5625rem;
	}
}

.form-flag {
	&__input {
		&.has-error ~ .form-flag__value {
			color: #f00;
		}
	}
}

.form-label {
	display: inline-block;
	vertical-align: top;
	font: 700 1rem/1.4em Arial, Helvetica, sans-serif;
}

label {
	&.has-error {
		visibility: hidden;
		position: absolute;
		left: 0;
		padding: 0.3rem 0.5rem;
		font: 11px/1.3em Arial, Helvetica, sans-serif;
		color: #fff;
		background: lighten(#f00, 1%);
		letter-spacing: 0.5px;
		width: 100%;
		margin-top: 3px;
		display: inline-block;
		vertical-align: top;
		z-index: 1;

		&::after {
			bottom: 100%;
			left: 10%;
			content: " ";
			height: 0;
			width: 0;
			position: absolute;
			pointer-events: none;
			border: 4px solid rgba(255, 51, 51, 0);
			border-bottom-color: #f33;
			margin-left: -4px;
		}

		.form-control:focus ~ &,
		&--visible {
			visibility: visible;
			position: relative;
		}
	}
}

.form-error {
	font-size: 12px;
	color: #f00;
	margin-bottom: rem(25);
}

.custom-file {
	&__input {
		overflow: hidden;
		width: 1px;
		height: 1px;
		visibility: hidden;
		position: absolute;
		left: 0;
	}

	&__result {
		display: block;
		cursor: pointer;
	}

	&__clear {
		display: none;
		position: absolute;
		width: 2rem;
		height: 2rem;
		background: #fff;
		top: 0.5rem;
		right: 1px;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		fill: #f00;

		@at-root {
			.has-file & {
				display: flex;
			}
		}
	}
}

.custom-checkbox {
	position: relative;

	&__input {
		position: relative;
		display: block;
		border: 1px solid #ccc;
		width: 20px;
		height: 20px;
		cursor: pointer;
		outline: none;
		z-index: 1;

		&:checked {
			~ .custom-checkbox__checked {
				opacity: 1;
			}
		}
	}

	&__checked {
		opacity: 0;
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
		font-size: 14px;
		text-align: center;
		z-index: 0;
		transition: 0.15s ease-out;
	}
}